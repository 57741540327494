<template>
  <div
    class="modal fade"
    id="terminModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="terminModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="terminModalLabel">
            {{ title }}
          </h5>
        </div>
        <div class="modal-body">
          <Loader v-if="mailStatus == 'loading'" />
          <div v-else-if="mailStatus === 'success' || mailStatus === 'failed'">
            <Checkmark v-if="mailStatus === 'success'" />
            <Failuremark v-if="mailStatus === 'failed'" />
            <p>
              {{ mailResponse }}
            </p>
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
              style="width: 100%"
            >
              Okay!
            </button>
          </div>
          <MeetingForm v-else-if="mailStatus === 'idle'" />
        </div>
        <div class="modal-footer" id="terminFooter" v-if="mailStatus == 'idle'">
          <button
            type="button"
            class="btn btn-secondary"
            data-dismiss="modal"
            style="width: 100%"
          >
            Doch nicht!
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Checkmark from "../../../components/Icons/Animated/Checkmark";
import Loader from "../../../components/Icons/Animated/Loader.vue";
import Failuremark from "../../../components/Icons/Animated/Failuremark.vue";
import MeetingForm from "./MeetingForm.vue";
import store from "../../../store";
export default {
  props: ["title", "Content"],
  computed: {
    mailStatus() {
      return store.state.mail.status;
    },
    mailResponse() {
      return store.state.mail.response;
    },
  },
  components: {
    Checkmark,
    Loader,
    Failuremark,
    MeetingForm,
  },
  mounted() {},
  methods: {},
};
</script>
