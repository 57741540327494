<template>
	<div class="profile-page" style="background-color: #F2F2F2;">
		<section class="section-profile-cover" id="header">
		</section>
		<section class="section section-skew" style="">
			<div class="container">
				<card shadow class="card-profile mt--300" no-body style="background-color: white;">
					<div class="px-4">
						<div class="row ">
							<div class="col" style="min-width: 300px">
								<div class="mt-3">
									<h2 style="color:#fbce3b">DU HAST FRAGEN?</h2>

								</div>
							</div>
						<EggPopup></EggPopup>

						</div>
						<div class="mt-3 py-3 border-top ">
							<div class="row d-flex " style="margin-top: 0px;">
								<form style="width: 100%;" class="needs-validation" novalidate onsubmit="return false">
									<p id="formHeading" data-aos="fade-right">Wer bist du?</p>
									<div class="form-row" data-aos="fade-right">
										<div class="form-group col-md-6">
											<label for="inputVorname"></label>
											<input type="text" class="form-control" id="inputVorname"
												placeholder="Vorname*" required>
											<div class="invalid-feedback">
											</div>
										</div>
										<div class="form-group col-md-6">
											<label for="inputNachname"></label>
											<input type="text" class="form-control" id="inputNachname"
												placeholder="Nachname*" required>
											<div class="invalid-feedback">
											</div>
										</div>
									</div>
									<p id="formHeading" data-aos="fade-right">Wie können wir dir antworten?</p>
									<div class="form-row" data-aos="fade-right">
										<div class="form-group col-md-6">
											<label for="inputMail"></label>
											<input type="text" class="form-control" id="inputMail" placeholder="E-Mail">

										</div>
										<div class="form-group col-md-6">
											<label for="inputTel"></label>
											<input type="text" class="form-control" id="inputTel"
												placeholder="Telefonnummer">
										</div>
									</div>
									<p id="formHeading" data-aos="fade-right">Deine Nachricht</p>
									<div class="form-row" data-aos="fade-right">
										<div class="form-group col-md-12">
											<label for="inputMessage"></label>
											<textarea class="form-control" rows="5" id="inputMessage"
												required></textarea>
											<div class="invalid-feedback">
											</div>
										</div>
									</div>

									<div class="row justify-content-center text-center" style="margin-top: 20px;"
										data-aos="fade-right">
										<input value="ABSENDEN" id="submit" type="submit" class="btn btnColor"
											v-on:click="submitInfo()" />
											<img id="EggPink" style="display: none;" src="@/assets/img/Ostern/EiPink.png" alt="Ei1" v-on:click="eggFound('pink')">
									</div>
								</form>
							</div>
						</div>
					</div>
				</card>
			</div>
		</section>


	</div>

</template>

<script>
	import EggPopup from "../components/EggPopup";

	import $ from "jquery";
	import axios from "axios";
	export default {
		components: {
			EggPopup
		},
		mounted() {
			AOS => AOS.init();
			axios.get(
					"https://forum-neuenbuerg.de/action.php?username=web166&pw=BX79HmqzoxKNb4OY&request=eggsActive"
				)
				.then(response => {
					response.data.forEach(egg => {

						if (egg.color == "pink") {
							document.getElementById('EggPink').style.cssText =
								"display: block; cursor: pointer"
						}
					});
				});
		},
		methods: {
			eggFound(clr) {

				this.$store.state.klickedEgg = clr
				$('#EggModal').modal('show')
			},
			submitInfo() {
				var forms = document.getElementsByClassName('needs-validation');
				var router = this.$router
				const first_name = $('#inputVorname').val()
				const last_name = $('#inputNachname').val()
				const email = $('#inputMail').val()
				const phone_number = $('#inputTel').val()
				const msg = $('#inputMessage').val()

				var validation = Array.prototype.filter.call(forms, function (form) {

					form.addEventListener('submit', function (event) {

						if (form.checkValidity() === false) {
							event.preventDefault();
							event.stopPropagation();
						} else {
							router.push({
								name: 'msgsend',
								params: {
									first_name: first_name,
									last_name: last_name,
									phone: phone_number,
									email: email,
									msg: msg
								}
							})

						}
						form.classList.add('was-validated');
					}, false);

				});
				// if (msgSent == true) {


				// }

			},
		}
	}
</script>

<style>
	@media only screen and (max-width: 555px) {
		#kontaktButton {
			width: 100% !important;
			margin-top: 20px !important;
		}
	}

	#header {
		background-image: url("../assets/img/logos/crossfit.jpg");
	}

	#kontaktButton {
		background-color: #fbce3b;
		font-family: Arial, Helvetica, sans-serif;
		font-weight: bolder;
		font-style: italic;
		color: #212529 !important;
		border-color: transparent;
		width: 200px;

		font-size: 15px;

	}

	#kontaktButton:hover {
		background-color: #212529;
		color: white;
		transition: 0.3s;
	}

	#submit:hover {
		background-color: #212529;
		color: white !important;
		transition: 0.3s;

	}


	.btnColor:hover {
		background-color: #212529;
		color: white;
		transition: 0.3s;

	}

	.btnColor {
		outline-color: transparent;
		color: #212529;
		background-color: #FBCE3B;
		font-family: Arial, Helvetica, sans-serif;
		font-weight: bolder;
		font-style: italic;
		height: 50px;
		width: 300px;
		font-size: 15px;

	}

	input,
	textarea {
		color: black !important;
	}

	#inputMessage::-webkit-input-placeholder {
		/* Chrome/Opera/Safari */
		position: relative !important;
		transform: translateY(-420%) !important;
	}

	input::-moz-placeholder {
		/* Firefox 19+ */
		vertical-align: top;
	}

	input::-ms-input-placeholder {
		/* IE 10+ */
		vertical-align: top;
	}

	/* .kontaktHeader {
		height: 100%;
		min-height: 500px;
		background-image: url("../assets/img/logos/fitnessstudio-sportstudio-neuenbuerg.jpg");
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
		position: relative;

	} */
</style>