export const media = [
	{
	  thumb:  require('@/assets/img/Kurse/Step/Step1.jpg'),
	  src: require('@/assets/img/Kurse/Step/Step1.jpg'),
	},
	{
		thumb:  require('@/assets/img/Kurse/Step/Step2.jpg'),
		src: require('@/assets/img/Kurse/Step/Step2.jpg'),
	  },
	  {
		thumb:  require('@/assets/img/Kurse/Step/Step3.jpg'),
		src: require('@/assets/img/Kurse/Step/Step3.jpg'),
	  }
  ]