export const media = [
  {
    thumb: require("@/assets/img/Ger/Ger2.jpg"),
    src: require("@/assets/img/Ger/Ger2.jpg"),
  },
  {
    thumb: require("@/assets/img/Ger/Ger3.jpg"),
    src: require("@/assets/img/Ger/Ger3.jpg"),
  },
  {
    thumb: require("@/assets/img/Ger/Ger4.jpg"),
    src: require("@/assets/img/Ger/Ger4.jpg"),
  },
];
