<template>
  <section class="section-hero section-shaped my-0">
    <div class="row">
      <div class="col-12 text-center" id="banner">
        <h1 id="bannertitle">
          <strong
            >aktuell anwesende Mitgliederzahl: <span v-html="mgCount"></span
          ></strong>
        </h1>
      </div>
    </div>
    <header class="masthead" style="position: relative">
      <div class="row">
        <div class="col-12 text-center">
          <h1 id="titleheader">Beweg dich und fühl dich frei!</h1>
        </div>
      </div>

      <div
        class="row d-flex "
        style="position: absolute; top: 45%; right: 160px; "
        id="headerundertitel_1"
      >
        <div class="col-sm-12 undertitle">
          <h1 class="undertitle">ab 24,99€ im Monat!</h1>
        </div>
      </div>

      <div
        class="row d-flex justify-content-center text-center"
        style="position: absolute; top: 50%; right: 130px; display: none;"
        id="headerundertitel_2"
      >
        <div class="col-sm-12 undertitle">
          <h1
            class="undertitle"
            style="float:right; margin-right: 30px; padding-bottom: 20px;"
          >
            ab 24,99€ im Monat!
          </h1>
        </div>
      </div>

      <div
        class="row d-flex justify-content-center text-center"
        style=" position: absolute; top: 60%; left: 25%; right: auto"
        id="headerundertitel_3"
      >
        <div class="col-sm-12 undertitle">
          <h1 class="undertitle" style="">ab 24,99€ im Monat!</h1>
        </div>
      </div>

      <div
        class="row d-flex justify-content-center text-center"
        style=" display: none; "
        id="headerundertitel_4"
      >
        <div class="col-sm-12 undertitle">
          <h1 class="undertitle" style="margin-top: 20%">
            ab 24,99€ im Monat!
          </h1>
        </div>
      </div>
    </header>
  </section>
</template>
<script>
import axios from "axios";
import https from "https";
export default {
  name: "HeaderTitle",
  data() {
    return {
      mgCount: "0",
    };
  },
  mounted() {
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker
        .register("/service-worker.js", {
          scope: "sw-test",
        })
        .then(function(registration) {
          // registration worked
          // console.log('Registration succeeded.');
          registration.unregister().then(function(boolean) {
            // if boolean = true, unregister is successful
          });
        })
        .catch(function(error) {
          // registration failed
          // console.log('Registration failed with ' + error);
        });
    }
    // axios.get("https://forum-neuenbuerg.de/MGZahl.php")
    //     .then(response => this.mgCount = response.data)

    axios
      .get(
        "https://clubconnector.sovd.cloud/api/anwesende/b3f9e89c-ead0-4cc1-9ce9-22bb1a77071d-070174/1"
      )
      .then((response) => (this.mgCount = response.data.count));
  },
  methods: {
    goToLive() {
      window.open("https://live.forum-neuenbuerg.de");
    },
  },
};
</script>
<style scoped>
@import url("https://use.typekit.net/pxv7oqc.css");

#titleheader {
  -webkit-animation: tracking-in-expand-fwd-top 0.8s
    cubic-bezier(0.215, 0.61, 0.355, 1) both;
  animation: tracking-in-expand-fwd-top 0.8s cubic-bezier(0.215, 0.61, 0.355, 1)
    0.2s both;
}

@-webkit-keyframes tracking-in-expand-fwd-top {
  0% {
    letter-spacing: -0.5em;
    -webkit-transform: translateZ(-700px) translateY(-500px);
    transform: translateZ(-700px) translateY(-500px);
    opacity: 0;
  }

  40% {
    opacity: 0.6;
  }

  100% {
    -webkit-transform: translateZ(0) translateY(0);
    transform: translateZ(0) translateY(0);
    opacity: 1;
  }
}

@keyframes tracking-in-expand-fwd-top {
  0% {
    letter-spacing: -0.5em;
    -webkit-transform: translateZ(-700px) translateY(-500px);
    transform: translateZ(-700px) translateY(-500px);
    opacity: 0;
  }

  40% {
    opacity: 0.6;
  }

  100% {
    -webkit-transform: translateZ(0) translateY(0);
    transform: translateZ(0) translateY(0);
    opacity: 1;
  }
}

.masthead {
  height: 100%;
  min-height: 500px;
  background-image: url("../../assets/img/logos/Forum-Website-1.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin-top: -110px;
  z-index: -2;
}

#titleheader {
  padding-top: 150px;
  color: #fbce3b;
  font-family: scarlet-wood, sans-serif;
  font-size: 120px;
}

@media only screen and (max-width: 1670px) {
  #titleheader {
    font-size: 100px;
  }
}

@media only screen and (max-width: 1600px) {
  #headerundertitel_1 {
    display: none !important;
  }
}

@media only screen and (min-width: 1601px) {
  #headerundertitel_2 {
    display: none !important;
  }
}

@media only screen and (max-width: 1400px) {
  #titleheader {
    padding-top: 140px;
    font-size: 70px !important;
  }
}

@media only screen and (max-width: 1000px) {
  #titleheader {
    padding-top: 160px !important;
    font-size: 60px !important;
  }

  #headerundertitel_3 h1 {
    margin-top: -40px;
  }
}

@media only screen and (max-width: 990px) {
  #headerundertitel_2 {
    display: none !important;
  }

  #titleheader {
    padding-top: 120px;
    font-size: 70px;
  }
}

@media only screen and (min-width: 991px) {
  #headerundertitel_3 {
    display: none !important;
  }
}

@media only screen and (max-width: 770px) {
  #headerundertitel_3 {
    display: none !important;
  }

  #heroBtn {
    width: 70% !important;
  }

  .masthead {
    height: 110%;
  }

  #headerundertitel_4 {
    margin-top: 0%;
  }
}

@media only screen and (min-width: 771px) {
  #headerundertitel_4 {
    display: none !important;
  }
}

@media only screen and (max-width: 740px) {
  #titleheader {
    padding-top: 120px;
    font-size: 70px;
  }
}

@media only screen and (max-width: 470px) {
  .undertitle {
    font-size: 50px !important;
    margin-bottom: 20px;
  }

  #titleheader {
    padding-top: 1200px;
    font-size: 50px !important;
  }

  .masthead {
    background-position-y: -0px;
  }
}

@media only screen and (max-width: 400px) {
  .undertitle {
    font-size: 60px !important;
  }

  .masthead {
    /* background-position-x: -1100px !important; */
  }
}

.undertitle {
  color: white;
  font-family: noto-sans-display-extraconde, sans-serif;
  font-size: 70px;
  font-style: italic;
  padding-top: 50px;
  -webkit-animation: slide-in-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: slide-in-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 1.5s both;
}

@-webkit-keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-1000px);
    transform: translateX(-1000px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-1000px);
    transform: translateX(-1000px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

#banner {
  height: 45px;
  margin-top: 0px;
  z-index: 10;
  background-color: #fbce3b;
  -webkit-animation: slide-in-top 3s cubic-bezier(0.25, 0.46, 0.45, 0.94) 2s
    both;
  animation: slide-in-top 3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes slide-in-top {
  0% {
    -webkit-transform: translateY(-1000px);
    transform: translateY(-1000px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slide-in-top {
  0% {
    -webkit-transform: translateY(-1000px);
    transform: translateY(-1000px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

#heroBtn {
  border: 1px solid transparent;
  color: #212529;
  background-color: #fbce3b;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bolder;
  font-style: italic;
  height: 120px;
  width: 300px;

  font-size: 30px;
  -webkit-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
  -webkit-animation: slide-in-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: slide-in-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 1.5s both;
}

#heroBtn:hover {
  background-color: #212529;
  color: white;
  transition: 0.3s;
}

@-webkit-keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

#btnText {
  font-size: 25px;
  color: #212529;
  font-family: noto-sans-display, sans-serif;
  font-weight: 900;
  font-style: italic;
  text-transform: none;
}

#bannertitle {
  font-size: 30px;
  text-align: center;
  margin-top: 0px;
}

@media only screen and (max-width: 540px) {
  #bannertitle {
    font-size: 25px;
    margin-top: 5px;
  }
}

@media only screen and (max-width: 444px) {
  #banner {
    height: 80px;
    margin-top: 0px;
    background-color: #fbce3b;
  }
}
</style>
