<template>
	<section class="section standortContainer" id="standortContainer">
		<div class="row">

			<div class="col-sm d-flex justify-content-center text-center">
				<div id="standortTitle" style="margin-top:0px;">
					<h2>STANDORT</h2>
					<h4>KONTAKT & ÖFFNUNGSZEITEN</h4>
				</div>
			</div>
		</div>
		<div class="row" id="standortRow_1">
			<div class="col" style="margin-left: 200px;">
				<div class="section-1" style="">
					<div class="row" data-aos="fade-right">
						<div class="col-sm-1">
							<img src="@/assets/img/logos/Uhr.png" style="width: 80px;">
						</div>
						<div class="col" style="padding-left: 60px; margin-top: 15px;">
							<p class="miniTitle">Öffnungszeiten</p>
						</div>
					</div>
					<div class="row" style="margin-top: 0px;" data-aos="fade-left">
						<div class="col-sm-3" style="min-width:200px">
							<p class="standortText">Montag - Freitag</p>
							<p class="standortZeit">06:00 - 22:00</p>
						</div>
						<div class="col-sm-1" style="margin-left: -20px; min-width:70px">
							<img src="@/assets/img/logos/trennstrich.png" style="width: 20px;">
						</div>
						<div class="col" style="margin-left: -20px; margin-top: 0px;">
							<p class="standortText">Sa. & So. / Feiertags</p>
							<p class="standortZeit">09:00 - 17:00 / 09:00 - 14:00 Uhr</p>
						</div>
					</div>
					<div class="row" data-aos="fade-up">
						<div class="col-sm-1">
						</div>
						<div class="col" style="padding-left: 40px; min-width: 300px; margin-top: 30px">
							<p class="standort365">365 Tage!</p>
						</div>
						<div class="col">
						</div>
					</div>
					<div class="row" data-aos="fade-right">
						<div class="col-sm-8">
							<hr class="solid" style="margin-bottom: 50px" id="standortDivider">
						</div>
						<div class="col">
						</div>
					</div>
					<div class="row" data-aos="fade-left">
						<div class="col-sm-1">
							<img src="@/assets/img/logos/Kontakt.png" style="width: 80px;">
						</div>
						<div class="col" style="padding-left: 60px; margin-top: 20px;">
							<p class="miniTitle">Kontakt</p>
						</div>
					</div>
					<div class="row" data-aos="fade-right">
						<div class="col-sm-1">
						</div>
						<div class="col" style="padding-left: 40px; margin-top: 0px;">
							<p class="standortText">Daimlerstraße 2<br>75305 Neuenbürg</p>
						</div>
					</div>
					<div class="row" data-aos="fade-left">
						<div class="section-1" style="width: 3%;">
						</div>
						<div class="col" style="padding-left: 40px; margin-top: 0px;">
							<p class="standortText"><img src="@/assets/img/logos/telefon_thumb.png"
									style="width: 35px; margin-top:-5px;"> Tel.: 0 70 82 / 94 11 44</p>
						</div>
					</div>
					<div class="row" data-aos="fade-left">
						<div class="section-1" style="width: 3%;">
						</div>
						<div class="col" style="padding-left: 40px; margin-top: 0px;">
							<p class="standortText" id="test"><img src="@/assets/img/logos/email_thumb.png"
									style="width: 35px; margin-top:-10px"> <a href="mailto:info@forum-neuenbuerg.de"
									style="color: white">E-Mail: info@forum-neuenbuerg.de</a></p>
						</div>
					
					</div>
					<img id="EggRedDesktop" style="display: none;" src="@/assets/img/Ostern/EiRot.png" alt="Ei1" v-on:click="eggFound('rot')">
				</div>
			</div>
			<div class="section-1" style="width: 51%; margin-top: 5%; padding-right: 100px;:" data-aos="fade-down">

				<div id="maps" style="width: 100%; height:90%"></div>
				<div class="row" style=" padding-bottom: 20px;">
					<div class="col-12 justify-content-center text-center">
						<button id="cookieBtn3" type="button" class="btn btn-dark" data-toggle="modal"
							data-target="#CookieModal" style="">
							Cookie-Einstellungen
						</button>

					</div>
				</div>
			</div>
		</div>

		<div class="row " id="standortRow_2" style="margin-top: 20px;">
			<div class="col-sm-12 " style="">
				<div class="oeffnungszeiten_1">
					<div class="row justify-content-center text-center" id="zeiten_1" style="" data-aos="fade-right">
						<div class="col-sm-1">
						</div>
						<div class="col-4">
							<img src="@/assets/img/logos/Uhr.png" style="width: 80px; float: right">
						</div>
						<div class="col" style="margin-top: 15px;">
							<p class="miniTitle" style=" float: left">Öffnungszeiten</p>
						</div>
					</div>
					<div class="row" id="zeiten_2" style="margin-top: 10px; margin-left: 5%" data-aos="fade-left">
						<div class="col-sm-1" style="">
						</div>
						<div class="col-sm-4 text-right" style="">
							<p class="standortText ">Montag - Freitag</p>
							<p class="standortZeit ">06:00 - 22:00</p>
						</div>
						<div class="col-sm-1 justify-content-center" style="margin-left: 2.5%; margin-right: 0%;">
							<img src="@/assets/img/logos/trennstrich.png" style="width: 20px;">
						</div>
						<div class="col text-left" id="samstag_1" style="margin-left: -2%; margin-top: 0px;">
							<p class="standortText">Sa. & So. / Feiertags</p>
							<p class="standortZeit">09:00 - 17:00 / 09:00 - 14:00 Uhr</p>
						</div>
					</div>
				</div>

				<div class="oeffnungszeiten_2">

					<div class="row justify-content-center text-center" style="margin-left: -180px"
						data-aos="fade-right">

						<div class="col">
							<img src="@/assets/img/logos/Uhr.png" style="width: 80px; float: right">
						</div>
						<div class="col" style="margin-top: 15px;">
							<p class="miniTitle" style=" float: left">Öffnungszeiten</p>
						</div>
					</div>
					<div class="row" style="margin-top: 10px;" data-aos="fade-left">
						<div class="col-sm-12 text-center" style="">
							<p class="standortText ">Montag - Freitag</p>
							<p class="standortZeit ">06:00 - 22:00</p>
						</div>

						<div class="col-sm-12 text-center" style="margin-top: 0px;">
							<p class="standortText">Sa. & So. / Feiertags</p>
							<p class="standortZeit">09:00 - 17:00 / 09:00 - 14:00 Uhr</p>
						</div>

					</div>
				</div>
				<div class="row" data-aos="fade-up">

					<div class="col justify-content-center text-center" style=" min-width: 300px; margin-top: 30px">
						<p class="standort365">365 Tage!</p>
					</div>

				</div>
				<div class="row" data-aos="fade-right">
					<div class="col justify-content-center text-center">
						<hr class="solid" style="margin-bottom: 50px" id="standortDivider">
					</div>

				</div>



				<div class="row justify-content-center text-center" id="kontakt_1" data-aos="fade-left">
					<div class="col">
						<img src="@/assets/img/logos/Kontakt.png" style="width: 80px; float: right">
					</div>
					<div class="col" style=" margin-top: 20px;">
						<p class="miniTitle" style=" float: left">Kontakt</p>
					</div>
				</div>
				<div class="row" data-aos="fade-right">

					<div class="col text-center" style=" margin-top: 20px;">
						<p class="standortText">Daimlerstraße 2<br>75305 Neuenbürg</p>
					</div>
				</div>
				<div class="row" data-aos="fade-left">

					<div class="col justify-content-center text-center" style=" margin-top: 0px;">
						<p class="standortText"><img src="@/assets/img/logos/telefon_thumb.png"
								style="width: 35px; margin-top:-5px;"> Tel.: 0 70 82 / 94 11 44</p>
					</div>
				</div>
				<div class="row" data-aos="fade-left">

					<div class="col justify-content-center text-center" style="p; margin-top: 0px;">
						<p class="standortText" id="test"><img src="@/assets/img/logos/email_thumb.png"
								style="width: 35px; margin-top:-10px"> <a href="mailto:info@forum-neuenbuerg.de"
								style="color: white">E-Mail: info@forum-neuenbuerg.de</a></p>
					</div>
				
				</div>
				<img id="EggRedMobile" style="display: none;" src="@/assets/img/Ostern/EiRot.png" alt="Ei1" v-on:click="eggFound('rot')">
			</div>
		</div>
		<div class="row" id="standortRow_2">
			<div class="col justify-content-center text-center" style="margin: 5%;  margin-bottom: 200px;"
				data-aos="fade-down">
				<div id="mapsMobile" style="width: 100%; height:150%"></div>
				<button type="button" id="cookieBtn4" class="btn btn-dark" data-toggle="modal"
					data-target="#CookieModal" style="margin-top: 0px; ">
					Cookie-Einstellungen
				</button>

			</div>


		</div>

	</section>
</template>

<script>
	import Cookies from "../../components/Cookies";
	import axios from "axios";
	import $ from "jquery";
	export default {
		components: {
			Cookies
		},
		mounted() {
			if (this.$cookies.isKey('user_session')) {
				document.getElementById('cookies').style.cssText = 'display: none!important;'
				document.getElementById('cookieBtn3').style.cssText = 'display: none'
				document.getElementById('cookieBtn4').style.cssText = 'display: none'
				document.getElementById('maps').innerHTML =
					'<iframe id=\"google_maps\" src=\"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d34314.437026142215!2d8.551987925153067!3d48.85497779701227!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x479712cfe88f5205%3A0x4a6cad22c639d1f2!2sFitness-%20und%20Gesundheitsforum!5e0!3m2!1sde!2sde!4v1605125980110!5m2!1sde!2sde\" width=\"100%\" height=\"90%\" frameborder=\"0\" style=\"border:0\" ></iframe>'
				document.getElementById('mapsMobile').innerHTML =
					'<iframe id=\"google_maps\" src=\"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d34314.437026142215!2d8.551987925153067!3d48.85497779701227!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x479712cfe88f5205%3A0x4a6cad22c639d1f2!2sFitness-%20und%20Gesundheitsforum!5e0!3m2!1sde!2sde!4v1605125980110!5m2!1sde!2sde\" width=\"100%\" height=\"150%\" frameborder=\"0\" style=\"border:0\" ></iframe>'
			} else {
				document.getElementById('cookieBtn3').style.cssText = 'display: inline'
				document.getElementById('cookieBtn4').style.cssText = 'display: inline'
				document.getElementById('maps').innerHTML =
					'<p style="color: white; padding-top: 300px; text-align: center">Inhalt blockiert da keine Cookies zuglassen sind.</p>'
				document.getElementById('maps').style.cssText = 'background-color: grey; width: 100%; height: 90%'
				document.getElementById('mapsMobile').innerHTML =
					'<p style="color: white; padding-top: 50px;">Inhalt blockiert da keine Cookies zuglassen sind.</p>'
				document.getElementById('mapsMobile').style.cssText = 'background-color: grey; width: 100%; height: 150%'

			}
			AOS => AOS.init();
			axios.get(
					"https://forum-neuenbuerg.de/action.php?username=web166&pw=BX79HmqzoxKNb4OY&request=eggsActive"
				)
				.then(response => {
					response.data.forEach(egg => {

						if (egg.color == "rot") {
							document.getElementById('EggRedDesktop').style.cssText =
								"display: block; cursor: pointer"
							document.getElementById('EggRedMobile').style.cssText =
								"display: block; cursor: pointer"
						}
					});
				});
		},
		methods: {
			eggFound(clr) {

				this.$store.state.klickedEgg = clr
				$('#EggModal').modal('show')
			},
		}
	}
</script>

<style>
	@media only screen and (max-width: 1585px) {
		hr.solid {
			width: 400px !important;
		}
	}

	@media only screen and (max-width: 1290px) {
		#standortRow_1 {
			display: none;
		}
	}

	@media only screen and (min-width: 1291px) {
		#standortRow_2 {
			display: none;
		}
	}

	@media only screen and (max-width: 1030px) {
		#samstag_1 {
			margin-left: -0.5% !important;
		}
	}

	@media only screen and (max-width: 860px) {
		#zeiten_1 {
			margin-left: -5% !important;
		}
	}

	@media only screen and (max-width: 800px) {
		#samstag_1 {
			margin-left: 0.5% !important;
		}
	}

	@media only screen and (max-width: 640px) {
		#zeiten_2 {
			margin-left: -5% !important;
		}

		#zeiten_1 {
			margin-left: -12.5% !important;
		}

		#samstag_1 {
			margin-left: 2.5% !important;
		}
	}

	@media only screen and (max-width: 576px) {
		.oeffnungszeiten_1 {
			display: none;
		}
	}

	@media only screen and (min-width: 577px) {
		.oeffnungszeiten_2 {
			display: none;
		}
	}

	#kontakt_1 {
		margin-left: -7.5%;
	}



	hr.solid {
		border-top: 2px solid white;


	}



	.standort365 {
		color: white;
		font-family: noto-sans-display-extraconde, sans-serif;
		font-size: 70px;
		font-style: italic;
		margin-top: -10px;
	}

	.standortContainer {
		background-color: #fbce3b;
	}

	#standortTitle h2 {
		color: white;
		font-family: noto-sans-display-extraconde, sans-serif;
		font-size: 70px;
		font-style: italic;
	}

	#standortTitle h4 {
		color: #4D4D4D;
		font-family: noto-sans-display-extraconde, sans-serif;
		font-size: 35px;
		font-style: italic;
		margin-top: -10px;
	}

	.miniTitle {
		color: #4D4D4D;
		font-size: 30px;
		font-style: italic;
		font-weight: 600;
	}

	.standortText {
		color: white;
		font-size: 20px;
		font-style: italic;
		font-weight: 600;
	}

	.standortZeit {
		margin-top: -20px;
		color: #4D4D4D;
		font-size: 25px;
		font-style: italic;
		font-weight: 600;
	}
</style>