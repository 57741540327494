<template>
   <div class="profile-page"  style="background-color: #F2F2F2;">
        <section class="section-profile-cover section-shaped my-0" id="headerKarriere">
           
        </section>
        <section class="section section-skew">
            <div class="container">
                 <EggPopup></EggPopup>
                <card shadow class="card-profile mt--300" no-body style="background-color: white;">
                    <div class="px-4">
                        <div class="row ">
                            <div class="col">
                                <div class="mt-3">
                                    <h2 style="color:#fbce3b">KARRIERE</h2>
                                </div>
                            </div>
                            <div class="col">
                                <base-button id="kontaktButton" type="default" size="sm" class="float-right mt-4">Kontakt</base-button>
                            </div>
                        </div>
                        <div class="mt-3 py-3 border-top ">
                            <div class="mt-3" data-aos="fade-right">
                                 <p ><strong>Wir sind stets auf der Suche nach Verstärkung für unser Team.
									 <br> Du bist interessiert daran in einem familiären Betrieb und einer vielversprechenden Position in der Fitnessbranche zu arbeiten?
									 <br> Dann bewerbe Dich jetzt!</strong></p>
                            </div>
							<hr>
							<div class="mt-3"  data-aos="fade-right">
							 <h4 style="color:#212529; font-size: 20px;">Unsere Stellenangebote</h4>
							  <!-- <router-link :to="{ name: 'stellenangebotTemplate' }">
							 	<a>Dualer Student / Trainer (m/w/d)</a>
							  </router-link> -->
                              <img id="EggOrange" style="display: none;" src="@/assets/img/Ostern/EiOrange.png" alt="Ei1" v-on:click="eggFound('orange')">
                              <a style="color:#fbce3b;"><strong>Derzeit suchen wir keine neuen Mitarbeiter!</strong></a>
							</div>
                        </div>
                    </div>
                </card>
            </div>
        </section>
    </div>
</template>

<script>
import $ from "jquery";
  import axios from "axios";
  import EggPopup from "../components/EggPopup";
export default {
    components: {
    EggPopup
    },

    
mounted() {
			AOS => AOS.init();
			axios.get(
					"https://forum-neuenbuerg.de/action.php?username=web166&pw=BX79HmqzoxKNb4OY&request=eggsActive"
				)
				.then(response => {
					response.data.forEach(egg => {

						if (egg.color == "orange") {
							document.getElementById('EggOrange').style.cssText =
								"display: block; cursor: pointer"
						}
					});
				});
		},
		methods: {
			eggFound(clr) {

				this.$store.state.klickedEgg = clr
				$('#EggModal').modal('show')
			},
		}
}
</script>

<style>
@media only screen and (max-width: 434px) {
    #kontaktButton{
        width:100%;
    }
}
  @media only screen and (max-width: 888px) {
      #headerKarriere {        
          min-height: 800px!important;
           background-position-y: 0px!important;     
      }
  }

#headerKarriere {        
		min-height: 560px!important;
        background-image: url("../assets/img/logos/care-male-healthy-weights-athletic.jpg");
        background-size: cover;
        background-position-y: -100px;       
		background-repeat: no-repeat;
		position: relative;
       
	}
</style>