<template>
  <div class="containerLeistungenMobileSmall">
    <div
      class="row d-flex justify-content-center "
      data-aos="fade-up"
      style=" padding-bottom: 0px; padding-top: 20px;"
      id="rowLeistung1_1"
    >
      <div class="col-sm-6 justify-content-center text-center">
        <div class="col justify-content-center text-center">
          <a
            v-on:click="showDetails(1)"
            class="justify-content-center text-center"
            style="position: relative;
    margin-left: -00px;"
          >
            <img src="@/assets/img/Leistungen/Geraete1.jpg" id="Leistung1" />
            <img
              src="@/assets/img/logos/mobileSmallOverlay.png"
              class="mobileSmallOverlayLeistung image-hover"
              id="mobileSmallOverlay1"
            />
          </a>
        </div>
      </div>
    </div>

    <div class="row detailDesktop">
      <div class="col-sm-1"></div>
      <div class="col">
        <div
          class="detail detailMobile"
          id="mobileSmallDetail_1"
          style="display: none;"
        >
          <div class="row">
            <div class="col-sm-12 mobileSmallDetail_left">
              <p class="subtitle">KRAFT- UND AUSDAUERTRAINING</p>

              <p class="leistungText" style="text-align: justify">
                <strong
                  >Für Dein optimales Krafttraining stehen dir modernste
                  Kraftgeräte sowie ein geräumiger Freihantelbereich zur
                  Verfügung. Dein Muskel-Workout wirkt sich positiv auf
                  Wohlbefinden und Ausstrahlung aus.</strong
                >
              </p>
              <p class="leistungText" style="text-align: justify">
                <strong>
                  Für das Ausdauertraining stehen dir eine Vielzahl
                  verschiedener Ausdauergeräte wie Laufband, Treppensteiger,
                  Crosstrainer oder Fahrrad zur Verfügung. Der Cardiobereich ist
                  mit vier Flachbildschirmen ausgestattet.
                </strong>
              </p>
            </div>
          </div>

          <Ger></Ger>
          <img
            id="EggBlauMobileSmall"
            style="display: none;"
            src="@/assets/img/Ostern/EiBlau.png"
            alt="Ei1"
            v-on:click="eggFound('dunkelblau')"
          />
        </div>
      </div>
      <div class="col-sm-1"></div>
    </div>

    <div
      class="row d-flex justify-content-center "
      data-aos="fade-up"
      style=" padding-bottom: 0px; padding-top: 20px;"
      id="rowLeistung1_1"
    >
      <div class="col-sm-6 justify-content-center text-center">
        <div class="col justify-content-center text-center">
          <a
            v-on:click="showDetails(2)"
            class="justify-content-center text-center"
            style="position: relative;
    margin-left: -00px;"
          >
            <img
              src="@/assets/img/Leistungen/Frauengefluester.jpg"
              id="Leistung2"
            />
            <img
              src="@/assets/img/logos/mobileSmallOverlay.png"
              class="mobileSmallOverlayLeistung image-hover"
              id="mobileSmallOverlay2"
            />
          </a>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-1"></div>
      <div class="col">
        <div class="detail" id="mobileSmallDetail_2" style="display: none;">
          <div class="row">
            <div class="col-sm-12 mobileSmallDetail_left">
              <p class="subtitle">FRAUENGEFLÜSTER</p>
              <p class="leistungText" style="text-align: left">
                <strong
                  >Weil Frauen einzigartig sind haben sie bei uns ihren eigenen
                  Trainingsbereich.</strong
                >
              </p>
              <p class="leistungText" style="text-align: left">
                <strong>
                  Im Damenbereich kannst du mit unserem Ganzkörperzirkel deine
                  Kraft verbessern, dein Stoffwechsel anregen, die Haut straffen
                  und dein Wohlbefinden steigern.</strong
                >
              </p>
              <p class="leistungText" style="text-align: left">
                <strong>
                  Der Kraftzirkel ist sowohl für Einsteiger als auch für
                  Fortgeschrittene geeignet. Durch die leicht verstellbaren
                  Widerstandsstufen wird es dir leicht gemacht auf allen Geräten
                  mit deinem persönlichen Leistungsniveau zu trainieren. Eine
                  Lichtampel gibt die Trainingszeiten und die Pausen im Wechsel
                  vor, sodass keine Wartezeiten an den einzelnen Geräten
                  entstehen.
                </strong>
              </p>
            </div>
          </div>
          <FG></FG>
        </div>
      </div>
      <div class="col-sm-1"></div>
    </div>

    <div
      class="row d-flex justify-content-center "
      data-aos="fade-up"
      style=" padding-bottom: 0px; padding-top: 20px;"
      id="rowLeistung1_1"
    >
      <div class="col-sm-6 justify-content-center text-center">
        <div class="col justify-content-center text-center">
          <a
            v-on:click="showDetails(3)"
            class="justify-content-center text-center"
            style="position: relative;
    margin-left: -00px;"
          >
            <img src="@/assets/img/Leistungen/Kurse.jpg" id="Leistung3" />
            <img
              src="@/assets/img/logos/mobileSmallOverlay.png"
              class="mobileSmallOverlayLeistung image-hover"
              id="mobileSmallOverlay3"
            />
          </a>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="row">
        <div class="col-sm-1"></div>
        <div class="col">
          <div class="detail" id="mobileSmallDetail_3" style="display: none;">
            <div class="row">
              <div class="col-sm-12 mobileSmallDetail_left">
                <p class="subtitle">KURSE</p>
                <p class="leistungText" style="text-align: left">
                  <strong
                    >Wenn dir gemeinsames Trainieren Spaß macht, dann bist du
                    bei uns genau richtig!</strong
                  >
                </p>
                <p class="leistungText" style="text-align: left">
                  <strong>
                    Wir bieten eine große Auswahl an Kursen, welche Online und
                    vor Ort genutzt werden können. Unsere Kurse sind sowohl für
                    Anfänger als auch für Fortgeschrittene geeignet. Bei uns
                    findest du ruhige Workouts wie zum Beispiel Entspannung und
                    Mediation und anspruchsvolle Power Stunden, wie Hot Iron 2™,
                    Zumba™ oder BBP.</strong
                  >
                </p>
                <p class="leistungText" style="text-align: left">
                  <strong>
                    Erlebe eine abwechslungsreiche Kursstunde mit unseren
                    qualifizierten Kursleitern.</strong
                  >
                </p>
              </div>
            </div>
            <router-link :to="{ name: 'Kurse' }">
              <button class="btn" id="imgBtn">erfahre mehr!</button>
            </router-link>
          </div>
        </div>
        <div class="col-sm-1"></div>
      </div>
    </div>

    <div
      class="row d-flex justify-content-center "
      data-aos="fade-up"
      style=" padding-bottom: 0px; padding-top: 20px;"
      id="rowLeistung1_1"
    >
      <div class="col-sm-6 justify-content-center text-center">
        <div class="col justify-content-center text-center">
          <a
            v-on:click="showDetails(4)"
            class="justify-content-center text-center"
            style="position: relative;
    margin-left: -00px;"
          >
            <img src="@/assets/img/Leistungen/PP.jpg" id="Leistung1" />
            <img
              src="@/assets/img/logos/mobileSmallOverlay.png"
              class="mobileSmallOverlayLeistung image-hover"
              id="mobileSmallOverlay4"
            />
          </a>
        </div>
      </div>
    </div>

    <div class="row detailDesktop">
      <div class="col-sm-1"></div>
      <div class="col">
        <div
          class="detail detailMobile"
          id="mobileSmallDetail_4"
          style="display: none;"
        >
          <div class="row">
            <div class="col-sm-12 mobileSmallDetail_left">
              <p class="subtitle">POWER PLATE</p>
              <p class="leistungText" style="text-align: left">
                <strong>Fit und Gesund mit geringem Zeitaufwand!</strong>
              </p>
              <p class="leistungText" style="text-align: left">
                <strong
                  >Es reichen schon 2 Trainingseinheiten pro Woche a 20 Minuten
                  aus um Muskelkraft, Figur und Gesundheit nachweislich zu
                  verbessern und zu steigern.
                </strong>
              </p>
              <p class="leistungText" style="text-align: left">
                <strong
                  >Power Plate dient…
                  <ul>
                    <li>
                      der Fettverbrennung / Gewichtsreduktion
                    </li>
                    <li>
                      dem schnellen Muskelaufbau
                    </li>
                    <li>
                      der Figurverbesserung
                    </li>
                    <li>
                      als Entspannungsmassage
                    </li>
                    <li>
                      als Prävention von Rückenschmerzen
                    </li>
                    <li>
                      der Verbesserung der allgemeinen Fitness
                    </li>
                    <li>
                      der Beweglichkeitsverbesserung
                    </li>
                  </ul>
                </strong>
              </p>
            </div>
          </div>
          <PP></PP>
        </div>
      </div>
      <div class="col-sm-1"></div>
    </div>

    <div
      class="row d-flex justify-content-center "
      data-aos="fade-up"
      style=" padding-bottom: 0px; padding-top: 20px;"
      id="rowLeistung2_1"
    >
      <div class="col-sm-6 justify-content-center text-center">
        <div class="col justify-content-center text-center">
          <a
            v-on:click="showDetails(5)"
            class="justify-content-center text-center"
            style="position: relative;
    margin-left: -00px;"
          >
            <img src="@/assets/img/Leistungen/Kids.jpg" id="Leistung2" />
            <img
              src="@/assets/img/logos/mobileSmallOverlay.png"
              class="mobileSmallOverlayLeistung image-hover"
              id="mobileSmallOverlay5"
            />
          </a>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-1"></div>
      <div class="col">
        <div class="detail" id="mobileSmallDetail_5" style="display: none;">
          <div class="row">
            <div class="col-sm-12 mobileSmallDetail_left">
              <p class="subtitle">KIDS</p>
              <p class="leistungText" style="text-align: left">
                <strong
                  >Wir bieten Kurse für ihre Kinder an, damit sie Fitness und
                  Bewegung in ihrem Alltag integrieren und neue Freunde kennen
                  lernen können.
                </strong>
              </p>
              <p class="leistungText" style="text-align: left">
                <strong
                  >Während ihr Kind neue Bewegungen erlernt und zur Ruhe kommen
                  kann, können Sie in dieser Zeit an unseren Geräten trainieren
                  oder sich eine Auszeit im Wellnessbereich gönnen.
                </strong>
              </p>
              <router-link :to="{ name: 'Kurse' }">
                <button class="btn" id="imgBtn">erfahre mehr!</button>
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-1"></div>
    </div>

    <div
      class="row d-flex justify-content-center "
      data-aos="fade-up"
      style=" padding-bottom: 0px; padding-top: 20px;"
      id="rowLeistung2_1"
    >
      <div class="col-sm-6 justify-content-center text-center">
        <div class="col justify-content-center text-center">
          <a
            v-on:click="showDetails(6)"
            class="justify-content-center text-center"
            style="position: relative;
    margin-left: -00px;"
          >
            <img src="@/assets/img/Leistungen/Flexx.jpg" id="Leistung3" />
            <img
              src="@/assets/img/logos/mobileSmallOverlay.png"
              class="mobileSmallOverlayLeistung image-hover"
              id="mobileSmallOverlay6"
            />
          </a>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-1"></div>
      <div class="col">
        <div class="detail" id="mobileSmallDetail_6" style="display: none;">
          <div class="row">
            <div class="col-sm-12 mobileSmallDetail_left">
              <p class="subtitle">FLE.XX</p>
              <p class="leistungText">
                <strong>(Wieder) beweglich werden – und es bleiben!</strong>
              </p>
              <p class="leistungText">
                <strong>
                  Mit dem Fle.xx Rückgratkonzept gewinnst du über eine flexible
                  Muskulatur, neue Beweglichkeit und damit mehr Wohlbefinden für
                  einen schmerzfreien Alltag.</strong
                >
              </p>
              <p class="leistungText">
                <strong>
                  Das Fle.xx Rückgratkonzept…
                  <ul>
                    <li>
                      steigert Beweglichkeit und wirkt Schmerzen entgegen
                    </li>
                    <li>
                      richtet dich wieder auf
                    </li>
                    <li>
                      macht dich locker
                    </li>
                    <li>
                      optimiert die Durchblutung und den Lymphfluss, wodurch
                      Bindegewebe und Muskulatur entgiftet werden
                    </li>
                    <li>
                      schenkt neue Energie
                    </li>
                    <li>
                      erhöht dein Wohlbefinden
                    </li>
                  </ul>
                </strong>
              </p>
            </div>
          </div>
          <Flexx></Flexx>
        </div>
      </div>
      <div class="col-sm-1"></div>
    </div>

    <div
      class="row d-flex justify-content-center "
      data-aos="fade-up"
      style=" padding-bottom: 0px; padding-top: 20px;"
      id="rowLeistung3_1"
    >
      <div class="col-sm-6 justify-content-center text-center">
        <div class="col justify-content-center text-center">
          <a
            v-on:click="showDetails(7)"
            class="justify-content-center text-center"
            style="position: relative;
    margin-left: -00px;"
          >
            <img src="@/assets/img/Leistungen/Tiefenwärme.jpg" id="Leistung1" />
            <img
              src="@/assets/img/logos/mobileSmallOverlay.png"
              class="mobileSmallOverlayLeistung image-hover"
              id="mobileSmallOverlay7"
            />
          </a>
        </div>
      </div>
    </div>

    <div class="row detailDesktop">
      <div class="col-sm-1"></div>
      <div class="col">
        <div
          class="detail detailMobile"
          id="mobileSmallDetail_7"
          style="display: none;"
        >
          <div class="row">
            <div class="col-sm-12 mobileSmallDetail_left">
              <p class="subtitle">TIEFENWÄRME</p>
              <p class="leistungText" style="text-align: left">
                <strong
                  >Um die Problemzonen, wie Oberschenkel, Po, Bauch und Oberarme
                  werden Heizbandagen gelegt, die dann auf ca. 60 Grad erhitzt
                  werden.</strong
                >
              </p>
              <p class="leistungText" style="text-align: left">
                <strong
                  >Durch die Hitze werden abgelagerte Fettpölsterchen erwärmt
                  und können dadurch vom Körper leichter abgebaut werden. Das
                  kann beschleunigend auf den Stoffwechsel wirken und die
                  Durchblutung steigern. Dadurch kann der Abbau des Fettgewebes
                  erleichtert und gefördert werden. Zusätzlich wird während der
                  Behandlung durch die Hautporen viel Flüssigkeit ausgeschieden,
                  was zusätzlich eine Entschlackung des Körpers bewirken kann.
                </strong>
              </p>
              <p class="leistungText" style="text-align: left">
                <strong
                  >Die Tiefenwärme kann dabei helfen:
                  <ul>
                    <li>
                      Veränderung des Umfangs zum Positiven
                    </li>
                    <li>
                      Beeinflussung des Bindegewebes mit dem Ziel der Festigung
                      und Straffung
                    </li>
                    <li>
                      Verbesserung des Abtransports von
                      Stoffwechsel-Abbauprodukten & Giften
                    </li>
                    <li>
                      als Beeinflussung von Problemzonen, wie z.B. den
                      Oberarmen, mit dem Ziel der Festigung Förderung der
                      Durchblutung
                    </li>
                    <li>
                      Entwässerung
                    </li>
                  </ul></strong
                >
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-1"></div>
    </div>

    <div
      class="row d-flex justify-content-center "
      data-aos="fade-up"
      style=" padding-bottom: 0px; padding-top: 20px;"
      id="rowLeistung3_1"
    >
      <div class="col-sm-6 justify-content-center text-center">
        <div class="col justify-content-center text-center">
          <a
            v-on:click="showDetails(8)"
            class="justify-content-center text-center"
            style="position: relative;
    margin-left: -00px;"
          >
            <img src="@/assets/img/Leistungen/BB1.jpg" id="Leistung2" />
            <img
              src="@/assets/img/logos/mobileSmallOverlay.png"
              class="mobileSmallOverlayLeistung image-hover"
              id="mobileSmallOverlay8"
            />
          </a>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-1"></div>
      <div class="col">
        <div class="detail" id="mobileSmallDetail_8" style="display: none;">
          <div class="row">
            <div class="col-sm-12 mobileSmallDetail_left">
              <p class="subtitle">BETTER BELLY</p>
              <p class="leistungText" style="text-align: left">
                <strong
                  >Der Bereich an Bauch und Hüften bleibt während des Trainings
                  überwiegend kalt, weil er nicht so stark durchblutet wird.
                  Daher ist es sehr schwer, genau an diesem Bereich abzunehmen.
                  Aus diesem Grund wurde der Better Belly-Tiefenwärmegürtel
                  entwickelt.</strong
                >
              </p>
              <p class="leistungText" style="text-align: left">
                <strong>
                  Der Gürtel wird um Bauch und Hüfte gelegt und erhitzt sich auf
                  60 Grad Celsius. Man trägt ihn während 45-60 Minuten moderaten
                  Ausdauertrainings auf einem unserer Cardiogeräte.</strong
                >
              </p>
              <p class="leistungText" style="text-align: left">
                <strong>
                  Die Wärme gelangt so in die innere Bauchfettschicht und der
                  Körper wird besser durchblutet – er muss sich anstrengen, um
                  diese Wärme wieder loszuwerden, dadurch kann sich die
                  Fettverbrennung um bis zu 100% erhöhen.
                </strong>
              </p>

              <p class="leistungText" style="text-align: left">
                <strong>
                  Der Better Belly…
                  <ul>
                    <li>
                      stimuliert die Fettverbrennung
                    </li>
                    <li>
                      erhöht den Basalstoffwechsel
                    </li>
                    <li>
                      verringert Rückenbeschwerden
                    </li>
                    <li>
                      unterstützt Heilungsprozesse
                    </li>
                    <li>
                      fördert die Beweglichkeit der Gelenke und Muskeln
                    </li>
                    <li>
                      entfernt Abfallprodukte aus dem Körper
                    </li>
                    <li>
                      hilft bei Menstruationsbeschwerden
                    </li>
                    <li>
                      fördert eine gute Nachtruhe
                    </li>
                  </ul></strong
                >
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-1"></div>
    </div>

    <div
      class="row d-flex justify-content-center "
      data-aos="fade-up"
      style=" padding-bottom: 0px; padding-top: 20px;"
      id="rowLeistung3_1"
    >
      <div class="col-sm-6 justify-content-center text-center">
        <div class="col justify-content-center text-center">
          <a
            v-on:click="showDetails(9)"
            class="justify-content-center text-center"
            style="position: relative;
    margin-left: -00px;"
          >
            <img src="@/assets/img/Leistungen/Band.jpg" id="Leistung3" />
            <img
              src="@/assets/img/logos/mobileSmallOverlay.png"
              class="mobileSmallOverlayLeistung image-hover"
              id="mobileSmallOverlay9"
            />
          </a>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-1"></div>
      <div class="col">
        <div class="detail" id="mobileSmallDetail_9" style="display: none;">
          <div class="row">
            <div class="col-sm-12 mobileSmallDetail_left">
              <p class="subtitle">ROLLE- & BANDMASSAGE</p>
              <p class="leistungText">
                <strong
                  >Die Rolle- & Bandmassage verbessert bei regelmäßiger
                  Anwendung das Hautbild typischen Problemzonen.</strong
                >
              </p>
              <p class="leistungText">
                <strong>
                  Was die Massage alles bewirken kann:
                  <ul>
                    <li>
                      Anregung des Stoffwechsels
                    </li>
                    <li>
                      Entgegenwirken bzw. Rückbildung von Cellulite
                    </li>
                    <li>
                      Regeneration
                    </li>
                    <li>
                      Umfangsreduktion an Problemzonen
                    </li>
                    <li>
                      Steigerung der Durchblutung
                    </li>
                    <li>
                      Straffung von Bindegewebe
                    </li>
                    <li>
                      Abbau von Stoffwechselprodukten, Entwässerung und
                      Entgiftung
                    </li>
                    <li>
                      Lockerung von Verspannungen und Verkrampfungen
                    </li>
                    <li>
                      Förderung der Verdauung
                    </li>
                    <li>
                      Steigerung des Wohlbefindens
                    </li>
                  </ul></strong
                >
              </p>
            </div>
          </div>
          <RB></RB>
        </div>
      </div>
      <div class="col-sm-1"></div>
    </div>

    <div
      class="row d-flex justify-content-center "
      data-aos="fade-up"
      style=" padding-bottom: 0px; padding-top: 20px;"
      id="rowLeistung3_1"
    >
      <div class="col-sm-6 justify-content-center text-center">
        <div class="col justify-content-center text-center">
          <a
            v-on:click="showDetails(10)"
            class="justify-content-center text-center"
            style="position: relative;
    margin-left: -00px;"
          >
            <img
              src="@/assets/img/Leistungen/koerperanalyse.jpg"
              id="Leistung1"
            />
            <img
              src="@/assets/img/logos/mobileSmallOverlay.png"
              class="mobileSmallOverlayLeistung image-hover"
              id="mobileSmallOverlay10"
            />
          </a>
        </div>
      </div>
    </div>

    <div class="row detailDesktop">
      <div class="col-sm-1"></div>
      <div class="col">
        <div
          class="detail detailMobile"
          id="mobileSmallDetail_10"
          style="display: none;"
        >
          <div class="row">
            <div class="col-sm-12 mobileSmallDetail_left">
              <p class="subtitle">KÖRPERANALYSE</p>
              <p class="leistungText" style="text-align: left">
                <strong
                  >Eine professionelle Gesundheitsberatung beginnt mit einem
                  professionellen Check-Up. Die Messung mit dem InBody 770
                  bietet dafür eine perfekte Grundlage, um
                  festzustellen:</strong
                >
              </p>
              <p class="leistungText" style="text-align: left">
                <strong
                  >Wie hoch ist der viszerale Fettbereich und wie wird er sich
                  wahrscheinlich in Zukunft entwickeln?
                  <ul>
                    <li>
                      Wie ist die Muskulatur verteilt und wie steht sie
                      qualitativ im Zusammenhang zu meinem Gesamtkörpergewicht?
                    </li>
                    <li>
                      Wie ist mein Wasserhaushalt? Bin ich dehydriert, habe ich
                      Wassereinlagerungen oder ist mein Wasserhaushalt optimal?
                    </li>
                    <li>
                      Wie steht mein Körpergeeicht im Verhältnis zur Muskel- und
                      zu meiner Fettmasse?
                    </li>
                  </ul></strong
                >
              </p>
              <p class="leistungText" style="text-align: left">
                <strong
                  >Der InBody 770 analysiert die Körperzusammensetzung
                  direkt-segmental über sechs verschiedene Frequenzen (mithilfe
                  Bioelektronischen Impedanzen BIA) und kann so Körperwasser
                  (intra- und extrazellulär) und den Muskel- und Fettanteil in
                  Armen, Beinen und Rumpf präzise bestimmen.</strong
                >
              </p>
              <p class="leistungText" style="text-align: left">
                <strong
                  >Eine professionelle Gesundheitsberatung beginnt mit einem
                  professionellen Check-Up. Die Messung mit dem InBody 770
                  bietet dafür eine perfekte Grundlage, um
                  festzustellen:</strong
                >
              </p>
              <p class="leistungText" style="text-align: left">
                <strong
                  >Darüber hinaus lässt sich der viszerale Fettbereich
                  (Organfett bzw. Innenbauchfett) zur Risikobestimmung für
                  Krankheiten wie z.B. Diabetes TypII oder
                  Herz-Kreislauf-Erkrankungen zu bestimmen. Die segmentale
                  Wasserverhältnisanalyse sowie die Protein- und Mineralienmasse
                  können Hinweise auf den Ernährungszustand, Entzündungen und
                  Verletzungen liefern. Die einzigartige Technologie bei allen
                  InBody- Systemen benötigt keine Durchschnittswerte nach Alter,
                  Geschlecht oder Aktivitätsgrad. Nur die individuellen Werte
                  der gemessenen Personen werden wiedergegeben.</strong
                >
              </p>
              <p class="leistungText" style="text-align: left">
                <strong
                  >Deine Messungen werden während deines Trainingsverlaufs immer
                  wieder herangezogen, um Dir deine Fortschritte aufzuzeigen und
                  deine Ziele nicht aus den Augen zu verlieren! Egal ob Du viel
                  Gewicht verlieren, an Muskeln aufbauen oder einen straffen
                  Bikini-Body bekommen möchtest – der InBody-Check ist eine
                  perfekte Basis für dein individuelles Fitnessprogramm!</strong
                >
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-1"></div>
    </div>

    <div
      class="row d-flex justify-content-center "
      data-aos="fade-up"
      style=" padding-bottom: 0px; padding-top: 20px;"
      id="rowLeistung4_1"
    >
      <div class="col-sm-6 justify-content-center text-center">
        <div class="col justify-content-center text-center">
          <a
            v-on:click="showDetails(11)"
            class="justify-content-center text-center"
            style="position: relative;
    margin-left: -00px;"
          >
            <img src="@/assets/img/Leistungen/Infrarot.jpg" id="Leistung2" />
            <img
              src="@/assets/img/logos/mobileSmallOverlay.png"
              class="mobileSmallOverlayLeistung image-hover"
              id="mobileSmallOverlay11"
            />
          </a>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-1"></div>
      <div class="col">
        <div class="detail" id="mobileSmallDetail_11" style="display: none;">
          <div class="row">
            <div class="col-sm-12 mobileSmallDetail_left">
              <p class="subtitle">INFRAROTKABINE</p>
              <p class="leistungText" style="text-align: left">
                <strong>Wärme für mehr Gesundheit und Wohlbefinden.</strong>
              </p>
              <p class="leistungText" style="text-align: left">
                <strong>
                  Das Infrarotlicht kann auf eine individuelle Temperatur
                  eingestellt werden. Wenn das Licht auf die Haut trifft, wird
                  diese gleichmäßig erwärmt. Hierdurch wird die Durchblutung
                  angeregt.</strong
                >
              </p>
              <p class="leistungText" style="text-align: left">
                <strong>
                  Das erwärmte Blut der Hautoberfläche kann sich so im gesamten
                  Körper verteilen, ohne dem Körper das Gefühl zu vermitteln,
                  dass er überhitzt.</strong
                >
              </p>

              <p class="leistungText" style="text-align: left">
                <strong>
                  Die positive Wirkung einer Infrarotkabine:
                  <ul>
                    <li>
                      Infrarotstrahlung wird auf den Körper abgegeben
                    </li>
                    <li>
                      Erst wenn das Licht auf die Haut trifft, entsteht Wärme
                    </li>
                    <li>
                      Das Immunsystem wird gestärkt
                    </li>
                    <li>
                      Muskelregeneration wird beschleunigt
                    </li>
                    <li>
                      Die Durchblutung wird angeregt
                    </li>
                  </ul></strong
                >
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-1"></div>
    </div>

    <div
      class="row d-flex justify-content-center "
      data-aos="fade-up"
      style=" padding-bottom: 0px; padding-top: 20px;"
      id="rowLeistung4_1"
    >
      <div class="col-sm-6 justify-content-center text-center">
        <div class="col justify-content-center text-center">
          <a
            v-on:click="showDetails(12)"
            class="justify-content-center text-center"
            style="position: relative;
    margin-left: -00px;"
          >
            <img src="@/assets/img/Leistungen/Sauna.jpg" id="Leistung3" />
            <img
              src="@/assets/img/logos/mobileSmallOverlay.png"
              class="mobileSmallOverlayLeistung image-hover"
              id="mobileSmallOverlay12"
            />
          </a>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-1"></div>
      <div class="col">
        <div class="detail" id="mobileSmallDetail_12" style="display: none;">
          <div class="row">
            <div class="col-sm-12 mobileSmallDetail_left">
              <p class="subtitle">SAUNA</p>
              <p class="leistungText">
                <strong
                  >Unsere Saune bietet dir nicht nur Wärme und Entspannung,
                  sondern auch eine wohltuende Atmosphäre. Die Sauna hat 365
                  Tage im Jahr für Dich geöffnet und kann zu den Öffnungszeiten
                  des Forums genutzt werden. (Mo. – Fr. 6-22 Uhr und Sa./So.
                  9-17 Uhr und Feiertags 9-17 Uhr) Die Temperatur beträgt 84
                  Grad und ist immer für alle Geschlechter geöffnet. Komm und
                  erhole Dich in unserem Saunabereich.</strong
                >
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-1"></div>
    </div>

    <div
      class="row d-flex justify-content-center "
      data-aos="fade-up"
      style=" padding-bottom: 0px; padding-top: 20px;"
      id="rowLeistung5_1"
    >
      <div class="col-sm-6 justify-content-center text-center">
        <div class="col justify-content-center text-center">
          <a
            v-on:click="showDetails(13)"
            class="justify-content-center text-center"
            style="position: relative;
    margin-left: -00px;"
          >
            <img src="@/assets/img/Leistungen/Massage.jpg" id="Leistung1" />
            <img
              src="@/assets/img/logos/mobileSmallOverlay.png"
              class="mobileSmallOverlayLeistung image-hover"
              id="mobileSmallOverlay13"
            />
          </a>
        </div>
      </div>
    </div>

    <div class="row detailDesktop" style="margin-bottom: 60px;">
      <div class="col-sm-1"></div>
      <div class="col">
        <div
          class="detail detailMobile"
          id="mobileSmallDetail_13"
          style="display: none;"
        >
          <div class="row">
            <div class="col-sm-12 mobileSmallDetail_left">
              <p class="subtitle">MASSAGELIEGE/FUßMASSAGE</p>
              <p class="leistungText" style="text-align: left">
                <strong
                  >Unser Wellnessbereich verfügt über eine Massageliege und ein
                  Fußmassagegerät. Beide Geräte lassen sich individuell auf die
                  Bedürfnisse unserer Kunden einstellen. Massiert wirst du durch
                  Vibration. An kalten Tagen kannst du zusätzlich die
                  Wärmefunktion beider Geräte einschalten.</strong
                >
              </p>
            </div>
          </div>
          <Massage></Massage>
        </div>
      </div>
      <div class="col-sm-1"></div>
    </div>

    <div
      class="row d-flex justify-content-center "
      data-aos="fade-up"
      style=" padding-bottom: 0px; margin-top: -40px;"
      id="rowLeistung5_1"
    >
      <div class="col-sm-6 justify-content-center text-center">
        <div class="col justify-content-center text-center">
          <a
            v-on:click="showDetails(14)"
            class="justify-content-center text-center"
            style="position: relative;
    margin-left: -00px;"
          >
            <img src="@/assets/img/Leistungen/Soli.jpg" id="Leistung2" />
            <img
              src="@/assets/img/logos/mobileSmallOverlay.png"
              class="mobileSmallOverlayLeistung image-hover"
              id="mobileSmallOverlay14"
            />
          </a>
        </div>
      </div>
    </div>
    <div class="row" style="margin-top: 0px;">
      <div class="detail" id="mobileSmallDetail_14" style="display: none; ">
        <div class="col-sm-6 mobileSmallDetail_left">
          <p class="subtitle">SOLARIUM</p>
          <p class="leistungText" style="text-align: left">
            <strong
              >Ein Gang unters Solarium lässt nicht nur die Haut schöner und
              weicher strahlen, er kann auch dazu beitragen, dass Falten und
              feine Linien gemindert werden.  Aufenthalte im Solarium verfeinern
              die Poren und verbessern das Hautbild. Außerdem wird die
              Produktion von Collagen, Elastin und Hyaluron angeregt.
            </strong>
          </p>
          <p class="leistungText" style="text-align: left">
            <strong>
              Zudem ist Sonnenlicht bzw. UV-Strahlung für die Vitamin D3-Bildung
              im Körper verantwortlich - zwar nehmen wir mit der Nahrung Vitamin
              D auf, dieses kann aber nur mit Hilfe von ultravioletten Strahlen
              in Vitamin D3 umgewandelt werden. Dieses Vitamin ist eine
              Hormon-Vorstufe und für die Bildung von Knochen und Zähnen von
              großer Bedeutung, daher kann ein Gang ins Sonnenstudio
              Mangelerscheinungen vorbeugen.
            </strong>
          </p>
          <p class="leistungText" style="text-align: left">
            <strong>
              Für alle, die sich noch nicht unter das Solarium getraut haben,
              gibt es gute Neuigkeiten: Eine Studie des Boston University
              Medical Centers besagt, dass moderne Solarien nicht das
              Hautkrebsrisiko erhöhen. Ein Zusammenhang zwischen
              Sonnenbanknutzung und erhöhtem Auftreten von Hautkrebs wurde nicht
              festgestellt.
            </strong>
          </p>
        </div>
      </div>
    </div>
    <div
      class="row d-flex justify-content-center "
      data-aos="fade-up"
      style=" padding-bottom: 0px; margin-top: 20px;"
      id="rowLeistung5_1"
    >
      <div class="col-sm-6 justify-content-center text-center">
        <div class="col justify-content-center text-center">
          <a
            v-on:click="showDetails(15)"
            class="justify-content-center text-center"
            style="position: relative;
    margin-left: -00px;"
          >
            <img src="@/assets/img/Leistungen/Ballancer.jpg" id="Leistung2" />
            <img
              src="@/assets/img/logos/mobileSmallOverlay.png"
              class="mobileSmallOverlayLeistung image-hover"
              id="mobileSmallOverlay15"
            />
          </a>
        </div>
      </div>
    </div>
    <div class="row" style="margin-top: 0px;">
      <div class="detail" id="mobileSmallDetail_15" style="display: none; ">
        <div class="col-sm-6 mobileSmallDetail_left">
          <p class="subtitle">BALLANCER</p>
          <p class="leistungText" style="text-align: left">
            <strong
              >Entspannen und gleichzeitig so Gutes für den Körtper tun.</strong
            >
          </p>
          <p class="leistungText" style="text-align: left">
            <strong
              >Der Ballancer ist eine entspannende Kompressionsmassage. Er wird
              in den Bereichen Beauty, Wellness, Sport, Gewichtsreduktion und
              Regeneration eingesetzt.</strong
            >
          </p>
          <p class="leistungText" style="text-align: left">
            <strong>
              Der Stoffwechsel wird um 280% erhöht und die eingelagerten
              Schadstoffe sowie Schlacken werden über das aktive Lymphsystem
              abtransportiert und somit entfernt. Außerdem beugt der Ballancer
              Schwellungen vor und trägt somit zur Reduktion von Cellulite bei –
              in kürzester Zeit!</strong
            >
          </p>
          <p class="leistungText" style="text-align: left">
            <strong>
              Die Behandlungen können entweder am Oberkörper oder am Unterkörper
              erfolgen. Dabei wird nacheinander Luft in einzelnen Kammern der
              Ballancerhose oder Jacke gepumpt. Der Ballancer steuert hierbei
              die Befüllung und Entleerung des Drucks in exakten Zyklen.
            </strong>
          </p>

          <p class="leistungText" style="text-align: left">
            <strong>
              Die entstehenden Druckwellen helfen dabei:

              <ul>
                <li>
                  Cellulite zu mindern
                </li>
                <li>
                  Die Haut zu straffen
                </li>
                <li>
                  Die Regeneration zu fördern
                </li>
                <li>
                  Lymphstauungen zu entfernen
                </li>
                <li>
                  Wasseransammlungen zu lösen
                </li>
                <li>
                  Schwellungen zu mindern
                </li>
                <li>
                  Schadstoffe abtransportieren
                </li>
                <li>
                  Müde und schwere Beine zu lindern
                </li>
              </ul>
            </strong>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";
import PP from "../../components/PP";
import RB from "../../components/RB";
import Flexx from "../../components/Flexx";

import Massage from "../../components/Massage";
import Ger from "../../components/Ger";
import FG from "../../components/FG";

import axios from "axios";
export default {
  components: {
    PP,
    RB,
    Flexx,
    Massage,
    Ger,
    FG,
  },
  data() {
    return {
      index: null,
    };
  },
  mounted() {
    (AOS) => AOS.init();
    axios
      .get(
        "https://forum-neuenbuerg.de/action.php?username=web166&pw=BX79HmqzoxKNb4OY&request=eggsActive"
      )
      .then((response) => {
        response.data.forEach((egg) => {
          if (egg.color == "dunkelblau") {
            document.getElementById("EggBlauMobileSmall").style.cssText =
              "display: block; cursor: pointer";
          }
        });
      });
  },
  methods: {
    eggFound(clr) {
      this.$store.state.klickedEgg = clr;
      $("#EggModal").modal("show");
    },
    showLightbox(i) {
      this.index = i;
    },
    showDetails(index) {
      var detail = [];
      switch (index) {
        case 1:
          detail[0] = 2;
          detail[1] = 3;
          detail[2] = 4;
          detail[3] = 5;
          detail[4] = 6;
          detail[5] = 7;
          detail[6] = 8;
          detail[7] = 9;
          detail[8] = 10;
          detail[9] = 11;
          detail[10] = 12;
          detail[11] = 13;
          detail[12] = 14;
          detail[13] = 15;
          break;
        case 2:
          detail[0] = 1;
          detail[1] = 3;
          detail[2] = 4;
          detail[3] = 5;
          detail[4] = 6;
          detail[5] = 7;
          detail[6] = 8;
          detail[7] = 9;
          detail[8] = 10;
          detail[9] = 11;
          detail[10] = 12;
          detail[11] = 13;
          detail[12] = 14;
          detail[13] = 15;

          break;
        case 3:
          detail[0] = 2;
          detail[1] = 1;
          detail[2] = 4;
          detail[3] = 5;
          detail[4] = 6;
          detail[5] = 7;
          detail[6] = 8;
          detail[7] = 9;
          detail[8] = 10;
          detail[9] = 11;
          detail[10] = 12;
          detail[11] = 13;
          detail[12] = 14;
          detail[13] = 15;

          break;
        case 4:
          detail[0] = 2;
          detail[1] = 3;
          detail[2] = 1;
          detail[3] = 5;
          detail[4] = 6;
          detail[5] = 7;
          detail[6] = 8;
          detail[7] = 9;
          detail[8] = 10;
          detail[9] = 11;
          detail[10] = 12;
          detail[11] = 13;
          detail[12] = 14;
          detail[13] = 15;

          break;
        case 5:
          detail[0] = 1;
          detail[1] = 3;
          detail[2] = 4;
          detail[3] = 2;
          detail[4] = 6;
          detail[5] = 7;
          detail[6] = 8;
          detail[7] = 9;
          detail[8] = 10;
          detail[9] = 11;
          detail[10] = 12;
          detail[11] = 13;
          detail[12] = 14;
          detail[13] = 15;

          break;
        case 6:
          detail[0] = 1;
          detail[1] = 3;
          detail[2] = 4;
          detail[3] = 2;
          detail[4] = 5;
          detail[5] = 7;
          detail[6] = 8;
          detail[7] = 9;
          detail[8] = 10;
          detail[9] = 11;
          detail[10] = 12;
          detail[11] = 13;
          detail[12] = 14;
          detail[13] = 15;

          break;
        case 7:
          detail[0] = 1;
          detail[1] = 3;
          detail[2] = 4;
          detail[3] = 2;
          detail[4] = 6;
          detail[5] = 5;
          detail[6] = 8;
          detail[7] = 9;
          detail[8] = 10;
          detail[9] = 11;
          detail[10] = 12;
          detail[11] = 13;
          detail[12] = 14;
          detail[13] = 15;

          break;
        case 8:
          detail[0] = 1;
          detail[1] = 3;
          detail[2] = 4;
          detail[3] = 2;
          detail[4] = 6;
          detail[5] = 5;
          detail[6] = 7;
          detail[7] = 9;
          detail[8] = 10;
          detail[9] = 11;
          detail[10] = 12;
          detail[11] = 13;
          detail[12] = 14;
          detail[13] = 15;

          break;
        case 9:
          detail[0] = 2;
          detail[1] = 3;
          detail[2] = 4;
          detail[3] = 5;
          detail[4] = 6;
          detail[5] = 7;
          detail[6] = 8;
          detail[7] = 1;
          detail[8] = 10;
          detail[9] = 11;
          detail[10] = 12;
          detail[11] = 13;
          detail[12] = 14;
          detail[13] = 15;

          break;
        case 10:
          detail[0] = 2;
          detail[1] = 3;
          detail[2] = 4;
          detail[3] = 5;
          detail[4] = 6;
          detail[5] = 7;
          detail[6] = 8;
          detail[7] = 9;
          detail[8] = 1;
          detail[9] = 11;
          detail[10] = 12;
          detail[11] = 13;
          detail[12] = 14;
          detail[13] = 15;

          break;
        case 11:
          detail[0] = 2;
          detail[1] = 3;
          detail[2] = 4;
          detail[3] = 5;
          detail[4] = 6;
          detail[5] = 7;
          detail[6] = 8;
          detail[7] = 9;
          detail[8] = 10;
          detail[9] = 1;
          detail[10] = 12;
          detail[11] = 13;
          detail[12] = 14;
          detail[13] = 15;

          break;
        case 12:
          detail[0] = 2;
          detail[1] = 3;
          detail[2] = 4;
          detail[3] = 5;
          detail[4] = 6;
          detail[5] = 7;
          detail[6] = 8;
          detail[7] = 9;
          detail[8] = 10;
          detail[9] = 11;
          detail[10] = 1;
          detail[11] = 13;
          detail[12] = 14;
          detail[13] = 15;

          break;
        case 13:
          detail[0] = 2;
          detail[1] = 3;
          detail[2] = 4;
          detail[3] = 5;
          detail[4] = 6;
          detail[5] = 7;
          detail[6] = 8;
          detail[7] = 9;
          detail[8] = 10;
          detail[9] = 11;
          detail[10] = 12;
          detail[11] = 1;
          detail[12] = 14;
          detail[13] = 15;

          break;
        case 14:
          detail[0] = 2;
          detail[1] = 3;
          detail[2] = 4;
          detail[3] = 5;
          detail[4] = 6;
          detail[5] = 7;
          detail[6] = 8;
          detail[7] = 9;
          detail[8] = 10;
          detail[9] = 11;
          detail[10] = 12;
          detail[11] = 1;
          detail[12] = 13;
          detail[13] = 15;

          break;
        case 15:
          detail[0] = 2;
          detail[1] = 3;
          detail[2] = 4;
          detail[3] = 5;
          detail[4] = 6;
          detail[5] = 7;
          detail[6] = 8;
          detail[7] = 9;
          detail[8] = 10;
          detail[9] = 11;
          detail[10] = 12;
          detail[11] = 1;
          detail[12] = 13;
          detail[13] = 14;

          break;
      }
      var showDetails = document.getElementById("mobileSmallDetail_" + index);
      var mobileSmallOverlay = document.getElementById(
        "mobileSmallOverlay" + index
      );
      var opacityDetail = window
        .getComputedStyle(showDetails)
        .getPropertyValue("opacity");
      if (showDetails.style.display === "none") {
        showDetails.style.cssText = "display: block; margin-top: 0px";
        mobileSmallOverlay.style.cssText = "opacity: 0;";
        for (var i = 0; i < detail.length; i++) {
          var hideDetails = document.getElementById(
            "mobileSmallDetail_" + detail[i]
          );
          var hidemobileSmallOverlay = document.getElementById(
            "mobileSmallOverlay" + detail[i]
          );
          hideDetails.style.cssText = "display: none;  margin-top: 0px";
          hidemobileSmallOverlay.style.cssText = "opacity: 1; width: 400px;";
        }
      } else {
        showDetails.style.cssText = "display: none;";
        mobileSmallOverlay.style.cssText = "opacity: 1; width: 400px;";
      }
    },
  },
};
</script>

<style scoped>
.mobileSmallOverlayLeistung:hover {
  cursor: pointer;
}

.mobileSmallOverlayLeistung {
  width: 400px;
  /* margin-left: 15px!important; */
  position: absolute;
  margin-top: -121px;
  opacity: 1;
  /* left: 22.5%; */
  /* transition: 1s ease; */
}

.mobileSmallOverlayLeistung {
  width: 250px !important;
  margin-top: -65px !important;
}

#Leistung1,
#Leistung2,
#Leistung3 {
  width: 350px !important;
}

.mobileSmallOverlayLeistung {
  width: 350px !important;
  margin-top: -90px !important;
}

.containerLeistungenMobileSmall {
  padding-right: 40px;
}

@media only screen and (max-width: 730px) {
  .containerLeistungenMobileSmall {
    padding-right: 60px;
  }
}

@media only screen and (max-width: 714px) {
  #Leistung1,
  #Leistung2,
  #Leistung3 {
    width: 300px !important;
  }

  .containerLeistungenMobileSmall {
    padding-right: 0px;
  }

  .mobileSmallOverlayLeistung {
    width: 300px !important;
    margin-top: -80px !important;
  }
}

@media only screen and (max-width: 670px) {
  .containerLeistungenMobileSmall {
    padding-right: 40px;
  }
}

@media only screen and (max-width: 630px) {
  .containerLeistungenMobileSmall {
    padding-right: 60px;
  }
}

@media only screen and (max-width: 714px) {
  #Leistung1,
  #Leistung2,
  #Leistung3 {
    width: 250px !important;
  }

  .containerLeistungenMobileSmall {
    padding-right: 0px;
  }

  .mobileSmallOverlayLeistung {
    width: 250px !important;
    margin-top: -65px !important;
  }

  .containerLeistungenMobileSmall .subtitle {
    font-size: 30px;
  }
}

@media only screen and (max-width: 576px) {
  #Leistung1,
  #Leistung2,
  #Leistung3 {
    width: 350px !important;
  }

  .containerLeistungenMobileSmall {
    padding-right: 0px;
  }

  .mobileSmallOverlayLeistung {
    width: 350px !important;
    margin-top: -90px !important;
  }

  .containerLeistungenMobileSmall .subtitle {
    font-size: 50px;
    text-align: center;
    margin-left: -20px;
  }

  .detail {
    padding-left: 50px;
    padding-right: 20px;
  }
}
</style>
