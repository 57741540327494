<template>
<section id="brandsContainer">	
		<div class="row  justify-content-center text-center brands" id="brands1">
			<div class="col-sm-1"></div>
            <div class="col">
				<img src="@/assets/img/logos/Element 2-8.png" id="brand" 
                    class="d-inline-block align-top" alt="">
				<div class="overlay"></div>
			</div>
			<div class="col">
				<img src="@/assets/img/logos/Element 3-8.png" id="brand"
                    class="d-inline-block align-top" alt="">
				<div class="overlay"></div>
			</div>
			<div class="col">
				<img src="@/assets/img/logos/Element 5-8.png" id="brand"
                    class="d-inline-block align-top" alt="">
				<div class="overlay"></div>
			</div>
			<div class="col">
				<img src="@/assets/img/logos/Element 6-8.png" id="brand"
                    class="d-inline-block align-top" alt="">
				<div class="overlay"></div>
			</div>
			<div class="col-sm-1"></div>
        </div>
		<div class="row  justify-content-center text-center brands" id="brands2">
			<div class="col-sm-1"></div>
            <div class="col">
				<img src="@/assets/img/logos/Element 2-8.png" id="brand" 
                    class="d-inline-block align-top" alt="">
				<div class="overlay"></div>
			</div>
			<div class="col">
				<img src="@/assets/img/logos/Element 3-8.png" id="brand"
                    class="d-inline-block align-top" alt="">
				<div class="overlay"></div>
			</div>
			
			<div class="col-sm-1"></div>
        </div>
				<div class="row  justify-content-center text-center brands" id="brands2">
			<div class="col-sm-1"></div>
            
			<div class="col">
				<img src="@/assets/img/logos/Element 5-8.png" id="brand"
                    class="d-inline-block align-top" alt="">
				<div class="overlay"></div>
			</div>
			<div class="col">
				<img src="@/assets/img/logos/Element 6-8.png" id="brand"
                    class="d-inline-block align-top" alt="">
				<div class="overlay"></div>
			</div>
			<div class="col-sm-1"></div>
        </div>

		<div class="row d-flex" id="quickBtnRow1" data-aos="fade-up" style="padding-top: 30px;">
			<div class="col-sm-1">
				
			</div>
			<div class="col d-flex justify-content-center text-center" id="btnContainer">
				<button  v-scroll-to="'#standortContainer'" id="quickBtn" class="btn">UNSERE ÖFFNUNGSZEITEN</button>
			</div>
			<div class="col d-flex justify-content-center text-center" id="btnContainer">
				<router-link :to="{ name: 'Membership' }">
				<button id="quickBtn" class="btn">UNSERE PREISE</button>
				</router-link>
			</div>
			<div class="col d-flex justify-content-center text-center" id="btnContainer">
				<button v-scroll-to="'#faqContainer'" class="btn" id="quickBtn">DU HAST FRAGEN?</button>
			</div>			
			<div class="col-sm-1">				
			</div>
		</div>

		<div class="row d-flex" id="quickBtnRow2" data-aos="fade-up" style="padding-top: 30px; padding-right: 7.5%; margin-bottom: 0px">
			<div class="col-sm-1"></div>
			<div class="col d-flex justify-content-center text-center" id="btnContainer">
				<button  v-scroll-to="'#standortContainer'" id="quickBtn" class="btn" style="height: 80px;">UNSERE<br>ÖFFNUNGSZEITEN</button>
			</div>
			<div class="col d-flex justify-content-center text-center" id="btnContainer">
				<router-link :to="{ name: 'Membership' }">
				<button id="quickBtn" class="btn" style="height: 80px;">UNSERE<br>PREISE</button>
				</router-link>
			</div>
			<div class="col d-flex justify-content-center text-center" id="btnContainer" style="">
				<button v-scroll-to="'#faqContainer'" class="btn" id="quickBtn" style="height: 80px;">DU HAST<br>FRAGEN?</button>
			</div>			
			
		</div>

		<div class="row d-flex justify-content-center text-center" id="quickBtnRow3" data-aos="fade-up" style="padding-top: 15px; ">
			
			<div class="col d-flex justify-content-center text-center" id="btnContainer">
				<button  v-scroll-to="'#standortContainer'" id="quickBtn" class="btn btn-block" style="height: 80px;">UNSERE ÖFFNUNGSZEITEN</button>
			</div>
			
		</div>

		<div class="row d-flex justify-content-center text-center" id="quickBtnRow3" data-aos="fade-up" style="padding-top: 15px; ">
			
			<div class="col d-flex justify-content-center text-center" id="btnContainer">
				<router-link :to="{ name: 'Membership' }" style="width: 100%">
				<button id="quickBtn" class="btn" style="height: 80px;">UNSERE PREISE</button>
				</router-link>
			</div>
					
			
		</div>

		<div class="row d-flex justify-content-center text-center" id="quickBtnRow3" data-aos="fade-up" style="padding-top: 15px; margin-bottom: -0px">
			
			<div class="col d-flex justify-content-center text-center" id="btnContainer">
				<button v-scroll-to="'#faqContainer'" class="btn" id="quickBtn" style="height: 80px;">DU HAST FRAGEN?</button>
			</div>			
			
		</div>

	
		
</section>
</template>

<script>

export default {
	 name: "Brands",
	 mounted(){
		//   AOS.init();
	 }
	
};

</script>

<style scoped>



</style>

<style>

#sectionImg{
  height: 10px;
  margin-top: 20px;
  background-color: black;
}

@media only screen and (max-width: 1158px) {
	 #quickBtnRow1{
	 display: none!important;
	 
 	}
	 #btnContainer{
		 max-width: 30%;
		 
	 }
	 #quickBtn{
		 height: 400px;
		 width: 200px!important
	 }
}

@media only screen and (min-width: 1159px) {
	 #quickBtnRow2{
	 display: none!important;
 	}
	
}

@media only screen and (max-width: 755px) {
	 #quickBtnRow2{
	 display: none!important;
	 
 	}
	 #btnContainer{
		 min-width: 100%;
		 
	 }
	 #quickBtn{
		 width: 90%!important;
	 }
	
}

@media only screen and (min-width: 756px) {
	 #quickBtnRow3{
	 display: none!important;
 	}
}

#quickBtnRow2{
	margin-top:50px;
	margin-bottom: -40px;
}

#quickBtnRow3{
	margin-top:25px;
	margin-bottom: -20px;
}



@media only screen and (max-width: 1090px) {
	#brand {
		width:80%!important;
	}
}

@media only screen and (max-width: 770px) {
	#brands1 {
		display: none;
	}
	#brands2 {
		height: 250px;
		padding: 30px;
	}
	#brandsContainer{
		margin-top: 70px;
	}
}

@media only screen and (min-width: 771px) {
	#brands2 {
		display: none;
	}
}

html {
  scroll-behavior: smooth;
}
.brands{
	margin-top: -70px;
	height: 140px;
	background-color: #212529;
	 align-items: center;
}
#brand{
	width: 200px;
	height: auto;
	opacity: 1;
}
.overlay{
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	height: 100%;
	width: 100%;
	opacity: 0.5;
	background-color: #212529;
}
#quickBtnRow1{
	margin-top: 50px;
}
#quickBtn:hover{	
    background-color: #212529;
	color: white;
	transition: 0.3s;
  
}

#quickBtn{
    outline-color: transparent;
    color: #212529;     
	background-color: #FBCE3B;
	font-family: Arial, Helvetica, sans-serif;
	font-weight: bolder;
	font-style: italic;    
	height: 50px;
	width: 300px;
	font-size: 15px;
	border: 0px;
}
</style>