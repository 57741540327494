<template>
	<section class="testimonialsContainer">
		<div class="row">
			<div class="col-sm d-flex justify-content-center text-center">
				<div id="title" style="margin-top:60px;">
					<h2>KUNDENFEEDBACK</h2>
					<h4>DAS SAGEN UNSERE MITGLIEDER</h4>
				</div>
			</div>
		</div>
		<div class="row justify-content-center" data-aos="fade-up" style="margin-bottom: 100px" id="testi_1">
			<div class="col justify-content-center" style="">
				<div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel" style="">
					<div class="carousel-inner justify-content-center text-center" role="listbox">
						<div class="carousel-item active text-center " style="margin-bottom: 0px;">
							<div class="row">
								<div class="col-sm-2">
								</div>
								<div class="col-sm-6" style="text-align: justify">
									<div class="row" style="position: absolute;">
										<div class="col-sm-1">
											<img src="@/assets/img/logos/Bild1.png"
												style="width: 40px; margin-left: -10px;">
										</div>
										<div class="col-sm-6">
											<a class="testimonial-person">
												Markus G.</a>
										</div>
										<div class="col-sm-2">
											<img src="@/assets/img/logos/Bild2.png" class="testimonial-star">
										</div>
									</div>
									<div class="row" style="padding-top: 60px;">
										<div class="col" style="">
											<a class="testimonial-text" style="">
												Super Fitnessstudio! Ich gehe zum Training dort hin.
												Freundliches Personal das einem mit Rat und Tat zu Seite steht.
												Saubere Räumlichkeiten mit allem was man braucht um wieder fit zu
												werden.
												Vorzufinden sind alle Altersklassen und auch Mitglieder die körperliche
												Einschränkungen haben.
												Auch der Umgang der Mitglieder untereinander ist sehr positiv zu
												bewerten. Habe mich nach längerer Suche für dieses
												Studio entschieden weil es zu mir einfach gepasst hat und eine angenehme
												Atmosphäre bietet und auch auf meinem Weg nach
												Hause liegt so das ausreden nicht zu trainieren nicht erst
												aufkommen.</a>
											
										</div>
									</div>
								</div>
								<div class="col-sm-2">
								</div>
							</div>
						</div>
						<div class="carousel-item text-center" style="">
							<div class="row">
								<div class="col-sm-2">
								</div>
								<div class="col-sm-6" style="text-align: justify">
									<div class="row" style="position: absolute;">
										<div class="col-sm-1">
											<img src="@/assets/img/logos/Bild1.png"
												style="width: 40px; margin-left: -10px;">
										</div>
										<div class="col-sm-6">
											<a class="testimonial-person">
												Gonca G.</a>
										</div>
										<div class="col-sm-2">
											<img src="@/assets/img/logos/Bild2.png" class="testimonial-star">
										</div>
									</div>
									<div class="row" style="padding-top: 60px;">
										<div class="col">
											<a class="testimonial-text">
												Bestes Studio, nettes Personal und immer offen für alle Fragen, sehr
												sauber und eine Menge Angebote wie Sauna, Solarium, Kaffeeecke... Finde
												es hier einfach klasse 👍</a>
											
										</div>
									</div>
								</div>
								<div class="col-sm-2">
								</div>
							</div>
						</div>
						<div class="carousel-item text-center" style="">
							<div class="row">
								<div class="col-sm-2">
								</div>
								<div class="col-sm-6" style="text-align: justify">
									<div class="row" style="position: absolute;">
										<div class="col-sm-1">
											<img src="@/assets/img/logos/Bild1.png"
												style="width: 40px; margin-left: -10px;">
										</div>
										<div class="col-sm-6">
											<a class="testimonial-person">
												Eberhard T.</a>
										</div>
										<div class="col-sm-2">
											<img src="@/assets/img/logos/Bild2.png" class="testimonial-star">
										</div>
									</div>
									<div class="row" style="padding-top: 60px;">
										<div class="col">
											<a class="testimonial-text">
												Sehr sauberes Fitnessstudio!
												Exzellente Ausstattung!
												Sehr freundliches Personal!</a>
									
										</div>
									</div>
								</div>
								<div class="col-sm-2">
								</div>
							</div>
						</div>
						<div class="carousel-item text-center" style="">
							<div class="row">
								<div class="col-sm-2">
								</div>
								<div class="col-sm-6" style="text-align: justify">
									<div class="row" style="position: absolute;">
										<div class="col-sm-1">
											<img src="@/assets/img/logos/Bild1.png"
												style="width: 40px; margin-left: -10px;">
										</div>
										<div class="col-sm-6">
											<a class="testimonial-person">
												Andrea W.</a>
										</div>
										<div class="col-sm-2">
											<img src="@/assets/img/logos/Bild2.png" class="testimonial-star">
										</div>
									</div>
									<div class="row" style="padding-top: 60px;">
										<div class="col">
											<a class="testimonial-text">
												Ihr seit spitze ich hatte im März Kleidergöße 54 /56 heute trag ich 46 /48! 21 kg weniger!</a>
									
										</div>
									</div>
								</div>
								<div class="col-sm-2">
								</div>
							</div>
						</div>
						<div class="carousel-item text-center" style="">
							<div class="row">
								<div class="col-sm-2">
								</div>
								<div class="col-sm-6" style="text-align: justify">
									<div class="row" style="position: absolute;">
										<div class="col-sm-1">
											<img src="@/assets/img/logos/Bild1.png"
												style="width: 40px; margin-left: -10px;">
										</div>
										<div class="col-sm-6">
											<a class="testimonial-person">
												Perry R.</a>
										</div>
										<div class="col-sm-2">
											<img src="@/assets/img/logos/Bild2.png" class="testimonial-star">
										</div>
									</div>
									<div class="row" style="padding-top: 60px;">
										<div class="col">
											<a class="testimonial-text">
												Das Gym ist Herrlich fast Familiäre Flair, und vor allem gute alte Gym 80 Geräte. Kein schicki Micki Gym fast alles da was man braucht und auch Sanitär Bereich immer sehr sauber. Und Trainer auch nur gute Erfahrungen gemacht bisher. Und der Chef ist auch sehr Nett und grade raus!!!</a>
										
										</div>
									</div>
								</div>
								<div class="col-sm-2">
								</div>
							</div>
						</div>
						<div class="carousel-item text-center" style="margin-bottom: 0px;">
							<div class="row">
								<div class="col-sm-2">
								</div>
								<div class="col-sm-6" style="text-align: justify">
									<div class="row" style="position: absolute;">
										<div class="col-sm-1">
											<img src="@/assets/img/logos/Bild1.png"
												style="width: 40px; margin-left: -10px;">
										</div>
										<div class="col-sm-6">
											<a class="testimonial-person">
												Sandra M.</a>
										</div>
										<div class="col-sm-2">
											<img src="@/assets/img/logos/Bild2.png" class="testimonial-star">
										</div>
									</div>
									<div class="row" style="padding-top: 60px;">
										<div class="col">
											<a class="testimonial-text" style="">
												Bin seit ca. 7 Monaten in diesem Studio und habe bereits 12 kg abgenommen:) Für mich macht es hier die Abwechslung. In meinem alten Studio habe ich wenig Veränderung gehabt. Hier habe ich die Auswahl zwischen einem Zirkel in einem Damenbereich (was ich sehr angenhem finde), Power Plate aber auch Entspannungsgeräten welche mein Hautbild verbessern. Habe verschiedene Trainer welche regelmäßig mit mir Körperanalysen durchführen und meinen Plan anpassen dass ich zu meiner Hochzeit mein Zielgewicht erreiche. Kurse will ich demnächst auch ausprobieren. Sieht von außen nach sehr viel Spaß aus! Kann es auf jeden Fall weiter empfehlen!</a>
										
										</div>
									</div>
								</div>
								<div class="col-sm-2">
								</div>
							</div>
						</div>
						<div class="carousel-item text-center" style="">
							<div class="row">
								<div class="col-sm-2">
								</div>
								<div class="col-sm-6" style="text-align: justify">
									<div class="row" style="position: absolute;">
										<div class="col-sm-1">
											<img src="@/assets/img/logos/Bild1.png"
												style="width: 40px; margin-left: -10px;">
										</div>
										<div class="col-sm-6">
											<a class="testimonial-person">
												Bianca E.</a>
										</div>
										<div class="col-sm-2">
											<img src="@/assets/img/logos/Bild2.png" class="testimonial-star">
										</div>
									</div>
									<div class="row" style="padding-top: 60px;">
										<div class="col">
											<a class="testimonial-text">
												Ich bin seit Nov 15 Mitglied und fühle mich sehr wohl. Das Personal ist sehr freundlich und absolut kompetent. Auch die Atmosphäre im Studio stimmt und es ist für jeden was dabei.</a>
											
										</div>
									</div>
								</div>
								<div class="col-sm-2">
								</div>
							</div>
						</div>
					</div>
					<a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
						<i class="fa fa-arrow-circle-left fa-3x" aria-hidden="true"
							style="position: absolute; top:100px; color: #FBCE3B"></i>
					</a>
					<a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
						<i class="fa fa-arrow-circle-right fa-3x" aria-hidden="true"
							style="position: absolute; top:100px; color: #FBCE3B"></i>
					</a>
				</div>
			</div>

		</div>


			<div class="row justify-content-center" data-aos="fade-up" style="margin-bottom: 40px" id="testi_2">
			<div class="col justify-content-center" style="">
				<div id="carouselExampleIndicators2_testi" class="carousel slide" data-ride="carousel" style="">
					
					<div class="carousel-inner justify-content-center text-center" role="listbox">
						<div class="carousel-item active text-center " style="margin-bottom: 0px;">
							<div class="row" style="padding-bottom: 0px;">
								<div class="col-sm-2">
								</div>
								<div class="col-sm-6" style="text-align: justify">
									<div class="row" style="position: absolute;">
										<div class="col-sm-1">
											<img src="@/assets/img/logos/Bild1.png"
												style="width: 40px; margin-left: -10px;">
										</div>
										<div class="col-sm-6">
											<a class="testimonial-person">
												Markus G.</a>
										</div>
										<div class="col-sm-2">
											<img src="@/assets/img/logos/Bild2.png" class="testimonial-star">
										</div>
									</div>
									<div class="row" style="padding-top: 60px;">
										<div class="col" style="">
											<a class="testimonial-text" style="width: 300px;">
												Top Fitnessstudio! Ich gehe zum Training dort hin.
												Freundliches Personal das einem mit Rat und Tat zu Seite steht.
												Saubere Räumlichkeiten mit allem was man braucht um wieder fit zu
												werden.
												Vorzufinden sind alle Altersklassen und auch Mitglieder die körperliche
												Einschränkungen haben.
												Auch der Umgang der Mitglieder untereinander ist sehr positiv zu
												bewerten. Habe mich nach längerer Suche für dieses
												Studio entschieden weil es zu mir einfach gepasst hat und eine angenehme
												Atmosphäre bietet und auch auf meinem Weg nach
												Hause liegt so das ausreden nicht zu trainieren nicht erst
												aufkommen.</a>
											
										</div>
									</div>
								</div>
								<div class="col-sm-2">
								</div>
							</div>
						</div>
						<div class="carousel-item text-center" style="">
							<div class="row">
								<div class="col-sm-2">
								</div>
								<div class="col-sm-6" style="text-align: justify">
									<div class="row" style="position: absolute;">
										<div class="col-sm-1">
											<img src="@/assets/img/logos/Bild1.png"
												style="width: 40px; margin-left: -10px;">
										</div>
										<div class="col-sm-6">
											<a class="testimonial-person">
												Gonca G.</a>
										</div>
										<div class="col-sm-2">
											<img src="@/assets/img/logos/Bild2.png" class="testimonial-star">
										</div>
									</div>
									<div class="row" style="padding-top: 60px;">
										<div class="col">
											<a class="testimonial-text">
												Bestes Studio, nettes Personal und immer offen für alle Fragen, sehr
												sauber und eine Menge Angebote wie Sauna, Solarium, Kaffeeecke... Finde
												es hier einfach klasse 👍</a>
											
										</div>
									</div>
								</div>
								<div class="col-sm-2">
								</div>
							</div>
						</div>
						<div class="carousel-item text-center" style="">
							<div class="row">
								<div class="col-sm-2">
								</div>
								<div class="col-sm-6" style="text-align: justify">
									<div class="row" style="position: absolute;">
										<div class="col-sm-1">
											<img src="@/assets/img/logos/Bild1.png"
												style="width: 40px; margin-left: -10px;">
										</div>
										<div class="col-sm-6">
											<a class="testimonial-person">
												Eberhard T.</a>
										</div>
										<div class="col-sm-2">
											<img src="@/assets/img/logos/Bild2.png" class="testimonial-star">
										</div>
									</div>
									<div class="row" style="padding-top: 60px;">
										<div class="col">
											<a class="testimonial-text">
												Sehr sauberes Fitnessstudio!
												Exzellente Ausstattung!
												Sehr freundliches Personal!</a>
									
										</div>
									</div>
								</div>
								<div class="col-sm-2">
								</div>
							</div>
						</div>
						<div class="carousel-item text-center" style="">
							<div class="row">
								<div class="col-sm-2">
								</div>
								<div class="col-sm-6" style="text-align: justify">
									<div class="row" style="position: absolute;">
										<div class="col-sm-1">
											<img src="@/assets/img/logos/Bild1.png"
												style="width: 40px; margin-left: -10px;">
										</div>
										<div class="col-sm-6">
											<a class="testimonial-person">
												Andrea W.</a>
										</div>
										<div class="col-sm-2">
											<img src="@/assets/img/logos/Bild2.png" class="testimonial-star">
										</div>
									</div>
									<div class="row" style="padding-top: 60px;">
										<div class="col">
											<a class="testimonial-text">
												Ihr seit spitze ich hatte im März Kleidergöße 54 /56 heute trag ich 46 /48! 21 kg weniger!</a>
									
										</div>
									</div>
								</div>
								<div class="col-sm-2">
								</div>
							</div>
						</div>
						<div class="carousel-item text-center" style="">
							<div class="row">
								<div class="col-sm-2">
								</div>
								<div class="col-sm-6" style="text-align: justify">
									<div class="row" style="position: absolute;">
										<div class="col-sm-1">
											<img src="@/assets/img/logos/Bild1.png"
												style="width: 40px; margin-left: -10px;">
										</div>
										<div class="col-sm-6">
											<a class="testimonial-person">
												Perry R.</a>
										</div>
										<div class="col-sm-2">
											<img src="@/assets/img/logos/Bild2.png" class="testimonial-star">
										</div>
									</div>
									<div class="row" style="padding-top: 60px;">
										<div class="col">
											<a class="testimonial-text">
												Das Gym ist Herrlich fast Familiäre Flair, und vor allem gute alte Gym 80 Geräte. Kein schicki Micki Gym fast alles da was man braucht und auch Sanitär Bereich immer sehr sauber. Und Trainer auch nur gute Erfahrungen gemacht bisher. Und der Chef ist auch sehr Nett und grade raus!!!</a>
										
										</div>
									</div>
								</div>
								<div class="col-sm-2">
								</div>
							</div>
						</div>
						<div class="carousel-item text-center" style="margin-bottom: 0px;">
							<div class="row">
								<div class="col-sm-2">
								</div>
								<div class="col-sm-6" style="text-align: justify">
									<div class="row" style="position: absolute;">
										<div class="col-sm-1">
											<img src="@/assets/img/logos/Bild1.png"
												style="width: 40px; margin-left: -10px;">
										</div>
										<div class="col-sm-6">
											<a class="testimonial-person">
												Sandra M.</a>
										</div>
										<div class="col-sm-2">
											<img src="@/assets/img/logos/Bild2.png" class="testimonial-star">
										</div>
									</div>
									<div class="row" style="padding-top: 60px;">
										<div class="col">
											<a class="testimonial-text" style="">
												Bin seit ca. 7 Monaten in diesem Studio und habe bereits 12 kg abgenommen:) Für mich macht es hier die Abwechslung. In meinem alten Studio habe ich wenig Veränderung gehabt. Hier habe ich die Auswahl zwischen einem Zirkel in einem Damenbereich (was ich sehr angenhem finde), Power Plate aber auch Entspannungsgeräten welche mein Hautbild verbessern. Habe verschiedene Trainer welche regelmäßig mit mir Körperanalysen durchführen und meinen Plan anpassen dass ich zu meiner Hochzeit mein Zielgewicht erreiche. Kurse will ich demnächst auch ausprobieren. Sieht von außen nach sehr viel Spaß aus! Kann es auf jeden Fall weiter empfehlen!</a>
										
										</div>
									</div>
								</div>
								<div class="col-sm-2">
								</div>
							</div>
						</div>
						<div class="carousel-item text-center" >
							<div class="row" >
								<div class="col-sm-2">
								</div>
								<div class="col-sm-6" style="text-align: justify">
									<div class="row" style="position: absolute;">
										<div class="col-sm-1">
											<img src="@/assets/img/logos/Bild1.png"
												style="width: 40px; margin-left: -10px;">
										</div>
										<div class="col-sm-6">
											<a class="testimonial-person">
												Bianca E.</a>
										</div>
										<div class="col-sm-2">
											<img src="@/assets/img/logos/Bild2.png" class="testimonial-star">
										</div>
									</div>
									<div class="row" style="padding-top: 60px;">
										<div class="col">
											<a class="testimonial-text">
												Ich bin seit Nov 15 Mitglied und fühle mich sehr wohl. Das Personal ist sehr freundlich und absolut kompetent. Auch die Atmosphäre im Studio stimmt und es ist für jeden was dabei.</a>
											
										</div>
									</div>
								</div>
								<div class="col-sm-2">
								</div>
							</div>
						</div>
					</div>
					<ol class="carousel-indicators" id="carouselIndi">
                <li data-target="#carouselExampleIndicators2_testi" data-slide-to="0" class="active"></li>
                <li data-target="#carouselExampleIndicators2_testi" data-slide-to="1"></li>
                <li data-target="#carouselExampleIndicators2_testi" data-slide-to="3"></li>
				<li data-target="#carouselExampleIndicators2_testi" data-slide-to="4"></li>
                <li data-target="#carouselExampleIndicators2_testi" data-slide-to="5"></li>
				<li data-target="#carouselExampleIndicators2_testi" data-slide-to="6"></li>
				<li data-target="#carouselExampleIndicators2_testi" data-slide-to="7"></li>
              </ol>
					<a class="carousel-control-prev control2" href="#carouselExampleIndicators2" role="button" data-slide="prev">
						<i class="fa fa-arrow-circle-left fa-3x" aria-hidden="true"
							style="position: absolute; top:100px; color: #FBCE3B"></i>
					</a>
					<a class="carousel-control-next control2" href="#carouselExampleIndicators2" role="button" data-slide="next">
						<i class="fa fa-arrow-circle-right fa-3x" aria-hidden="true"
							style="position: absolute; top:100px; color: #FBCE3B"></i>
					</a>
				</div>
			</div>

		</div>
	</section>
</template>

<script>
	export default {

	}
</script>

<style scoped>

	@media only screen and (max-width: 1500px) {
		#recipeCarousel2 {
			display: none;
		}

.carousel-inner .carousel-item.active,
	.carousel-inner .carousel-item-next,
	.carousel-inner .carousel-item-prev {
		display: flex;
	}




	.carousel-inner .carousel-item-right,
	.carousel-inner .carousel-item-left {
		transform: translateX(0);
	}
		
		.carousel-inner .carousel-item-right.active,
		.carousel-inner .carousel-item-next {
			transform: translateX(120%);
		}

		.carousel-inner .carousel-item-left.active,
		.carousel-inner .carousel-item-prev {
			transform: translateX(-120%);
		}
	}

 @media only screen and (max-width: 1500px) {
	 #carouselExampleIndicators{
	margin-left: 20%!important;
	margin-right: 20%!important;
}
 }

  @media only screen and (max-width: 1300px) {
	 #carouselExampleIndicators{
	margin-left: 15%!important;
	margin-right: 15%!important;
}
 }

  @media only screen and (max-width: 1100px) {
	 #carouselExampleIndicators{
	margin-left: 10%!important;
	margin-right: 10%!important;
}
 }

  @media only screen and (max-width: 900px) {
	 #carouselExampleIndicators{
	margin-left: 5%!important;
	margin-right: 5%!important;
}
 }

  @media only screen and (max-width: 700px) {
	 #carouselExampleIndicators{
	margin-left: 0%!important;
	margin-right: 0%!important;
}
 }

 @media only screen and (max-width: 575px) {
	 #testi_1{
		 display: none;
	 }
	  .testimonial-star {
		width: 100px;
		margin-top: -72.5px!important;
		margin-left: 140px!important;
	}
	.carousel-item{
		width: 80%;
		margin-left: 40px
	}
	.testimonial-person{
		margin-top: -32.5px!important;
		position: absolute!important;
		margin-left: 110px!important;
	}
 }

 @media only screen and (min-width: 576px) {
	 #testi_2{
		 display: none;
	 }
	
 }

	 #carouselIndi{
		 position: relative;
		 bottom: -10px;
	 }
	
 

 

#carouselExampleIndicators{
	margin-left: 25%;
	margin-right: 25%;
}

.control2{
	display: none;
}
#carouselExampleIndicators2_testi{
	margin-left: 5%;
	margin-right: 5%;
}
	.testimonial-person {
		position: relative;
		top: 20%;
		left: -15%;
		font-weight: bolder;
	}

	.testimonial-star {
		width: 100px;
		margin-top: 7.5px;
		margin-left: -60px;
	}

	.testimonial-text{
		margin-top: 100px;
		padding-bottom: 500px;
		font-weight:400;
	}

	.carousel-item {
		/* height: 300px; */
		padding-top: 50px;
		
	}

	.carousel-control-prev-icon {
		background: black;
	}

	.carousel-item.active {
		display: block;
		
	}
</style>