<template>
  <div id="app">
    <router-view name="header"></router-view>
    <main>
      <fade-transition origin="center" mode="out-in" :duration="250">
        <router-view />
      </fade-transition>
    </main>
    <router-view name="footer"></router-view>
  </div>
</template>
<script>
import { FadeTransition } from "vue2-transitions";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";

export default {
  components: {
    FadeTransition,
    BootstrapVue,
  },
};
</script>
<style>
.btn {
  border: 0px;
}

.btn-primary {
  background: transparent linear-gradient(266deg, #fcce3a 0%, #ffdb67 100%) 0%
    0% no-repeat padding-box;
  box-shadow: 0px 2px 5px #d0d0d0;
  border-radius: 10px !important;
  -webkit-transition: all 0.5s ease-in-out !important;
}

.btn-secondary {
  background: transparent linear-gradient(340deg, #b1b1b1 0%, #e5e5e5 100%) 0%
    0% no-repeat padding-box;
  box-shadow: 0px 2px 5px #d0d0d0;
  border-radius: 10px !important;
  -webkit-transition: all 0.5s ease-in-out;
}

.btn-light {
  background: transparent linear-gradient(340deg, #e3e3e3 0%, #ffffff 100%) 0%
    0% no-repeat padding-box;
  box-shadow: 0px 2px 5px #d0d0d0;
  color: black !important;
  border-radius: 10px !important;
  -webkit-transition: all 0.5s ease-in-out;
}

main {
  overflow: clip !important;
}

.title {
  text-align: center;
  display: block !important;
  padding-bottom: 20px;
  padding-top: 50px;
}

.title h2 {
  color: #212529;
  font-family: noto-sans-display-extraconde, sans-serif;
  font-size: 70px;
  font-style: italic;
}

.title h4 {
  color: #fbce3b;
  font-family: noto-sans-display-extraconde, sans-serif;
  font-size: 35px;
  font-style: italic;
  margin-top: -10px;
}
</style>
