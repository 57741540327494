export const media = [
	{
	  thumb: require('@/assets/img/Flexx/Flexx2.jpg'),
		src: require('@/assets/img/Flexx/Flexx2.jpg'),
	},
	{
		thumb: require('@/assets/img/Flexx/Flexx3.jpg'),
		  src: require('@/assets/img/Flexx/Flexx3.jpg'),
	  },
	  {
		thumb: require('@/assets/img/Flexx/Flexx4.jpg'),
		  src: require('@/assets/img/Flexx/Flexx4.jpg'),
	  },
	  {
		thumb: require('@/assets/img/Flexx/Flexx5.jpg'),
		  src: require('@/assets/img/Flexx/Flexx5.jpg'),
	  },
	  {
		thumb: require('@/assets/img/Flexx/Flexx1.jpg'),
		  src: require('@/assets/img/Flexx/Flexx1.jpg'),
	  },
	
  ]