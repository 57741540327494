<template>
  <div class="containerMgfin">
    <section
      class="section-profile-cover section-shaped my-0"
      id="headerMGfin"
    ></section>
    <section class="section section-skew" style="margin-top: -150px;">
      <div class="container">
        <card
          shadow
          class="card-profile mt--300"
          no-body
          style="background-color: white;"
        >
          <div class="px-4">
            <div class="row ">
              <div class="col">
                <div class="mt-3">
                  <h2 id="agbtitle" style="color:#fbce3b">
                    Vielen Dank für deine Anfrage!
                  </h2>
                </div>
              </div>
              <div class="col">
                <!-- <router-link :to="{ name: 'kontakt' }">
                                    <base-button id="kontaktButton" type="default" size="sm" class="float-right mt-4">
                                        Kontakt</base-button>
                                </router-link> -->
              </div>
            </div>
            <div class="mt-3 py-3 border-top ">
              <div class="row " data-aos="fade-right">
                <div class="col-lg-12">
                  <p>
                    <strong
                      >Wir melden uns so schnell wie möglich bei dir!<br />In
                      dringenden Anliegen kannst du uns telefonisch unter
                      <strong>0 70 82 / 94 11 44</strong> zu unseren
                      Öffnungszeiten erreichen.</strong
                    >
                  </p>

                  <h4 class="mt-2" style="color:#fbce3b">Öffnungszeiten</h4>
                  <div class="row">
                    <div class="col-lg-2">Mo-Fr</div>
                    <div class="col-lg-2">06-22 Uhr</div>
                  </div>
                  <div class="row">
                    <div class="col-lg-2">Sa,So</div>
                    <div class="col-lg-2">09-17 Uhr</div>
                  </div>
                  <div class="row">
                    <div class="col-lg-2">Feiertag</div>
                    <div class="col-lg-2">9-17 Uhr</div>
                  </div>
                  <div class="row">
                    <div class="col-lg-3">365 Tage geöffnet</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </card>
      </div>
    </section>
  </div>
</template>

<script>
import axios from "axios";
export default {
  mounted() {
    axios
      .post("https://forum-neuenbuerg.de/mailerKontakt.php", {
        first_name: this.$route.params.first_name,
        last_name: this.$route.params.last_name,
        phone: this.$route.params.phone,
        email: this.$route.params.email,
        message: this.$route.params.msg,
      })
      .then(
        (response) => {},
        (error) => {}
      );
  },
};
</script>

<style scoped>
@media only screen and (max-width: 767px) {
  #kontaktButton {
    width: 100% !important;
    margin-top: 20px !important;
  }
}

@media only screen and (max-width: 457px) {
  #agbtitle {
    font-size: 25px;
  }
}

#listVerhalten {
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.7;
}

#headerMGfin {
  background-image: url("../assets/img/logos/crossfit.jpg");
}

#kontaktButton {
  background-color: #fbce3b;
  border-color: transparent;
  width: 200px;
  font-size: 15px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bolder;
  font-style: italic;
}

#kontaktButton:hover {
  background-color: #212529;
  color: white;
  transition: 0.3s;
}
</style>
