export const media = [
  {
    thumb: require("@/assets/img/Kurse/Cycling/Cycling1.jpg"),
    src: require("@/assets/img/Kurse/Cycling/Cycling1.jpg"),
  },
  {
    thumb: require("@/assets/img/Kurse/Cycling/Cycling2.jpg"),
    src: require("@/assets/img/Kurse/Cycling/Cycling2.jpg"),
  },
  {
    thumb: require("@/assets/img/Kurse/Cycling/Cycling3.jpg"),
    src: require("@/assets/img/Kurse/Cycling/Cycling3.jpg"),
  },
  {
    thumb: require("@/assets/img/Kurse/Cycling/Cycling4.jpg"),
    src: require("@/assets/img/Kurse/Cycling/Cycling4.jpg"),
  },
  {
    thumb: require("@/assets/img/Kurse/Cycling/Cycling5.jpg"),
    src: require("@/assets/img/Kurse/Cycling/Cycling5.jpg"),
  },
  {
    thumb: require("@/assets/img/Kurse/Cycling/Cycling7.jpg"),
    src: require("@/assets/img/Kurse/Cycling/Cycling7.jpg"),
  },
  {
    thumb: require("@/assets/img/Kurse/Cycling/Cycling8.jpg"),
    src: require("@/assets/img/Kurse/Cycling/Cycling8.jpg"),
  },
];
