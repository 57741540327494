import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);

const initialState = {
  mail: {
    status: "idle",
    response: "nodata",
  },
};

export default new Vuex.Store({ state: initialState });
