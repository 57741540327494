<template>
  <div class="profile-page KursContainer">
    <section
      class="section-profile-cover section-shaped my-0"
      id="header"
    ></section>
    <section class="section section-skew">
      <div class="container">
        <card
          shadow
          class="card-profile mt--300"
          no-body
          style="background-color: white;"
        >
          <div class="px-4">
            <div class="row ">
              <div class="col">
                <div class="mt-3">
                  <h2 id="agbtitle" style="color:#fbce3b">KURSE</h2>
                </div>
              </div>
            </div>
            <div class="mt-3 py-3 border-top ">
              <div
                class="row "
                style="margin-bottom: -20px;"
                data-aos="fade-right"
              >
                <div class="col-lg-12">
                  <img
                    id="Kursplan"
                    :src="'https://dashboard.forum-neuenbuerg.de/' + kursplan"
                    alt="Kursplan"
                    style="cursor: pointer; width: 100%; margin-bottom: 20px;"
                    data-aos="fade-down"
                  />
                  <p>Unsere Kursauswahl besteht aus folgenden Angeboten:</p>
                </div>
              </div>
            </div>
            <div class="btnRow">
              <button
                id="kursAuswahlBtn"
                class="btn"
                v-scroll-to="'#IndoorCycling'"
              >
                Indoor Cycling
              </button>
              <button
                id="kursAuswahlBtn"
                class="btn"
                v-scroll-to="'#TotalBodyWorkout'"
              >
                Total Body<br />Workout
              </button>
              <button
                id="kursAuswahlBtn"
                class="btn"
                v-scroll-to="'#Evergreen'"
              >
                Evergreen 50+
              </button>
              <button
                id="kursAuswahlBtn"
                class="btn"
                v-scroll-to="'#Stretching'"
              >
                Stretching
              </button>
              <button id="kursAuswahlBtn" class="btn" v-scroll-to="'#BBP'">
                BBP
              </button>
              <button id="kursAuswahlBtn" class="btn" v-scroll-to="'#PilFas'">
                Pilates &<br />Faszienworkout
              </button>
              <button id="kursAuswahlBtn" class="btn" v-scroll-to="'#Vinyasa'">
                Vinyasa Yoga
              </button>
              <button id="kursAuswahlBtn" class="btn" v-scroll-to="'#Line'">
                Line Dance
              </button>
              <button id="kursAuswahlBtn" class="btn" v-scroll-to="'#boxen'">
                Box Dich Fit!
              </button>
              <button id="kursAuswahlBtn" class="btn" v-scroll-to="'#Step'">
                Step-Classic
              </button>
              <button
                id="kursAuswahlBtn"
                class="btn"
                v-scroll-to="'#Bodytoning'"
              >
                Bodytoning
              </button>
              <button
                id="kursAuswahlBtn"
                class="btn"
                v-scroll-to="'#RückenFit'"
              >
                RückenFit
              </button>
              <button id="kursAuswahlBtn" class="btn" v-scroll-to="'#Zumba'">
                Zumba
              </button>
              <button id="kursAuswahlBtn" class="btn" v-scroll-to="'#Stretch'">
                Strong & Relax
              </button>
            </div>

            <hr style="broder: 2px solid black" id="IndoorCycling" />
            <div class="row">
              <h4
                id="kursHeading"
                style="margin-top: -15px; margin-left: 10px;"
              >
                Indoor Cycling
              </h4>
              <div class="row " id="kursText" data-aos="fade-right">
                <p>
                  Indoor Cycling ist ein hocheffektives Cardiotraining. Es zählt
                  zu den Ausdauersportarten und wird in der Gruppe ausgeübt. Die
                  leicht erlernbaren Bewegungsabläufe werden auf stationären
                  Fahrrädern, dem Indoor Bike und im Rhythmus der Musik
                  trainiert. Auf den Bikes fährt man zu aktueller Musik in
                  unterschiedlicher Geschwindigkeit und Kraftanstrengung.
                  Hierbei wird das Herzkreislaufsystem trainiert, der Blutdruck
                  gesenkt und die Kraftausdauer, vor allem in den Beinen,
                  gesteigert. Ebenfalls wird der Stoffwechsel und die
                  Fettverbrennung angekurbelt.
                </p>
              </div>
              <Cycling></Cycling>
            </div>
            <hr style="broder: 2px solid black" id="TotalBodyWorkout" />
            <div class="row">
              <h4
                id="kursHeading"
                style="margin-top: -15px; margin-left: 10px;"
              >
                Total Body Workout
              </h4>
              <div class="row " id="kursText" data-aos="fade-right">
                <p>
                  In diesem Kurs erwartet dich ein kreatives, vielfältiges, aber
                  auch anstrengendes und schweißtreibendes Workout für den
                  gesamten Körper. Dabei wird vermehrt mit Bodyweight-Übungen
                  gearbeitet, bei denen es keine weiteren Hilfsmittel benötigt
                  als den eigenen Körper. Aber auch Elemente eines klassischen
                  Bauch-Beine-Po Workouts, Intervallmethoden – die z.B. von
                  „HIIT“ oder „Tabata“ inspiriert sind –, ebenso wie ein
                  Mobility-Training, oder Kräftigungsübungen mit diversen
                  Kleingeräten wie z.B. Kurzhanteln, Resistance-Bändern etc.,
                  finden in diesem Fitnesskurs ihren Platz. Der Fantasie bzw.
                  den Trainingsmethoden sind in diesem Kurs also nahezu keine
                  Grenzen gesetzt. Seien Sie also gespannt auf ein
                  abwechslungsreiches und forderndes Total-Body-Workout. Der Mix
                  aus Cardio- und Krafttraining macht nicht nur Spaß, sondern
                  kräftigt / strafft Ihren gesamten Körper und bringt auch die
                  Figur in Topform.
                </p>
              </div>
            </div>

            <hr style="broder: 2px solid black" id="Evergreen" />
            <div class="row">
              <h4
                id="kursHeading"
                style="margin-top: -15px; margin-left: 10px;"
              >
                Evergreen 50+
              </h4>
              <div class="row " id="kursText" data-aos="fade-right">
                <p>
                  Hierbei handelt es sich um eine spezielle, schonende
                  Gymnastik, leicht zu erlernende Dehnungs- und
                  Beweglichkeitsübungen mit viel Spaß für Sie und Ihn ab 50.
                </p>
              </div>
              <Evergreen></Evergreen>
            </div>
            <hr style="broder: 2px solid black" id="Stretching" />
            <div class="row">
              <h4
                id="kursHeading"
                style="margin-top: -15px; margin-left: 10px;"
              >
                Stretching
              </h4>
              <div class="row " id="kursText" data-aos="fade-right">
                <p>
                  Leicht zu erlernende Dehnungs- und Beweglichkeitsübungen,
                  welche die Weichheit und Lockerung der gesamten
                  Körpermuskulatur sowie die Beweglichkeit fördern.
                </p>
              </div>
              <Stretch></Stretch>
            </div>
            <hr style="broder: 2px solid black" id="BBP" />
            <div class="row">
              <h4
                id="kursHeading"
                style="margin-top: -15px; margin-left: 10px;"
              >
                BBP
              </h4>
              <div
                class="row "
                style="min-width: 100%;"
                id="kursText"
                data-aos="fade-right"
              >
                <p>
                  Ein effektives und förderndes Training mit Schwerpunkt auf
                  Bauch, Beine & Po – für alle Trainings- und Altersklassen
                  geeignet.
                </p>
              </div>
              <BBP></BBP>
            </div>
            <hr style="broder: 2px solid black" id="PilFas" />
            <div class="row">
              <h4
                id="kursHeading"
                style="margin-top: -15px; margin-left: 10px;"
              >
                Pilates & Faszienworkout
              </h4>
              <div class="row " id="kursText" data-aos="fade-right">
                <p>
                  Pilates ist ein ganzheitliches Ganzkörpertraining, bei dem
                  Atmung und Bewegung in Einklang gebracht werden. Durch die
                  Anspannung der tiefen Bauch-, Rücken- und
                  Beckenbodenmuskulatur wird der Rumpf stabilisiert und damit
                  das Powerhouse aktiviert. Zudem wird durch das Trainieren die
                  Beweglichkeit gefördert. Beim Pilates steht immer die
                  kontrollierte und konzentrierte Bewegungsausführung im
                  Vordergrund. Mit Dehnübungen werden die Faszien trainiert und
                  dank Pilates die Muskeln gestärkt. Bei allen Übungen ist
                  Qualität wichtiger als Quantität!
                </p>
              </div>
              <!-- <Yoga></Yoga> -->
            </div>
            <hr style="broder: 2px solid black" id="Vinyasa" />
            <div class="row">
              <h4
                id="kursHeading"
                style="margin-top: -15px; margin-left: 10px;"
              >
                Vinyasa Yoga
              </h4>
              <div class="row " id="kursText" data-aos="fade-right">
                <p>
                  Vinyasa Flow Yoga ist die traditionelle Urform des dynamischen
                  Yoga. Geübt wird eine gleichbleibende Serie von
                  Yogastellungen. Jede Asana (Yogastellung) ist mit der nächsten
                  über eine spezifische Atem- (Ujjayi-Pranayama) und
                  Bewegungsabfolge (Vinyasa) verbunden. Das Ergebnis ist eine
                  kraftvoll fließende, harmonische Sequenz. Ashtanga Vinyasa
                  Yoga fördert sowohl Flexibilität, Kraft, Stabilität und
                  Fitness, als auch Ausgeglichenheit und
                  Konzentrationsfähigkeit. Es kann als Meditation in Bewegung
                  bezeichnet werden. Yoga stärkt Körper und Seele!
                </p>
              </div>
              <Vinyasa></Vinyasa>
            </div>

            <hr style="broder: 2px solid black" id="Line" />
            <div class="row">
              <h4
                id="kursHeading"
                style="margin-top: -15px; margin-left: 10px;"
              >
                Line Dance
              </h4>
              <div class="row " id="kursText" data-aos="fade-right">
                <p>
                  Line Dance ist eine choreografierte Tanzform, bei der die
                  Tänzer in Reihen und Linien vor- und nebeneinander tanzen. Die
                  Musik der Tänze stammt meist aus den Kategorien Country und
                  Pop.
                </p>
              </div>
              <Line></Line>
            </div>
            <hr style="broder: 2px solid black" id="Step" />
            <div class="row">
              <h4
                id="kursHeading"
                style="margin-top: -15px; margin-left: 10px;"
              >
                Step-Classic
              </h4>
              <div class="row " id="kursText" data-aos="fade-right">
                <p>
                  Step Classic ist ein Herz-/Kreislauftraining, bei dem einzelne
                  Stretchingübungen, welche die Weichheit und Lockerung der
                  gesamten Körpermuskulatur sowie die Beweglichkeit fördern. Je
                  nach Schwierigkeitsgrad werden die Grundschritte und
                  -techniken sowie Armbewegungen gelernt und geübt. Sowohl
                  kreatives als auch intensives Erleben des klassischen
                  Step-Aerobic wird möglich.
                </p>
              </div>
              <Step></Step>
            </div>
            <hr style="broder: 2px solid black" id="Bodytoning" />
            <div class="row" id="bodytoning">
              <h4
                id="kursHeading"
                style="margin-top: -15px; margin-left: 10px;"
              >
                Bodytoning
              </h4>
              <div
                class="row "
                style="min-width: 100%;"
                id="kursText"
                data-aos="fade-right"
              >
                <p>
                  Bodytoning ist ein effektives und förderndes Training für den
                  ganzen Körper und strafft die Körpersilhouette.
                </p>
              </div>
            </div>
            <hr style="broder: 2px solid black" id="Zumba" />
            <div class="row">
              <h4
                id="kursHeading"
                style="margin-top: -15px; margin-left: 10px;"
              >
                Zumba
              </h4>
              <div class="row " id="kursText" data-aos="fade-right">
                <p>
                  Tanz-Workout zur Verbesserung der Koordination, der
                  Fettverbrennung, sowie der Ausdauer. Ohne größeres
                  Belastungsrisiko zu flottem Hip Hop aus Lateinamerika - Party
                  mit hohem Spaßfaktor ohne komplizierte Choreografie
                </p>
              </div>
            </div>
            <hr style="broder: 2px solid black" id="Stretch" />
            <div class="row">
              <h4
                id="kursHeading"
                style="margin-top: -15px; margin-left: 10px;"
              >
                Strong & Relax
              </h4>
              <div
                class="row"
                id="kursText"
                style="min-width: 100%;"
                data-aos="fade-right"
              >
                <p>
                  Dieser Kurs beinhaltet Dehnübungen für den ganzen Körper. Ein
                  Mix aus Dehnung & Entspannung.
                </p>
              </div>
              <StrongRelax></StrongRelax>
            </div>
            <hr style="broder: 2px solid black" id="RückenFit" />
            <div class="row">
              <h4
                id="kursHeading"
                style="margin-top: -15px; margin-left: 10px;"
              >
                RückenFit
              </h4>
              <div class="row " id="kursText" data-aos="fade-right">
                <p>
                  Ein Training zur Stärkung der Rumpf-&Rückenmuskulatur.
                  Haltungs-&Rückenprobleme werden vorgebeugt sowie
                  rückenfreundliches Verhalten im Alltag trainiert. Die
                  alltagsrelevanten Muskeln werden intensiv gekräftigt, die
                  Wirbelsäule stabilisiert, die Tiefenmuskulatur aktiviert,
                  sowie das optimale Zusammenspiel aller Muskeln gefördert
                </p>
              </div>
              <Ruecken></Ruecken>
            </div>
            <hr style="broder: 2px solid black" id="boxen" />
            <div class="row">
              <h4
                id="kursHeading"
                style="margin-top: -15px; margin-left: 10px;"
              >
                Box Dich Fit!
              </h4>
              <div class="row " id="kursText" data-aos="fade-right">
                <p>
                  Bist du bereit für etwas Neues? Geh unter der Anleitung von
                  Dominic mit speziellen Box-Übungen an dein Limit. Dein
                  Fitnesslevel spielt dabei keine Rolle. Es wird die gesamte
                  Körpermuskulatur, sowie die Kondition und die Konzentration
                  trainiert. Verbessere deine Technik, deine Agilität und deine
                  Kraft. Neugierig? Dann melde dich für einen Kurs an – die
                  Teilnehmerzahl ist begrenzt! Denkt immer daran: Aktion –
                  Reaktion!
                </p>
              </div>
              <Box></Box>
            </div>
          </div>
        </card>
      </div>
    </section>
  </div>
</template>

<script style="background-color: #F2F2F2;">
import Yoga from "../components/Kurse/Yoga";
import Vinyasa from "../components/Kurse/Vinyasa.vue";
import Ruecken from "../components/Kurse/Ruecken.vue";
import Stretch from "../components/Kurse/Stretch.vue";
import Step from "../components/Kurse/Step.vue";
import Line from "../components/Kurse/Line.vue";
import Box from "../components/Kurse/Box.vue";
import BBP from "../components/Kurse/BBP.vue";
import Evergreen from "../components/Kurse/Evergreen.vue";
import Cycling from "../components/Kurse/Cycling.vue";
import StrongRelax from "../components/Kurse/StrongRelax.vue";
import axios from "axios";
export default {
  components: {
    Yoga,
    BBP,
    StrongRelax,
    Evergreen,
    Cycling,
    Vinyasa,
    Ruecken,
    Stretch,
    Step,
    Line,
    Box,
  },
  data: function() {
    return {
      kursplan: "",
    };
  },
  mounted() {
    var ref = this;
    axios
      .get(
        "https://forum-neuenbuerg.de/action.php?username=web166&pw=BX79HmqzoxKNb4OY&request=aktuelles"
      )
      .then((response) => {
        response.data.forEach((data) => {
          if (data["name"] == "Kursplan") {
            ref.kursplan = data["link"];
          }
        });
      });
  },
};
</script>

<style scoped>
.KursContainer p {
  font-weight: 400;
  padding-right: 20px;
}

iframe {
  -webkit-box-shadow: 10px 10px 5px 0px rgb(0 0 0 / 0%);
  padding: 10px;
}

#kursHeading {
  margin-top: -15px;
  margin-left: 10px;
}

#kursText {
  margin-left: 10px;
}

#kursAuswahlBtn:hover {
  background-color: #212529;
  color: white;
  transition: 0.3s;
}

#kursAuswahlBtn {
  outline-color: transparent;
  color: #212529;
  background-color: #fbce3b;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bolder;
  font-style: italic;
  height: 80px;
  width: 200px;
  font-size: 15px;
  word-wrap: break-word;
}

.rowKurse {
  margin-bottom: 0px;
  margin-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
}

#listVerhalten {
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.7;
}

#header {
  background-image: url("../assets/img/logos/crossfit.jpg");
}

.btnRow {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  gap: 20px;
}

.btnRow .btn {
  min-width: 200px;
  margin-right: 0;
}

.btnRow3 .btn {
  height: 65px !important;
  font-size: 12px !important;
  width: 160px !important;
  height: 50px !important;
}

@media only screen and (max-width: 500px) {
  .btnRow {
    justify-content: center;
  }
}
</style>
