<template>
  <div class="trainerContainer">
    <div class="col-sm d-flex justify-content-center text-center">
      <div id="title2" class="title-container">
        <h2 class="title" data-aos="fade-right">Unsere {{ filter }}</h2>
      </div>
    </div>
    <div class="container text-center my-3 trainerContainer">
      <div class="row mx-auto my-auto">
        <div
          :id="carouselId"
          class="carousel carousel slide w-100"
          data-ride="carousel"
        >
          <div class="carousel-inner w-100" role="listbox">
            <div
              v-for="(trainer, index) in Trainer"
              :key="index"
              :class="{ 'carousel-item': true, active: index === 0 }"
            >
              <TemplateTrainer
                :name="trainer.name"
                :areas="trainer.areas"
                :age="trainer.age"
                :hobbies="trainer.hobbies"
                :education="trainer.education"
                :courses="trainer.courses"
                :quote="trainer.quote"
              ></TemplateTrainer>
            </div>
          </div>
          <a
            class="carousel-control-prev w-auto"
            :href="prevHref"
            role="button"
            data-slide="prev"
          >
            <span
              class="carousel-control-prev-icon bg-dark border border-dark rounded-circle"
              aria-hidden="true"
            ></span>
            <span class="sr-only">Previous</span>
          </a>
          <a
            class="carousel-control-next w-auto"
            :href="nextHref"
            role="button"
            data-slide="next"
          >
            <span
              class="carousel-control-next-icon bg-dark border border-dark rounded-circle"
              aria-hidden="true"
            ></span>
            <span class="sr-only">Next</span>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { trainer } from "./Trainer";
import TemplateTrainer from "./TemplateTrainer.vue";

export default {
  components: {
    TemplateTrainer,
  },
  data() {
    return {
      carouselId: "",
      prevHref: "",
      nextHref: "",
      Trainer: [],
    };
  },
  props: {
    filter: String,
  },
  mounted() {
    this.Trainer = trainer.filter((trainer) => trainer.role === this.filter);
    console.log(this.Trainer);
    this.carouselId = this.filter + "Carousel";
    this.prevHref = "#" + this.filter + "Carousel";
    this.nextHref = "#" + this.filter + "Carousel";
  },
};
</script>

<style scoped>
@media only screen and (max-width: 680px) {
  .title h2 {
    font-size: 50px !important;
  }

  #trainerContainer {
    margin-top: -50px !important;
    margin-bottom: -150px !important;
  }

  .carousel-control-prev-icon,
  .carousel-control-next-icon {
    display: none;
  }
}

@media only screen and (max-width: 1100px) {
  .title-container {
    margin-top: 0px !important;
  }
}

.title {
  font-family: noto-sans-display-extraconde, sans-serif;
  font-size: 70px;
  font-style: italic;
  margin-top: 0px;
  padding-top: 0px;
  color: white;
}

.carousel-control-prev-icon {
  margin-left: -50px;
}

.carousel-control-next-icon {
  margin-right: -50px;
}

@media only screen and (max-width: 1500px) {
  .carousel-inner .carousel-item-right.active,
  .carousel-inner .carousel-item-next {
    transform: translateX(100%);
  }

  .carousel-inner .carousel-item-left.active,
  .carousel-inner .carousel-item-prev {
    transform: translateX(-100%);
  }
}

@media only screen and (min-width: 1501px) {
  #recipeCarousel1 {
    display: none;
  }

  .carousel-control-prev-icon,
  .carousel-control-next-icon {
    margin-top: 0%;
  }

  .carousel-inner .carousel-item-right.active,
  .carousel-inner .carousel-item-next {
    transform: translateX(100%);
  }

  .carousel-inner .carousel-item-left.active,
  .carousel-inner .carousel-item-prev {
    transform: translateX(-100%);
  }
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  outline: #fbce3b !important;
  border: 1px solid #fbce3b !important;
  background-color: #fbce3b !important;
  margin-top: 10%;
}

@media (max-width: 768px) {
  .carousel-inner carousel-item > div {
    display: none;
  }

  .carousel-inner .carousel-item > div:first-child {
    display: block;
  }

  .carousel-inner carousel-item2 > div {
    display: none;
  }

  .carousel-inner .carousel-item2 > div:first-child {
    display: block;
  }
}

.carousel-inner .carousel-item.active,
.carousel-inner .carousel-item-next,
.carousel-inner .carousel-item-prev {
  display: flex;
}

.carousel-inner .carousel-item-right,
.carousel-inner .carousel-item-left {
  transform: translateX(0);
}

.trainerContainer {
  padding-top: 20px;
}
</style>
