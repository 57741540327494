<template>
  <div class="diashowEingang">
    <div class="headerEntry text-center">
      <h1>
        Hier beginnt dein gesundes Leben!
      </h1>
      <h2>
        Heute für dich im Einsatz:
      </h2>
    </div>
    <div id="carouselEntry" class="carousel slide" data-ride="carousel">
      <div class="carousel-inner " id="diashowEingang"></div>
    </div>
    <div class="footerEntry">
      <div class="row">
        <div class="col-9">
          <p>
            Beweg Dich und Fühl Dich Frei! Lebe Jetzt!
          </p>
        </div>
        <div class="col-3">
          <img src="@/assets/img/logos/Unbenannt-2.png" id="entryLogo" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import $ from "jquery";
export default {
  data() {
    return {
      img: [
        {
          img: "@/assets/img/MA/Vanessa1.jpg",
        },
      ],
    };
  },
  mounted() {
    $("#carouselEntry").carousel({
      interval: 8000,
      pause: "false",
    });
    // if (window.location.href == "https://www.forum-neuenbuerg.de/#/diashoweingang") {
    axios
      .get(
        "https://www.forum-neuenbuerg.de/Diashow.php?username=web166&pw=BX79HmqzoxKNb4OY&request=diashoweingang"
      )
      .then((response) => {
        var responseArray = this.data_to_array(response.data);
        responseArray.forEach((element, index) => {
          this.createCarouselItems(element[0], index);
          this.setImg(element[0], index);
          this.setName(element[0], index);
          this.setService(element[1], index);
          this.setTime(element[2], index);
        });
      });

    // } else {
    // 	axios.get("http://forum-neuenbuerg.de/Diashow.php?username=web166&pw=BX79HmqzoxKNb4OY&request=diashoweingang")
    // 		.then((response) => {
    // 			var responseArray = this.data_to_array(response.data);
    // 			responseArray.forEach((element, index) => {
    // 				this.createCarouselItems(element[0], index)
    // 				this.setImg(element[0], index)
    // 				this.setName(element[0], index)
    // 				this.setService(element[1], index)
    // 				this.setTime(element[2], index)
    // 			});

    // 		})
    // }
  },
  methods: {
    createCarouselItems(name, index) {
      var active = "";

      if (index == 0) {
        active = " active";
      }
      var carouselElement = document.createElement("div");
      carouselElement.className = "carousel-item" + active;
      carouselElement.id = "diashowEingang" + index;
      document.getElementById("diashowEingang").append(carouselElement);

      var structure =
        "<div class='row' ><div class='col-6'  id='trainer" +
        name +
        "'></div><div class='col-6'><p class='entryName' style='text-align: center' id='entryName" +
        index +
        "'></p><p class='entryInfo' style='text-align: center' id='entryInfo" +
        index +
        "'></p><p class='entryTime' style='text-align: center' id='entryTime" +
        index +
        "'></p></div></div>";

      document.getElementById("diashowEingang" + index).innerHTML = structure;
    },

    setImg(name, index) {
      var imgElement =
        "<img id='Img" +
        index +
        "' alt='diashowEingangImg" +
        index +
        "class='img-fluid' style='height: 55vh; margin-top: 60px;'>";
      document.getElementById("trainer" + name).innerHTML = imgElement;

      if (name == "Vanessa") {
        document.getElementById(
          "Img" + index
        ).src = require("@/assets/img/MA/Vanessa1.jpg");
      }

      if (name == "Charly") {
        document.getElementById(
          "Img" + index
        ).src = require("@/assets/img/MA/Charly1.jpg");
      }
      if (name == "Nadine") {
        document.getElementById(
          "Img" + index
        ).src = require("@/assets/img/MA/Nadine1.jpg");
      }
      if (name == "Jana") {
        document.getElementById(
          "Img" + index
        ).src = require("@/assets/img/MA/Jana1.jpg");
      }
      if (name == "Marcel") {
        document.getElementById(
          "Img" + index
        ).src = require("@/assets/img/MA/Marcel1.jpg");
      }
    },
    setName(name, index) {
      document.getElementById("entryName" + index).innerText = name;
    },
    setService(name, index) {
      document.getElementById("entryInfo" + index).innerText = name;
    },
    setTime(name, index) {
      document.getElementById("entryTime" + index).innerText = name;
    },
    data_to_array(data) {
      var array = [];
      for (var i = 0; i < data.length; i++) {
        var obj = data[i];
        array[i] = new Array();
        for (var key in obj) {
          array[i].push(obj[key]);
        }
      }
      return array;
    },
  },
};
</script>

<style>
#entryLogo {
  width: 90%;
  /* position: absolute;
		bottom: 20px;
		right: 20px; */
  z-index: 2;
}

.headerEntry h1 {
  color: #080e14;
  background-color: #fbce3b;
  padding: 10px;
  margin-top: 50px;
  font-size: 20px;
}

.headerEntry h2 {
  color: #080e14;
  background-color: lightgray;
  padding: 10px;
  margin-top: -10px;
  font-size: 20px;
}

#carouselEntry .row {
  margin-top: -8px;
}

.entryName {
  color: white;
  font-size: 30px;
  background-color: #080e14;
  border-radius: 20px;
  font-weight: 700;
  padding: 15px;
  margin: 60px 0px 0px 100px;
}

.entryInfo {
  color: #080e14;
  font-size: 30px;
  background-color: lightgray;
  border-radius: 20px;
  font-weight: 700;
  padding: 15px;

  margin-left: 100px;
  margin-top: 10px;
}
.entryTime {
  color: #080e14;
  font-size: 30px;
  background-color: lightgray;
  border-radius: 20px;
  font-weight: 700;
  padding: 15px;
  margin-top: -5px;
  margin-left: 100px;
}

.img-fluid {
  height: 90vh;
}

#carouselEntry {
  position: absolute;
  top: 24vh;
}

.diashowEingang {
  max-height: 100vh;
}

@import url("https://use.typekit.net/pxv7oqc.css");

.footerEntry p {
  color: white;
  font-size: 20px;
  font-weight: 700;
  padding: 20px;
  margin: 0px;
  text-align: left;
  font-family: ballare, sans-serif;
  font-weight: 400;
  font-style: normal;
}

.footerEntry {
  color: white;
  font-size: 10px;
  background-color: #080e14;
  position: absolute;
  bottom: -50px;
}
</style>
