export const trainer = [
  {
    name: "Dana",
    role: "Kurstrainer",
    areas: "KURSTRAINER",
    age: {
      year: 1998,
      month: 6,
      day: 13,
    },
    hobbies: "Yoga, Kochen, Gym, Lesen",
    courses: ["DeepWork", "Vinyasa Yoga"],
    quote: "Wenn du es dir vorstellen kannst, kannst du es auch tun.",
  },
  {
    name: "Elena",
    role: "Kurstrainer",
    areas: "KURSTRAINER",
    age: {
      year: 1977,
      month: 1,
      day: 15,
    },
    hobbies:
      "mit interessanten Menschen unterhalten, Neues Lernen, Tanzen, Malen, Lesen",
    courses: ["Rückenfit", "Vinyasa Yoga", "Total Body Workout"],
    quote: "GIB JEDEM TAG DIE CHANCE, DER SCHÖNSTE DEINES LEBENS ZU WERDEN.",
  },
  {
    name: "Jana",
    role: "Trainer",
    areas: "SERVICE - TRAINER - BERATUNG",
    age: {
      year: 2000,
      month: 11,
      day: 18,
    },
    hobbies: "Sport & Fitness, Reiten",
    education: ["Bachelor of Science Sportwissenschaften"],
    quote: "Your only Limit is your mind.",
  },
  {
    name: "Kathrin",
    role: "Kurstrainer",
    areas: "KURSTRAINER",
    age: {
      year: 1995,
      month: 5,
      day: 23,
    },
    hobbies: "Poledance, Reisen",
    courses: ["Strong & Relax", "Pilates & Faszientraining", "Latin Dance"],
    quote: "DAS LEBEN IST EINE GEMEINSAME REISE, KEIN KONKURRENZKAMPF.",
  },
  {
    name: "Charly",
    role: "Trainer",
    areas: "SERVICE - TRAINER - BERATUNG",
    age: {
      year: 1993,
      month: 6,
      day: 6,
    },
    hobbies: "Sport & Fitness, Familie & Freunde, Reisen",
    quote: "ALWAYS BELIEVE THAT SOMETHING WONDERFUL IS ABOUT TO HAPPEN.",
  },
  {
    name: "Marcel",
    role: "Trainer",
    areas: "SERVICE - TRAINER - BERATUNG",
    age: {
      year: 2001,
      month: 3,
      day: 29,
    },
    hobbies: "Sport",
    education: ["Fitnesstrainer A & B Lizenz"],
    quote: "NUR KEIN WORKOUT IST EIN SCHLECHTES WORKOUT.",
  },
  {
    name: "Natascha",
    role: "Kurstrainer",
    areas: "KURSTRAINER",
    age: {
      year: 1997,
      month: 0,
      day: 19,
    },
    hobbies: "Wandern, Reisen, Tanzen",
    courses: ["BBP", "Zirkel-Training"],
    quote: "Es sind die Kleinigkeiten, die das Große groß machen.",
  },
  {
    name: "Tina",
    role: "Kurstrainer",
    areas: "KURSTRAINER",
    age: {
      year: 1969,
      month: 7,
      day: 28,
    },
    hobbies: "Mein Hund, Zeichnen, Malen, Stricken",
    courses: ["BBP", "Step", "und viele mehr"],
    quote: "GIB JEDEM TAG DIE CHANCE, DER SCHÖNSTE DEINES LEBENS ZU WERDEN.",
  },
  {
    name: "Toni",
    role: "Kurstrainer",
    areas: "KURSTRAINER",
    age: {
      year: 1964,
      month: 10,
      day: 3,
    },
    courses: ["Boxen"],
    quote: "Fit zu sein ist kein Ziel, es ist eine Lebenshaltung.",
  },
  {
    name: "Vanessa",
    role: "Trainer",
    areas: "SERVICE - TRAINER - BERATUNG",
    age: {
      year: 1999,
      month: 5,
      day: 2,
    },
    hobbies: "Sport & Fitness, Kampfsport, Freunde, Reisen",
    education: ["Fitnesstrainer B-Lizenz", "Bachelor of Arts Fitnesstraining"],
    quote: "DIE ZUKUNFT HÄNGT VON DEM AB, WAS DU HEUTE TUST.",
  },
];
