<template>
   <div class="profile-page"  style="background-color: #F2F2F2;">
        <section class="section-profile-cover section-shaped my-0" id="headerKarriere">
           
        </section>
        <section class="section section-skew">
            <div class="container">
                <card shadow class="card-profile mt--300" no-body style="background-color: white;">
                    <div class="px-4">
                        <div class="row ">
                            <div class="col" style="min-width: 300px;">
                                <div class="mt-3">
                                    <h2 style="color:#fbce3b">Dualer Student / Trainer (m/w/d)</h2>
                                </div>
                            </div>
                            <div class="col" style="min-width: 300px;">
                                <base-button id="kontaktButton" type="default" size="sm" class="float-right mt-4">Kontakt</base-button>
                            </div>
                        </div>
                        <div class="mt-3 py-3 border-top ">
                            <div class="mt-3" data-aos="fade-right">
                                 <p >Wir suchen Verstärkung und das schon zum nächstmöglichen Zeitpunkt! <br>
								  Deine Expertise als Dualer Student/Trainer ist gefragt.</p>
                            </div>
							<hr>
							<div class="mt-3"  data-aos="fade-right">
							 <h4 style="color:#fbce3b; font-size: 20px;">Deine Aufgaben</h4>
								<ul>
									<li>Durchführung von Beratungs- und Verkaufsgesprächen</li>
									<li>Organisation und Durchführung von Trainingsplänen/ Probetrainings</li>
									<li>übersichtliche Flächenbetreuung sowie eine gute Übersicht bei Rundgängen</li>
									<li>kommunikatives und kompetentes Auftreten gegenüber dem Kunden</li>
									<li>aktive Generierung von Mitgliedern</li>
									<li>vollständige Aufbereitung von internen Betriebslisten</li>
								</ul>
							</div>	
							<hr>						
							<div class="mt-3"  data-aos="fade-right">
							 <h4 style="color:#fbce3b; font-size: 20px;">Was wir suchen</h4>
								<ul>
									<li>sicheres, aufgeschlossenes, kompetentes und kommunikatives Auftreten</li>
									<li>ausgeprägte Kundenorientierung und sicherer Umgang mit der deutschen Sprache</li>
									<li>zuverlässige und sorgfältige Bearbeitung von Sachverhalten sowie sicherer Umgang in der Formulierung</li>
									<li>Teamfähigkeit, Pünktlichkeit, entwicklungsfähig, zielfokussiert und verantwortungsbewusst</li>								
								</ul>
							</div>
							<hr>
							<div class="mt-3"  data-aos="fade-right">
							 <h4 style="color:#fbce3b; font-size: 20px;">Was wir bieten</h4>
								<ul>
									<li>Langfristige Entwicklungsmöglichkeiten nach Einarbeitung in einer jungen, modernen Arbeitsumgebung</li>
									<li>Eine sichere Anstellung mit leistungsgerechter Vergütung in einem zukunftssicheren Fitnessmarkt</li>
									<li>Ein attraktives Umfeld, geprägt durch ein modernes, für alle Alters- und Zielgruppen geeignetes, modernes Dienstleistungsprodukt</li>
									<li>stetiger Austausch zu diversen Optimierungsprozessen mit der Managerebene</li>
									<li>ein gut eingespieltes und kompetentes Team</li>								
								</ul>
							</div>
							<hr>
							<div class="mt-3" data-aos="fade-right">
                                 <p >Du bist interessiert, in einem aufstrebenden Studio mitzuwirken?
									 <br>Dann bewirb dich bei uns!
								 </p>
                            </div>
						<div class="mt-3" data-aos="fade-right">
										<a href= "mailto:info@forum-neuenbuerg.de" id="submit" type="submit" class="btn btnColor" style="height: 40px;"
											>Jetzt Bewerben</a>
									</div>
                        </div>
                    </div>
                </card>
            </div>
        </section>
    </div>
</template>

<script>
export default {

}
</script>

<style scoped>

    @media only screen and (max-width: 767px) {
    #kontaktButton{
        width:100%!important;
        margin-top: 20px!important;
    }
}

</style>