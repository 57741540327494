<template>
  <section class="section" id="personeninfoContainer">
    <MeetingModal title="Dein Weg zu Forum Live! 🚀" />
    <div class="row">
      <div class="col-sm d-flex justify-content-center text-center">
        <div data-aos="fade-up" id="title" style="margin-top:0px;">
          <h2>WERDE TEIL DES FORUMS</h2>
          <h4>& LEBE JETZT</h4>
        </div>
      </div>
    </div>
    <div
      class="row d-flex justify-content-center text-center"
      style="margin-top: 60px;"
    >
      <MembershipForm></MembershipForm>
    </div>
  </section>
</template>

<script>
import MembershipForm from "./MembershipForm.vue";

import MeetingModal from "./MeetingModal.vue";
export default {
  components: {
    MembershipForm,
    MeetingModal,
  },
};
</script>

<style>
@import "./getLiveStyles.css";
</style>
