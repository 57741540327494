<template>
  <div class="grid">
    <div
      class="modal fade"
      id="CookieModal"
      tabindex="1"
      style=""
      role="dialog"
      aria-labelledby="CookieModalTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle">
              Cookie-Einstellungen
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            Diese Internetseite verwendet Cookies, um die Nutzung von
            eingebetteten Videos der Plattform Youtube und Karten der Plattform
            Google Maps zu ermöglichen. Um diese Inhalte sehen zu können,
            bestätigen Sie bitte die Cookienutzung.
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-primary"
              data-dismiss="modal"
              @click="acceptCookies()"
            >
              Zulassen
            </button>
            <keep-alive>
              <router-link :to="{ name: 'Datenschutz' }">
                <button
                  data-dismiss="modal"
                  type="button"
                  class="btn btn-secondary"
                >
                  Datenschutz
                </button>
              </router-link>
            </keep-alive>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
export default {
  methods: {
    acceptCookies() {
      this.$store.state.cookiesIsActive = true;
      this.$cookies.set("user_session", "X1!KMs5%sA?");
      location.reload();
    },
  },
  mounted() {
    if (!this.$cookies.isKey("user_session")) {
      $("#CookieModal").modal("show");
    }
  },
};
</script>

<style scoped>
.grid {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

ul {
  border: 1px solid gray;
  padding: 1rem 2rem;
  width: 200px;
}

li {
  text-align: left;
  margin: 0.5rem 0;
}

button.close {
  background-color: transparent !important;
}

.item {
  display: grid;
  grid-template-columns: 1fr 96px;
  width: 100%;
}

.modal-footer {
  display: block;
  text-align: right;
}

@media only screen and (max-width: 476px) {
  a {
    /* margin-top: 10px; */
  }

  .modal-footer button {
    margin-right: 0;
    margin-bottom: 20px;
  }
}
</style>
