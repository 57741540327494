<template>
	<div class="grid">
		<div class="modal fade" id="EggModal" tabindex="1" style="" role="dialog" aria-labelledby="EggModalTitle"
			aria-hidden="true">
			<div class="modal-dialog modal-dialog-centered" role="document">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title" id="EggSuccess">Herzlichen Glückwunsch!</h5>
						<button type="button" class="close" data-dismiss="modal" aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div class="modal-body">
						Du hast ein Osterei und damit einen Gewinn gefunden!<br>Lade den Gewinngutschein herunter, um
						ihn bei uns abholen zu können!

						Hinweis: Falls der Browser auf deinem Mobilgerät den Gutschein nicht automatisch herunterlädt, kannst du uns einen Screenshot von deinem gefundenen Ei zusenden!
					</div>
					<div class="modal-footer">
						<keep-alive>
							<button @click="downloadEgg()" data-dismiss="modal" type="button"
								class="btn btn-primary">Gutschein
								Herunterladen</button>
						</keep-alive>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import $ from 'jquery';

	import axios from "axios";
	export default {
		methods: {
			downloadEgg() {

				if (window.location.href == "https://www.forum-neuenbuerg.de/#/") {
					axios.get(
							"https://www.forum-neuenbuerg.de/action.php?username=web166&pw=BX79HmqzoxKNb4OY&request=egg&color=" +
							this.$store.state.klickedEgg
						)
						.then(response => {

							const linkSource = `data:application/octet-stream;base64,${response.data[0].pdf}`;
							const downloadLink = document.createElement("a");
							const fileName = "Ostergutschein.pdf";

							downloadLink.href = linkSource;
							downloadLink.download = fileName;
							downloadLink.click();
						});
				} else {
					axios.get(
							"https://forum-neuenbuerg.de/action.php?username=web166&pw=BX79HmqzoxKNb4OY&request=egg&color=" +
							this.$store.state.klickedEgg
						)
						.then(response => {

							const linkSource = `data:application/octet-stream;base64,${response.data[0].pdf}`;
							const downloadLink = document.createElement("a");
							const fileName = "Ostergutschein.pdf";

							downloadLink.href = linkSource;
							downloadLink.download = fileName;
							downloadLink.click();
						});

				}
			}

		},
		mounted() {

		}
	}
</script>

<style scoped>
	.grid {
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
	}

	ul {
		border: 1px solid gray;
		padding: 1rem 2rem;
		width: 200px;
	}

	li {
		text-align: left;
		margin: 0.5rem 0;
	}

	button.close {
		background-color: transparent !important;
	}

	.item {
		display: grid;
		grid-template-columns: 1fr 96px;
		width: 100%;
	}
</style>