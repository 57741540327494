import { render, staticRenderFns } from "./Kurse.vue?vue&type=template&id=7549477a&scoped=true&"
import script from "./Kurse.vue?vue&type=script&style=background-color%3A%20%23F2F2F2%3B&lang=js&"
export * from "./Kurse.vue?vue&type=script&style=background-color%3A%20%23F2F2F2%3B&lang=js&"
import style0 from "./Kurse.vue?vue&type=style&index=0&id=7549477a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7549477a",
  null
  
)

export default component.exports