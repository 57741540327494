import axios from "axios";
import store from "../../../store";

export const validateForm = () => {
  return new Promise((resolve, reject) => {
    var forms = document.getElementsByClassName("needs-validation");

    Array.prototype.filter.call(forms, function(form) {
      form.addEventListener(
        "submit",
        function(event) {
          if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            reject(new Error("Validation failed."));
          } else {
            resolve(form);
          }
          form.classList.add("was-validated");
        },
        false
      );
    });
  });
};

export const sendMail = (data, url) => {
  axios.post(url, data).then(
    (response) => {
      if (response.data != "Message Sent OK") {
        store.state.mail.status = "failed";
        store.state.mail.response =
          "Huch, hier ist leider etwas schief gegangen! Bitte sende uns eine Mail mit deinem Anliegen an info@forum-neuenbuerg.de";
      } else {
        store.state.mail.status = "success";
        store.state.mail.response =
          "Vielen Dank für deine Anfrage! Wir bearbeiten diese so schnell wie möglich und geben dir Bescheid!";
      }
    },
    (error) => {
      store.state.mail.status = "failed";
      store.state.mail.response =
        "Huch, hier ist leider etwas schief gegangen! Bitte sende uns eine Mail mit deinem Anliegen an info@forum-neuenbuerg.de";
    }
  );
};
