<template>
  <div class="diashowWerbung">
    <div id="carouselWerbung" class="carousel slide" data-ride="carousel">
      <div class="carousel-inner text-center" id="diashowWerbung"></div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import $ from "jquery";
export default {
  mounted() {
    $("#carouselWerbung").carousel({
      interval: 16000,
      pause: "false",
    });
    axios
      .get(
        "https://www.forum-neuenbuerg.de/Diashow.php?username=web166&pw=BX79HmqzoxKNb4OY&request=entry"
      )
      .then((response) => {
        var responseArray = this.data_to_array(response.data);
        responseArray = responseArray.reverse();
        responseArray.forEach((element, index) => {
          this.createCarouselItems(index);

          if (element[0] == "img") {
            this.setImg(element[1], index);
          }
        });
      });
  },
  methods: {
    createCarouselItems(index) {
      var active = "";

      if (index == 0) {
        active = " active";
      }
      var carouselElement = document.createElement("div");
      carouselElement.className = "carousel-item" + active;
      carouselElement.id = "diashowWerbung" + index;
      document.getElementById("diashowWerbung").append(carouselElement);
    },

    setImg(base64, index) {
      var maxwidth = "100%";
      var imgElement =
        "<img id='Img" +
        index +
        "' alt='diashowWerbung" +
        index +
        "' src='data:image/png;base64," +
        base64 +
        "' class='imagediashowWerbung' style ='cursor: pointer; width:" +
        maxwidth +
        "' >";
      document.getElementById("diashowWerbung" + index).innerHTML = imgElement;
    },

    data_to_array(data) {
      var array = [];
      for (var i = 0; i < data.length; i++) {
        var obj = data[i];
        array[i] = new Array();
        for (var key in obj) {
          array[i].push(obj[key]);
        }
      }
      return array;
    },
  },
};
</script>

<style>
#carouselWerbung img {
  height: 100vh;
  /* width: 100vw; */
  width: auto !important;
}

#carouselWerbung {
  background-color: black;
}

video {
  margin-top: 1vh;
  margin-bottom: 10vh;
}
</style>
