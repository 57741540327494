const labels = [
  "06:00",
  "07:00",
  "08:00",
  "09:00",
  "10:00",
  "11:00",
  "12:00",
  "13:00",
  "14:00",
  "15:00",
  "16:00",
  "17:00",
  "18:00",
  "19:00",
  "20:00",
  "21:00",
  "22:00",
];

const type = "line";

const dataStyle = {
  strokeColor: "#ff6c23",
  borderWidth: 1,
};

const options = {
  scales: {
    yAxes: [
      {
        ticks: {
          display: false,
        },
        gridLines: {
          drawBorder: true,
          display: false,
        },
      },
    ],
    xAxes: [
      {
        gridLines: {
          display: false,
        },
      },
    ],
  },
  legend: {
    display: false,
  },
  elements: {
    point: {
      radius: 0,
    },
  },
};

const data = [
  //monday
  [0, 21, 39, 63, 79, 74, 58, 56, 56, 56, 69, 85, 115, 112, 74, 40, 0],
  //tuesday
  [0, 13, 25, 35, 43, 43, 25, 29, 47, 60, 58, 118, 131, 95, 54, 29, 0],
  //wedneseday
  [0, 26, 38, 60, 62, 62, 41, 36, 38, 42, 57, 93, 109, 104, 67, 47, 0],
  //thursday
  [0, 19, 25, 38, 51, 51, 31, 26, 33, 45, 60, 91, 88, 69, 46, 21, 0],
  //friday
  [0, 19, 26, 35, 41, 44, 39, 42, 57, 60, 56, 67, 68, 66, 56, 27, 0],
  //saturday
  [0, 0, 0, 0, 67, 73, 56, 42, 44, 42, 43, 0, 0, 0, 0, 0, 0],
  //sunday
  [0, 0, 0, 0, 75, 84, 68, 67, 65, 59, 41, 0, 0, 0, 0, 0, 0],
];

const TimeData = (dataIndex, color) => [
  {
    type: type,
    data: {
      labels,
      datasets: [
        {
          data: data[dataIndex],
          dataStyle,
          backgroundColor: color,
        },
      ],
    },
    options: options,
  },
];

export default TimeData;
