<template>
  <div class="containerMgfin">
    <section
      class="section-profile-cover section-shaped my-0"
      id="headerMGfin"
    ></section>
    <section class="section section-skew" style="margin-top: -150px;">
      <div class="container">
        <card
          shadow
          class="card-profile mt--300"
          no-body
          style="background-color: white;"
        >
          <div class="px-4">
            <div class="row ">
              <div class="col">
                <div class="mt-3">
                  <h2 id="agbtitle" style="color:#fbce3b, fon">
                    Vielen Dank für deine Anfrage!
                  </h2>
                </div>
              </div>
            </div>
            <div class="mt-3 py-3 border-top ">
              <div class="row " data-aos="fade-right">
                <div class="col-lg129">
                  <p>
                    <strong
                      >Abschiede sind nie schön, auch für uns nicht. Wir hoffen
                      dich bald wiederzusehen und wünschen dir alles Gute für
                      deine sportliche Zukunft!</strong
                    >
                  </p>
                  <p>
                    <strong
                      >Wir melden uns so schnell wie möglich bei dir!<br />In
                      dringenden Anliegen kannst du uns telefonisch unter
                      <strong>0 70 82 / 94 11 44</strong> zu unseren
                      Öffnungszeiten erreichen.</strong
                    >
                  </p>
                  <h4 class="mt-2" style="color:#fbce3b">Öffnungszeiten</h4>
                  <div class="row">
                    <div class="col-lg-2">Mo-Fr</div>
                    <div class="col-lg-2">06-22 Uhr</div>
                  </div>
                  <div class="row">
                    <div class="col-lg-2">Sa,So</div>
                    <div class="col-lg-2">09-17 Uhr</div>
                  </div>
                  <div class="row">
                    <div class="col-lg-2">Feiertag</div>
                    <div class="col-lg-2">9-17 Uhr</div>
                  </div>
                  <div class="row">
                    <div class="col-lg-3">365 Tage geöffnet</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </card>
      </div>
    </section>
  </div>
</template>

<script>
import axios from "axios";
import $ from "jquery";
export default {
  data() {
    return {
      vorname: "",
      nachname: "",
      mail: "",
      telefon: "",
      nachricht: "",
      datenschutzConsent: false,
    };
  },
  methods: {
    loadInfo() {
      this.vorname = document.getElementById("inputVorname").value;
      this.nachname = document.getElementById("inputNachname").value;
      this.mail = document.getElementById("inputMail").value;
      this.telefon = document.getElementById("inputTel").value;
      this.nachricht = document.getElementById("inputMessage").value;
      this.datenschutzConsent = document.getElementById("agbCheck").checked;
    },
    checkInfo() {
      if (
        this.vorname == "" ||
        this.nachname == "" ||
        this.mail == "" ||
        this.telefon == "" ||
        this.nachricht == "" ||
        this.datenschutzConsent == false
      ) {
        return false;
      } else {
        return true;
      }
    },
    submitInfo() {
      this.loadInfo();
      const ref = this;
      if (this.checkInfo() == true) {
        axios
          .post("https://forum-neuenbuerg.de/kuendigung.php", {
            vorname: this.vorname,
            nachname: this.nachname,
            telefon: this.telefon,
            mail: this.mail,
            nachricht: this.nachricht,
          })
          .then(
            (response) => {
              if (response.data == "sent successully") {
                ref.$router.push("/Kuendigungabgeschlossen");
              }
            },
            (error) => {}
          );
      }
    },
  },
  mounted() {},
};
</script>

<style scoped>
#headerMGfin {
  background-image: url("../assets/img/logos/crossfit.jpg");
}

#kontaktButton {
  background-color: #fbce3b;
  border-color: transparent;
  width: 200px;
  font-size: 15px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bolder;
  font-style: italic;
}

#kontaktButton:hover {
  background-color: #212529;
  color: white;
  transition: 0.3s;
}
</style>
