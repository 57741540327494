export const media = [
	{
	  thumb: require('@/assets/img/RB/RB1.jpg'),
		src: require('@/assets/img/RB/RB1.jpg'),
	},
	{
		thumb: require('@/assets/img/RB/RB2.jpg'),
		  src: require('@/assets/img/RB/RB2.jpg'),
	  },
	  {
		thumb: require('@/assets/img/RB/RB3.jpg'),
		  src: require('@/assets/img/RB/RB3.jpg'),
	  },
	  {
		thumb: require('@/assets/img/RB/RB4.jpg'),
		  src: require('@/assets/img/RB/RB4.jpg'),
	  },
	  {
		thumb: require('@/assets/img/RB/RB5.jpg'),
		  src: require('@/assets/img/RB/RB5.jpg'),
	  }
	
  ]