<template>
  <div class="profile-page" style="background-color: #F2F2F2;">
    <section class="section-profile-cover" id="header"></section>
    <section class="section section-skew sectionEinwilligung">
      <div class="container">
        <card
          shadow
          class="card-profile mt--300"
          no-body
          style="background-color: white;"
        >
          <div class="px-4">
            <div class="row ">
              <div class="col">
                <div class="mt-3">
                  <h2 style="color:#fbce3b">DATENSCHUTZ</h2>
                </div>
              </div>
              <div class="col">
                <base-button
                  id="kontaktButton"
                  type="default"
                  size="sm"
                  class="float-right mt-4"
                  >Kontakt</base-button
                >
              </div>
            </div>
            <div class="mt-3 py-3 border-top ">
              <div class="row " data-aos="fade-right">
                <div class="col-lg-12" id="datenschutzContent">
                  <p><strong>Datenschutzklausel</strong></p>
                  <p>
                    Die personenberzogenen Daten (Name, Anschrift, Geburtstag,
                    Kontoverbindungen, E-Mail und Tel. mobil) des Mitglieds
                    werden gemäß den aktuell geltenden Datenschutzbestimmungen
                    erhoben und verarbeitet (auch elektronisch). Es werden keine
                    personenberzogenene Daten an Dritte übermittelt. Hiervon
                    ausgenommen sind Auftragsverarbeiter, die vertraglich zur
                    Verschwiegenheit verpflichtet werden. Nach Beendigung des
                    Vertrages werden nur die Daten im Rahmen der gesetzlichen
                    Aufbewahrungspflicht gespeichert und nach deren Ablauf
                    automatisch gelöscht. Der für die Datenverarbeitung
                    Verantwortliche ist die Frank Reuck und Clement Deeg GbR,
                    Fitness- und Gesundheitsforum, Daimlerstr. 2 in 75305
                    Neuenbürg, info@forum-neuenbuerg.de. Die Aufsichtsbehörde,
                    bei der Ihnen ein Beschwerderecht zusteht, ist der
                    Landesbeauftragte für den Datenschutz Baden-Württemberg,
                    Königstr. 10a, 70173 Stuttgart.
                  </p>
                  <p>
                    <strong
                      >Dem Mitglied stehen gegnüber der Frank Reuck und Clement
                      Deeg GbR, Fitness- und Gesundheitsforum, Daimlerstr. 2 in
                      75305 Neuenbürg folgende Rechte zu:</strong
                    >
                  </p>
                  <p>
                    Das Recht auf Auskunft (es entstehen dem Mitglied dadurch
                    keine Kosten wie Porto bzw. Übermittlungskosten), ein
                    Berichtigungsrecht, das Recht auf Löschung, auf
                    Einschränkung der Verarbeitung, sowie das Recht,
                    gespeicherte Daten heraus zu verlangen, um sie bei einem
                    anderen Verantwortlichen speichern zu lassen (Recht auf
                    Datenübertragbarkeit). Das Mitglied hat bei unrechtmäßiger
                    Datenverarbeitung das Recht, Widerspruch einzulegen. Der
                    Verantwortliche verarbeitet die personenberzogenen Daten
                    dann nicht mehr, es sei denn, er kann zwingende
                    schutzwürdige Gründe für die Verarbeitung nachweisen, die
                    die Interessen, Rechte und Freiheiten der betroffenen Person
                    überwiegen , oder die Verarbeitung dient der Geltendmachung,
                    Ausübung oder Verteidigung von Rechtsansprüchen.
                  </p>
                  <p>
                    Das Forum ist zur Teilnahme an einem
                    Streitbeilegungsverfahren vor einer
                    Verbraucherschlichtungsstelle weder bereit noch
                    verpflichtet.
                  </p>
                  <p>
                    Sollten einzelne Bestimmungen dieses Vertrages unwirksam
                    oder undurchführbar sein bzw. nach Vertragsabschluss
                    unwirksam oder undurchführbar werden, so wird dadurch diw
                    Wirksamkeit des Vertrages im Übrigen nicht berührt. Soweit
                    die Bestimmungen nicht Vertragsbestandteil geworden oder
                    unwirksam sind, richtet sich der Inhalt des Vertrages nach
                    den gesetzlichen Vorschirften. Mündliche Nebenabreden
                    bestehen nicht.
                  </p>
                  <h4 class="mt-2" style="color:#fbce3b">Öffnungszeiten</h4>
                  <div class="row">
                    <div class="col-lg-2">Mo-Fr</div>
                    <div class="col-lg-2">06-22 Uhr</div>
                  </div>
                  <div class="row">
                    <div class="col-lg-2">Sa,So</div>
                    <div class="col-lg-2">09-17 Uhr</div>
                  </div>
                  <div class="row">
                    <div class="col-lg-2">Feiertag</div>
                    <div class="col-lg-2">9-17 Uhr</div>
                  </div>
                  <div class="row">
                    <div class="col-lg-3">365 Tage geöffnet</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </card>
      </div>
    </section>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
@media only screen and (max-width: 654px) {
  #kontaktButton {
    width: 100% !important;
    margin-top: 20px !important;
  }
}

@media only screen and (max-width: 396px) {
  #kontaktButton {
    margin-top: 20px !important;
  }
}

.sectionEinwilligung p {
  font-weight: 400;
}

#header {
  background-image: url("../assets/img/logos/crossfit.jpg");
}
#kontaktButton {
  background-color: #fbce3b;
  border-color: transparent;
  width: 200px;
  font-size: 15px;

  font-family: Arial, Helvetica, sans-serif;
  font-weight: bolder;
  font-style: italic;
}
#kontaktButton:hover {
  background-color: #212529;
  color: white;
  transition: 0.3s;
}

#datenschutzContent h5,
a {
  color: #fbce3b;
}
</style>
