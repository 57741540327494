export const media = [
  {
    thumb: require("@/assets/img/FG/FG2.jpg"),
    src: require("@/assets/img/FG/FG2.jpg"),
  },
  {
    thumb: require("@/assets/img/FG/FG4.jpg"),
    src: require("@/assets/img/FG/FG4.jpg"),
  },
  {
    thumb: require("@/assets/img/FG/FG7.jpg"),
    src: require("@/assets/img/FG/FG7.jpg"),
  },
];
