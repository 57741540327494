
<template>
  <div
    class="FG" 
  >
    <div>
      <button id="imgBtn"  @click="openGallery(0)" class="btn"><i class="fa fa-picture-o fa-2x" ></i>
      <a style="font-size: 20px;"><br><strong>zeig mir mehr!</strong></a></button>

      <LightBox
        ref="lightbox"
        :media="media"
        :show-caption="true"
        :show-light-box="false"
      />
    </div>
  </div>
</template>

<script>
import LightBox from './LightBox'
import {
  media,
  paragraphs,
} from './FGMedia'
export default {
  components: {
    LightBox,
  },
  data () {
    return {
      media,
    }
  },
  methods: {
    openGallery(index) {
      this.$refs.lightbox.showImage(index)
    }
  }
}
</script>

<style scoped>

.vue-lb-info{
  display: none!important;
}
#imgBtn {
		outline-color: transparent;
		color: #212529;
		background-color: #FBCE3B;
		font-family: Arial, Helvetica, sans-serif;
		font-weight: bolder;
		font-style: italic;
		height: 80px;
		width: 200px;
		font-size: 15px;
	}

  #imgBtb i,
  #imgBtn a{
    color: #212529;
    transition: 0.3s;
  }

  #imgBtn:hover {
		background-color: #212529;
		color: white;
		transition: 0.3s;
	}

   #imgBtn:hover a{
		color: white;
    transition: 0.3s;
	}

</style>

