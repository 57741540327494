
<template>
  <div
    class="sectionTEST" 
  >
   <h1>
      TEST
    </h1>
    <div
      v-for="i in [0, 1, 2, 3, 4]"
      :key="i"
    >
    </div>
   
    <div>
      <ul>
        <li
          v-for="(image, index) in media"
          :key="index"
          style="display: inline-block"
        >
          <img
            v-lazy="image.src || image.thumb"
            style="height: 100px"
            @click="openGallery(index)"
          >
        </li>
      </ul>

      <LightBox
        ref="lightbox"
        :media="media"
        :show-caption="true"
        :show-light-box="false"
        :site-loading="siteLoading"
      />
    </div>
  </div>
</template>

<script>
import LightBox from './LightBox'
import {
  media,
  paragraphs,
} from './dummy'
export default {
  components: {
    LightBox,
  },
  data () {
    return {
      media,
      paragraphs,
    }
  },
  methods: {
    openGallery(index) {
      this.$refs.lightbox.showImage(index)
    }
  }
}
</script>

