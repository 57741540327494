export const media = [
	{
	  thumb: require('@/assets/img/Massage/massage1.jpg'),
		src: require('@/assets/img/Massage/massage1.jpg'),
	},
	{
		thumb: require('@/assets/img/Massage/massage2.jpg'),
		  src: require('@/assets/img/Massage/massage2.jpg'),
	  }
	
  ]