<template>
  <form
    style="width: 800px;"
    class="needs-validation"
    novalidate
    data-aos="fade-right"
    id="formPerson"
    onsubmit="return false"
  >
    <p id="formHeading">Persönliche Daten</p>
    <p id="formSubText">
      Informationen zum Datenschutz findest du
      <router-link :to="{ name: 'datenschutzerklaerung' }" target="_blank"
        >hier</router-link
      >.
    </p>
    <div class="form-row" style="margin-top: -25px;">
      <div class="form-group col-md-5">
        <label for="inputVorname"></label>
        <input
          type="text"
          class="form-control"
          v-model="firstName"
          placeholder="Vorname*"
          required
        />
        <div class="invalid-feedback"></div>
      </div>
      <div class="form-group col-md-5">
        <label for="inputNachname"></label>
        <input
          type="text"
          class="form-control"
          v-model="lastName"
          placeholder="Nachname*"
          required
        />
        <div class="invalid-feedback"></div>
      </div>
      <div class="custom-control col-md-2" style="margin-top: 24px;">
        <select class="form-control" v-model="gender">
          <option value="" selected hidden>Geschlecht</option>
          <option>Männlich</option>
          <option>Weiblich</option>
          <option>Divers</option>
        </select>
      </div>
    </div>
    <p id="formHeading">Geburtsdaten</p>
    <p id="formSubText">
      Du musst mindestens 18 Jahre alt sein, um die Mitgliedschaft online
      abzuschließen.
    </p>
    <div class="form-row">
      <div class="form-group col-md-4">
        <select class="form-control" v-model="day" required>
          <option value="" selected disabled>Tag*</option>
          <option v-for="dayOption in days" :key="dayOption">{{
            dayOption
          }}</option>
        </select>
        <div class="invalid-feedback"></div>
      </div>
      <div class="form-group col-md-4" id="rowMonth">
        <select class="form-control" v-model="month" required>
          <option value="" selected disabled>Monat*</option>
          <option v-for="monthOption in months" :key="monthOption">{{
            monthOption
          }}</option>
        </select>
        <div class="invalid-feedback"></div>
      </div>
      <div class="form-group col-md-4" id="rowYear">
        <select class="form-control" v-model="year" required>
          <option value="" selected disabled>Jahr*</option>
          <option v-for="yearOption in years" :key="yearOption">{{
            yearOption
          }}</option>
        </select>
        <div class="invalid-feedback"></div>
      </div>
    </div>
    <p id="formHeading">Kontaktdaten</p>
    <div class="form-row">
      <div class="form-group col-md-10">
        <label for="inputStraße"></label>
        <input
          type="text"
          class="form-control"
          v-model="street"
          placeholder="Straße*"
          required
        />
        <div class="invalid-feedback"></div>
      </div>
      <div class="form-group col-md-2">
        <label for="inputStrNummer"></label>
        <input
          type="text"
          class="form-control"
          v-model="number"
          placeholder="Nummer*"
          required
        />
        <div class="invalid-feedback"></div>
      </div>
      <div class="form-group col-md-3">
        <label for="inputPlz"></label>
        <input
          type="text"
          class="form-control"
          v-model="plz"
          placeholder="Postleitzahl*"
          required
        />
        <div class="invalid-feedback"></div>
      </div>
      <div class="form-group col-md-9">
        <label for="inputOrt"></label>
        <input
          type="text"
          class="form-control"
          v-model="city"
          placeholder="Ort*"
          required
        />
        <div class="invalid-feedback"></div>
      </div>
      <div class="form-group col-md-6">
        <label for="inputMail"></label>
        <input
          type="text"
          class="form-control"
          v-model="mail"
          placeholder="E-Mail*"
          required
        />
        <div class="invalid-feedback"></div>
      </div>
      <div class="form-group col-md-6">
        <label for="inputTel"></label>
        <input
          type="text"
          class="form-control"
          v-model="phone"
          placeholder="Telefonnummer"
        />
      </div>
    </div>
    <p id="formHeading">Bankverbindung</p>
    <p id="formSubText" style="margin-bottom: -5px;">
      <strong
        >Der Kontoinhaber muss identisch mit dem Antragsteller sein. Falls dies
        nicht der Fall ist, gehe bitte in eines unserer Studios um den Vertrag
        abzuschließen.</strong
      >
    </p>
    <div class="form-row">
      <div class="form-group col-md-6">
        <label for="inputInhaber"></label>
        <input
          type="text"
          class="form-control"
          v-model="owner"
          placeholder="Kontoinhaber*"
          required
        />
        <div class="invalid-feedback"></div>
      </div>
      <div class="form-group col-md-6">
        <label for="inputIban"></label>
        <input
          type="text"
          class="form-control"
          v-model="iban"
          placeholder="IBAN*"
          required
        />
        <div class="invalid-feedback"></div>
      </div>
      <div class="custom-control custom-checkbox" style="padding-left: 35px;">
        <input
          class="custom-control-input"
          type="checkbox"
          value=""
          id="kontoCheck"
          required
        />
        <div class="invalid-feedback"></div>
        <label class="custom-control-label text-left" for="kontoCheck"
          ><strong>
            Ich ermächtige das Forum oder ihre Rechtsnachfolger, Zahlungen von
            meinem Konto mittels Lastschrift einzuziehen. Zugleich weise ich
            mein Kreditinstitut an, die vom Forum auf mein Konto gezogenen
            Lastschriften einzulösen.</strong
          >
        </label>
      </div>
      <label class="text-left" style="margin-top: 20px; padding-left: 10px;"
        ><strong
          >Der Mitgliedsbeitrag wird mit einer SEPA-Lastschrift von angegebener
          Kontoverbindung spätestens zum dritten Werktag eines jeden Monats,
          beginnend mit dem Vertragsbeginn eingezogen.<br /><br />Die
          Gläubiger-Identifikationsnummer lautet:
          <strong>DE66ZZZ00000150974</strong><br />Die Mandatsreferenz wird
          seperat mitgeteilt.
        </strong></label
      >
    </div>
    <div class=" justify-content-center text-center">
      <div class="sectionSignature">
        <p id="formHeading" style="margin-bottom: 20px;" data-aos="fade-up">
          Unterschrift
        </p>
        <div class="row justify-content-center text-center">
          <VueSignaturePad
            class="VueSignaturePad"
            width="100%"
            height="300px"
            ref="signaturePad"
            data-aos="fade-up"
            required
          />
        </div>
        <div class="row" data-aos="fade-up">
          <div class="col">
            <input
              value="löschen"
              id="undo"
              type="undo"
              class="btn btn-primary"
              v-on:click="undoSign()"
            />
          </div>
        </div>
        <hr
          style="border-top: 0.0625rem solid rgba(0, 0, 0, 0.3);; margin-top: 60px;"
        />
        <div class="custom-control custom-checkbox" style="margin-top: 30px;">
          <input
            class="custom-control-input"
            type="checkbox"
            value=""
            id="agbCheck"
            required
          />
          <div class="invalid-feedback"></div>
          <label
            class="custom-control-label"
            for="agbCheck"
            data-aos="fade-up"
            style="font-size: 15px"
            ><strong>
              Ich habe die
              <router-link :to="{ name: 'AGB' }" target="_blank"
                >Allgemeinen Geschäftsbedingungen</router-link
              >
              sowie die
              <router-link
                :to="{ name: 'datenschutzerklaerung' }"
                target="_blank"
                >Datenschutzerklärung</router-link
              >. gelesen und akzeptiere sie.</strong
            >
          </label>
        </div>
        <div
          class="row justify-content-center text-center"
          style="margin-top: 20px;"
          data-aos="fade-up"
        >
          <input
            value="kostenpflichtig abschließen"
            type="submit"
            class="btn btn-primary"
            v-on:click="submitInfo()"
          />
        </div>
        <p data-aos="fade-up" id="signText" style="display: none">
          <strong>Bestätige den Abschluss mit deiner Unterschrift.</strong>
        </p>
      </div>
    </div>
    <div
      class="row justify-content-center text-center"
      style="margin-top: 20px;"
    >
      <p style="color: red; display: none" id="ageText">
        <strong
          >Du musst 18 Jahre alt sein, um die Mitgliedschaft online
          abzuschließen. Bitte schließe deine Mitgliedschaft in unserem Studio
          ab.</strong
        >
      </p>
    </div>
  </form>
</template>

<script>
import { validateForm, sendMail } from "./utils";
import store from "../../../store";
import $ from "jquery";

export default {
  data() {
    return {
      validAge: false,
      sign: [],
      firstName: "",
      lastName: "",
      gender: "",
      street: "",
      number: "",
      city: "",
      plz: "",
      mail: "",
      phone: "",
      owner: "",
      iban: "",
      birthday: "",
      day: "",
      month: "",
      year: "",
      days: Array.from({ length: 31 }, (_, i) =>
        String(i + 1).padStart(2, "0")
      ),
      months: Array.from({ length: 12 }, (_, i) =>
        String(i + 1).padStart(2, "0")
      ),
      years: Array.from({ length: 84 }, (_, i) => String(2020 - i)),
    };
  },
  mounted() {},
  methods: {
    undoSign() {
      this.$refs.signaturePad.clearSignature();
    },

    saveSign() {
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
      if (isEmpty) {
        document.getElementById("signText").style.display = "block";
        return false;
      } else {
        var length = data.length;
        this.sign = data.substring(22, length);
        document.getElementById("signText").style.display = "none";
        return true;
      }
    },

    getBirthday() {
      const day = parseInt(this.day);
      const month = parseInt(this.month);
      const year = parseInt(this.year);
      this.calcAge();
      this.birthday = day + "." + month + "." + year;
    },

    calcAge() {
      var today = new Date();
      var dd = parseInt(String(today.getDate()).padStart(2, "0"));
      var mm = parseInt(String(today.getMonth() + 1).padStart(2, "0"));
      var yy = today.getFullYear();

      if (yy - this.year < 18) {
        this.validAge = false;
      } else if (this.month >= mm && this.day > dd && yy - this.year == 18) {
        this.validAge = false;
      } else {
        this.validAge = true;
      }
    },

    startSending() {
      const formData = {
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.mail,
        phone: this.phone,
        gender: this.gender,
        street: this.street,
        number: this.number,
        city: this.city,
        plz: this.plz,
        owner: this.owner,
        iban: this.iban,
        birthday: this.birthday,
        signing: this.sign,
      };
      store.state.mail.status = "loading";
      sendMail(formData, "https://forum-neuenbuerg.de/getLive.php");
      $("#terminModal").modal("show");
    },

    async submitInfo() {
      try {
        await validateForm();
        this.getBirthday();
        if (!this.validAge) {
          document.getElementById("ageText").style.display = "block";
          return;
        }
        if (!this.saveSign()) {
          return;
        }
        this.startSending();
      } catch (error) {
        console.error("Error:", error);
      }
    },
  },
};
</script>

<style></style>
