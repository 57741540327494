<template>
  <header class="header-global">
    <nav class="navbar bg-dark navbar-dark" id="navbarCollapse">
      <a class="navbar-brand" href="#" style="width: 200px;">
        <router-link :to="{ name: 'startseite' }">
          <img
            src="@/assets/img/logos/Unbenannt-2.png"
            style="width: 95%; height: auto; margin-top: -5px;"
            class="d-inline-block align-top"
            alt=""
          />
        </router-link>
      </a>
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#collapsibleNavbar"
      >
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="collapsibleNavbar">
        <ul class="navbar-nav">
          <li class="nav-item active">
            <router-link
              :to="{ name: 'startseite' }"
              style="text-decoration: none;"
            >
              <a
                id="menu"
                class="nav-link"
                href="#"
                style="text-decoration: none;"
                >Das Forum<span class="sr-only">(current)</span></a
              >
            </router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{ name: 'ueberUns' }">
              <a
                id="menu"
                class="nav-link"
                href="#"
                style="text-decoration: none;"
                >Über uns</a
              >
            </router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{ name: 'karriere' }">
              <a
                id="menu"
                class="nav-link"
                href="#"
                style="text-decoration: none;"
                >Karriere</a
              >
            </router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{ name: 'kontakt' }">
              <a
                id="menu"
                class="nav-link "
                href="#"
                style="text-decoration: none;"
                >Kontakt</a
              >
            </router-link>
          </li>
          <li class="nav-item">
            <a
              id="menu"
              class="nav-link "
              href="https://live.forum-neuenbuerg.de"
              style="text-decoration: none;"
              >Live</a
            >
          </li>
        </ul>
        <hr />
        <div class="headerBtns">
          <img
            id="lebejetzt"
            src="@/assets/img/logos/lebejetzt.png"
            class="d-inline-block align-top"
            alt=""
          />
          <router-link :to="{ name: 'Membership' }">
            <button id="headerBtn" class="btn">Mitglied werden</button>
          </router-link>
        </div>
      </div>
    </nav>
    <nav class="navbar navbar-expand-lg bg-dark navbar-dark" id="navbarFull">
      <a class="navbar-brand" href="#" style="width: 200px;">
        <router-link :to="{ name: 'startseite' }">
          <img
            src="@/assets/img/logos/Unbenannt-2.png"
            style="width: 95%; height: auto; margin-top: -5px;"
            class="d-inline-block align-top"
            alt=""
          />
        </router-link>
      </a>
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#collapsibleNavbar"
      >
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="collapsibleNavbar">
        <ul class="navbar-nav">
          <li class="nav-item active">
            <router-link
              :to="{ name: 'startseite' }"
              style="text-decoration: none;"
            >
              <a
                id="menu"
                class="nav-link"
                href="#"
                style="text-decoration: none;"
                >Das Forum<span class="sr-only">(current)</span></a
              >
            </router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{ name: 'ueberUns' }">
              <a
                id="menu"
                class="nav-link"
                href="#"
                style="text-decoration: none;"
                >Über uns</a
              >
            </router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{ name: 'karriere' }">
              <a
                id="menu"
                class="nav-link"
                href="#"
                style="text-decoration: none;"
                >Karriere</a
              >
            </router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{ name: 'kontakt' }">
              <a
                id="menu"
                class="nav-link "
                href="#"
                style="text-decoration: none;"
                >Kontakt</a
              >
            </router-link>
          </li>
          <li class="nav-item">
            <a
              id="menu"
              class="nav-link "
              href="https://live.forum-neuenbuerg.de"
              style="text-decoration: none;"
              >Live</a
            >
          </li>
        </ul>
        <div class="headerBtns">
          <img
            id="lebejetzt"
            src="@/assets/img/logos/lebejetzt.png"
            class="d-inline-block align-top"
            alt=""
          />
          <router-link :to="{ name: 'Membership' }">
            <button id="headerBtn" class="btn">Mitglied werden</button>
          </router-link>
        </div>
      </div>
    </nav>
  </header>
</template>
<script src="https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>
<script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.16.0/umd/popper.min.js"></script>
<script src="https://maxcdn.bootstrapcdn.com/bootstrap/4.5.2/js/bootstrap.min.js"></script>
<script>
import BaseNav from "@/components/BaseNav";
import BaseDropdown from "@/components/BaseDropdown";
import CloseButton from "@/components/CloseButton";
import $ from "jquery";

export default {
  components: {
    BaseNav,
    CloseButton,
    BaseDropdown,
  },
  data() {
    return {
      show: true,
    };
  },
  methods: {
    toggleNavbar() {
      this.show = !this.show;
    },
  },
};
</script>
<style scoped>
#mg1 {
  margin: 0;
  position: absolute;
}

.navbar .navbar-collapse {
  text-align: center;
}

#collapsibleNavbar {
  width: 2000px;
}

@media only screen and (max-width: 1029.98px) {
  .navbar-collapse.collapsing,
  .navbar-collapse.show {
    padding: 0rem !important;
  }

  .navbar-nav {
    margin-top: 20px;
  }
}

.headerBtns {
  margin-right: 0;
  margin-left: auto;
}

@media only screen and (max-width: 1335px) {
  #lebejetzt {
    display: none !important;
  }
}

@media only screen and (min-width: 1164px) {
  #navbarCollapse {
    display: none;
  }

  #navbarFull {
    display: flex;
  }
}

@media only screen and (max-width: 1165px) {
  #navbarCollapse {
    display: flex;
  }

  #navbarFull {
    display: none;
  }
}

@media only screen and (max-width: 500px) {
  #mg1 {
    display: none !important;
  }

  #mg3 {
    display: flex !important;
  }
}

#lebejetzt {
  width: 170px;
  height: auto;
  padding-right: 20px;
  margin-top: -5px;
  -webkit-animation: rotate-in-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: rotate-in-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 4s both;
}

@-webkit-keyframes rotate-in-top {
  0% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
    -webkit-transform-origin: top;
    transform-origin: top;
    opacity: 0;
  }

  100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transform-origin: top;
    transform-origin: top;
    opacity: 1;
  }
}

@keyframes rotate-in-top {
  0% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
    -webkit-transform-origin: top;
    transform-origin: top;
    opacity: 0;
  }

  100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transform-origin: top;
    transform-origin: top;
    opacity: 1;
  }
}

#headerBtn {
  /* float: right; */
  color: white;
  background-color: #fbce3b;
  height: 50px;
  font-size: 15px;
  margin-left: auto;
  margin-right: auto;
}

#kuendigenBtn {
  color: white;
  background-color: grey;
  height: 50px;
  font-size: 15px;
  margin-left: 20px;
  margin-right: auto;
}

#menu {
  font-size: 20px;
}
</style>
