<template>
	<section class="section leistungen" id="leistungenContainer">
		<div class="row">
			<div class="col-sm d-flex justify-content-center text-center">
				<div id="title">
					<h2>UNSERE LEISTUNGEN</h2>
					<h4>DAMIT DU DEIN ZIEL ERREICHST</h4>
				</div>
			</div>
		</div>
		<LeistungDesktop></LeistungDesktop>
		<LeistungMobile></LeistungMobile>
		<LeistungMobileSmall></LeistungMobileSmall>
	</section>
</template>

<script>
  
	import LeistungDesktop from "./LeistungDesktop";
	import LeistungMobile from './LeistungMobile';
	import LeistungMobileSmall from './LeistungMobileSmall';
	export default {
		components: {
			LeistungDesktop,
			LeistungMobile,
			LeistungMobileSmall,
		
		 },
		data() {
			return {
			}
		},
		mounted() {
			AOS => AOS.init();
		},
		methods: {
		}
	}
</script>

<style>
 
	@media only screen and (max-width: 1340px) {
		.containerLeistungen{
	 padding-right: 20px;
 }
	}

	@media only screen and (max-width: 1280px) {
		.containerLeistungen{
	 padding-right: 40px;
 }
	}

	@media only screen and (max-width: 1240px) {
		.containerLeistungen{
	 padding-right: 60px;
 }
	}

	@media only screen and (max-width: 1250px) {
		#Leistung1,
		#Leistung2,
		#Leistung3{
			width: 300px!important;
		}
		.overlayLeistung{
			width: 300px!important;
			margin-top: -80px!important;
		}
		.containerLeistungen{
			padding-right: 0px;
		}
	}

	@media only screen and (max-width: 1030px) {
		.containerLeistungen{
	 padding-right: 20px;
 }
	}

	@media only screen and (max-width: 990px) {
		.containerLeistungen{
	 padding-right: 40px;
 }
	}

	@media only screen and (max-width: 910px) {
		#Leistung1,
		#Leistung2,
		#Leistung3{
			width: 250px!important;
		}
		.overlayLeistung{
			width: 250px!important;
			margin-top: -65px!important;
		}
		.containerLeistungen{
			padding-right: 0px;
		}
	}

	@media only screen and (max-width: 880px) {
		.containerLeistungen{
	 padding-right: 20px;
 }
	}

	@media only screen and (max-width: 930px) {
		.containerLeistungen{
	 padding-right: 40px;
 }
	}

@media only screen and (max-width: 790px) {
		.containerLeistungen{
	 padding-right: 60px;
 }
	}

	@media only screen and (max-width: 767px) {
		.containerLeistungen{
	 display: none;
 }
	}

	@media only screen and (min-width: 768px) {
		.containerLeistungenMobile{
	 display: none;
 }
	}

	@media only screen and (max-width: 576px) {
		.containerLeistungenMobile{
	 display: none;
 }
	}

	@media only screen and (min-width: 577px) {
		.containerLeistungenMobileSmall{
	 display: none;
 }
	}


	#kursBtn:hover{	
    background-color: #212529;
	color: white;
	transition: 0.3s;
  
}

	#kursBtn{
    outline-color: transparent;
    color: #212529;     
	background-color: #FBCE3B;
	font-family: Arial, Helvetica, sans-serif;
	font-weight: bolder;
	font-style: italic;    
	height: 50px;
	width: 200px;
	font-size: 15px;
	margin-left: 30px;
	}

	.detail_left {
		max-width: 100% !important;
	}

	.vue-lb-info{
  display: none!important;
}

	#Leistung1,
	#Leistung2,
	#Leistung3 {
		width: 400px;
	}


	.image-hover {
		position: absolute;
		top: 0;
		left: 0;
		opacity: 0;
		transition: opacity 1s ease-out;
	}

	.image-hover:hover {
		opacity: 1;
	}

	#closeBtn {
		position: absolute;
		top: 0px;
		right: 0px;
	}


	#closeBtn {
		background-color: white;
	}

	.detail {
		width: 100%;
		padding: 50px 0;
		margin-top: 0px;
		display: none;
	}

	.overlayLeistung:hover {
		cursor: pointer;
	}

	.overlayLeistung {
		width: 400px;
		/* margin-left: 15px!important; */
		position: absolute;
		margin-top: -121px;
		opacity: 1;
		/* left: 22.5%; */
		/* transition: 1s ease; */
	}



	#detail_1 {
		margin-top: -300px;
		display: none;
	}

	#detail_2 {
		margin-top: -301px;
		display: none;
	}

	#detail_3 {
		margin-top: -302px;

		display: none;
	}

	#detail_4 {
		margin-top: -300px;
		display: none;
	}

	#detail_5 {
		margin-top: -301px;
		display: none;
	}

	#detail_6 {
		margin-top: -302px;

		display: none;
	}

	.subtitle {
		color: #212529;
		font-family: noto-sans-display-extraconde, sans-serif;
		font-size: 60px;
		font-style: italic;
	}

	#title h2 {
		color: #212529;
		font-family: noto-sans-display-extraconde, sans-serif;
		font-size: 70px;
		font-style: italic;
	}

	#title h4 {
		color: #fbce3b;
		font-family: noto-sans-display-extraconde, sans-serif;
		font-size: 35px;
		font-style: italic;
		margin-top: -10px;
	}

	img.preview,
	img.modal-preview {
		opacity: 0.6;
	}

	img.active,
	.preview:hover,
	.modal-preview:hover {
		opacity: 1;
	}

	img.hover-shadow {
		transition: 0.3s;
	}

	.hover-shadow {
		width: 100%;
		height: auto;
		margin-top: 5px;
	}

	.hover-shadow:hover {
		box-shadow: 0 4px 8px 0 white, 0 6px 20px 0 white;
	}

	.close {
		color: white;
		position: absolute;
		top: 10px;
		right: 25px;
		font-size: 35px;
		font-weight: bold;
	}

	.close:hover,
	.close:focus {
		color: white;
		text-decoration: none;
		cursor: pointer;
	}

	#imgBtn {
		outline-color: transparent;
		color: #212529;
		background-color: #FBCE3B;
		font-family: Arial, Helvetica, sans-serif;
		font-weight: bolder;
		font-style: italic;
		height: 80px;
		width: 200px;
		font-size: 15px;
	}

  #imgBtn:hover {
		background-color: #212529;
		color: white;
		transition: 0.3s;

	}

	.previous,
	.next {
		cursor: pointer;
		position: absolute;
		top: 40%;
		width: auto;
		padding: 16px;
		margin-top: -50px;
		color: white;
		font-weight: bold;
		font-size: 20px;
		transition: 0.6s ease;
		border-radius: 0 3px 3px 0;
		user-select: none;
		-webkit-user-select: none;
	}

	.next {
		right: 0;
		border-radius: 3px 0 0 3px;
	}

	.previous:hover,
	.next:hover {
		background-color: rgba(0, 0, 0, 0.8);
	}

	html {
		box-sizing: border-box;
	}

	*,
	*:before,
	*:after {
		box-sizing: inherit;
	}

	body {
		margin: 0;
	}

	.preview {
		width: 100%;
	}

	.row {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}

	.row>.col {
		padding: 0 8px;
	}

	.col {
		float: left;
		width: 25%;
	}

	.modal {
		display: none;
		position: fixed;
		z-index: 1;
		padding: 10px 62px 0px 62px;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		overflow: auto;
		background-color: rgba(0, 0, 0, 0.8);
	}

	.modal-content {
		position: relative;
		display: flex;
		flex-direction: column;
		justify-content: center;
		margin: auto;
		padding: 0 0 0 0;
		width: 80%;
		max-width: 1200px;
	}

	.slide-lightbox {
		display: none;
		padding-top: 15px;
		background-color: #212529;
	}

	.image-slide {
		object-fit: cover;
		object-position: 100% 0;
		width: auto;
		height: 635px;
		display: block;
		margin: 0 auto;

		overflow: hidden;
	}

	.modal-preview {
		width: 100%;
	}

	.dots {
		padding-bottom: 25px;
		padding-top: 25px;
		padding-left: 15px;
		padding-right: 15px;
		display: flex;
		flex-direction: row;
		background-color: #212529;
	}

	#leistungThumbnail {
		position: absolute;
		width: 400px;
		cursor: pointer;
		margin-top: -0px !important;
	}

	#leistungThumbnailFrauen {
		position: absolute;
		width: 400px;
		cursor: pointer;
		margin-top: 50px;
	}

	#leistungThumbnailKurse {
		position: absolute;
		width: 400px;
		cursor: pointer;
		margin-top: 50px;
	}

	#leistungThumbnail {
		position: absolute;
		width: 400px;
		right: 10%;
		cursor: pointer;
		margin-top: 120px;
	}

	#leistungThumbnailBB {
		position: absolute;
		width: 400px;
		cursor: pointer;
		margin-top: 120px;
	}

	#leistungThumbnailPP {
		position: absolute;
		width: 400px;
		cursor: pointer;
		margin-top: 120px;
	}
</style>