export const media = [
  {
    thumb: require("@/assets/img/Kurse/IronCross/IronCross4.jpg"),
    src: require("@/assets/img/Kurse/IronCross/IronCross4.jpg"),
  },
  {
    thumb: require("@/assets/img/Kurse/IronCross/IronCross2.jpg"),
    src: require("@/assets/img/Kurse/IronCross/IronCross2.jpg"),
  },
  {
    thumb: require("@/assets/img/Kurse/IronCross/IronCross3.jpg"),
    src: require("@/assets/img/Kurse/IronCross/IronCross3.jpg"),
  },
];
