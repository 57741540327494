export const media = [
	{
		thumb: require('@/assets/img/Kurse/StrongRelax/StrongRelax1.jpg'),
		src: require('@/assets/img/Kurse/StrongRelax/StrongRelax1.jpg'),
	},
	{
		thumb: require('@/assets/img/Kurse/StrongRelax/StrongRelax2.jpg'),
		src: require('@/assets/img/Kurse/StrongRelax/StrongRelax2.jpg'),
	},
	{
		thumb: require('@/assets/img/Kurse/StrongRelax/StrongRelax3.jpg'),
		src: require('@/assets/img/Kurse/StrongRelax/StrongRelax3.jpg'),
	},
	{
		thumb: require('@/assets/img/Kurse/StrongRelax/StrongRelax4.jpg'),
		src: require('@/assets/img/Kurse/StrongRelax/StrongRelax4.jpg'),
	},
	{
		thumb: require('@/assets/img/Kurse/StrongRelax/StrongRelax5.jpg'),
		src: require('@/assets/img/Kurse/StrongRelax/StrongRelax5.jpg'),
	},
	{
		thumb: require('@/assets/img/Kurse/StrongRelax/StrongRelax6.jpg'),
		src: require('@/assets/img/Kurse/StrongRelax/StrongRelax6.jpg'),
	},
	{
		thumb: require('@/assets/img/Kurse/StrongRelax/StrongRelax7.jpg'),
		src: require('@/assets/img/Kurse/StrongRelax/StrongRelax7.jpg'),
	}
]