<template>
  <div class="containerMgfin">
    <section
      class="section-profile-cover section-shaped my-0"
      id="headerMGfin"
    ></section>
    <section class="section section-skew" style="margin-top: -150px;">
      <div class="container">
        <card
          shadow
          class="card-profile mt--300"
          no-body
          style="background-color: white;"
        >
          <div class="px-4">
            <div class="row ">
              <div class="col">
                <div class="mt-3">
                  <h2 id="agbtitle" style="color:#fbce3b">
                    SCHADE DASS DU UNS VERLÄSST!
                  </h2>
                </div>
              </div>
            </div>
            <div class="mt-3 py-3 border-top ">
              <div class="row " data-aos="fade-right">
                <div class="col-lg-12">
                  <p>
                    <strong
                      >Wir bieten eine große Trainingsvielfalt und wollen dich
                      natürlich bei deinen sportlichen Zielen unterstützen.
                      Fehlt dir etwas oder gibt es andere Gründe für deine
                      Entscheidung? Vielleicht können wir dir in einem
                      persönlichen Gespräch weiterhelfen? Denn Abschiede sind
                      nie schön, auch für uns nicht. Wir hoffen dich bald
                      wiederzusehen und wünschen dir alles Gute für deine
                      sportliche Zukunft!</strong
                    >
                  </p>
                  <div class="mt-3 py-3 border-top ">
                    <div class="row d-flex " style="margin-top: 0px;">
                      <form
                        style="width: 100%;"
                        class="needs-validation"
                        onsubmit="return false"
                      >
                        <p id="formHeading" data-aos="fade-right">
                          Wer bist du?
                        </p>
                        <div class="form-row" data-aos="fade-right">
                          <div class="form-group col-md-6">
                            <label for="inputVorname"></label>
                            <input
                              type="text"
                              class="form-control"
                              id="inputVorname"
                              placeholder="Vorname*"
                              required
                            />
                            <div class="invalid-feedback"></div>
                          </div>
                          <div class="form-group col-md-6">
                            <label for="inputNachname"></label>
                            <input
                              type="text"
                              class="form-control"
                              id="inputNachname"
                              placeholder="Nachname*"
                              required
                            />
                            <div class="invalid-feedback"></div>
                          </div>
                        </div>
                        <p id="formHeading" data-aos="fade-right">
                          Wie können wir dir antworten?
                        </p>
                        <div class="form-row" data-aos="fade-right">
                          <div class="form-group col-md-6">
                            <label for="inputMail"></label>
                            <input
                              type="text"
                              class="form-control"
                              id="inputMail"
                              placeholder="E-Mail*"
                              required
                            />
                          </div>
                          <div class="form-group col-md-6">
                            <label for="inputTel"></label>
                            <input
                              type="text"
                              class="form-control"
                              id="inputTel"
                              placeholder="Telefonnummer"
                            />
                          </div>
                        </div>
                        <p id="formHeading" data-aos="fade-right">
                          Warum möchtest du uns verlassen?
                        </p>
                        <div class="form-row" data-aos="fade-right">
                          <div class="form-group col-md-12">
                            <label for="inputMessage"></label>
                            <textarea
                              class="form-control"
                              rows="5"
                              id="inputMessage"
                              required
                              placeholder="Deine Nachricht*"
                            ></textarea>
                            <div class="invalid-feedback"></div>
                          </div>
                        </div>
                        <div
                          class="custom-control custom-checkbox"
                          style="margin-top: 30px;"
                        >
                          <input
                            class="custom-control-input"
                            type="checkbox"
                            value=""
                            id="agbCheck"
                            required
                          />
                          <div class="invalid-feedback"></div>
                          <label
                            class="custom-control-label"
                            for="agbCheck"
                            data-aos="fade-up"
                            style="font-size: 15px"
                            ><strong>
                              Ich habe die
                              <a
                                href="https://forum-neuenbuerg.de/#/AGB"
                                target="_blank"
                                ><strong
                                  >Allgemeinen Geschäftsbedingungen</strong
                                ></a
                              >
                              , sowie die
                              <a
                                href="https://forum-neuenbuerg.de/#/Datenschutzerklaerung"
                                target="_blank"
                              >
                                <strong>Datenschutzeinwilligung</strong>
                              </a>
                              gelesen und akzeptiere sie.</strong
                            >
                          </label>
                        </div>
                        <div
                          class="row justify-content-center text-center"
                          style="margin-top: 20px;"
                          data-aos="fade-up"
                        >
                          <input
                            value="Absenden"
                            id="submit"
                            type="submit"
                            class="btn btnColor2"
                            v-on:click="submitInfo()"
                          />
                        </div>
                      </form>
                    </div>
                  </div>
                  <h4 class="mt-2" style="color:#fbce3b">Öffnungszeiten</h4>
                  <div class="row">
                    <div class="col-lg-2">Mo-Fr</div>
                    <div class="col-lg-2">06-22 Uhr</div>
                  </div>
                  <div class="row">
                    <div class="col-lg-2">Sa,So</div>
                    <div class="col-lg-2">09-17 Uhr</div>
                  </div>
                  <div class="row">
                    <div class="col-lg-2">Feiertag</div>
                    <div class="col-lg-2">9-17 Uhr</div>
                  </div>
                  <div class="row">
                    <div class="col-lg-3">365 Tage geöffnet</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </card>
      </div>
    </section>
  </div>
</template>

<script>
import axios from "axios";
import $ from "jquery";
export default {
  data() {
    return {
      vorname: "",
      nachname: "",
      mail: "",
      telefon: "",
      nachricht: "",
      datenschutzConsent: false,
    };
  },
  methods: {
    loadInfo() {
      this.vorname = document.getElementById("inputVorname").value;
      this.nachname = document.getElementById("inputNachname").value;
      this.mail = document.getElementById("inputMail").value;
      this.telefon = document.getElementById("inputTel").value;
      this.nachricht = document.getElementById("inputMessage").value;
      this.datenschutzConsent = document.getElementById("agbCheck").checked;
    },
    checkInfo() {
      if (
        this.vorname == "" ||
        this.nachname == "" ||
        this.mail == "" ||
        this.telefon == "" ||
        this.nachricht == "" ||
        this.datenschutzConsent == false
      ) {
        return false;
      } else {
        return true;
      }
    },
    submitInfo() {
      this.loadInfo();
      const ref = this;
      if (this.checkInfo() == true) {
        axios
          .post("https://forum-neuenbuerg.de/kuendigung.php", {
            vorname: this.vorname,
            nachname: this.nachname,
            telefon: this.telefon,
            mail: this.mail,
            nachricht: this.nachricht,
          })
          .then(
            (response) => {
              if (response.data == "sent successully") {
                ref.$router.push("/Kuendigungabgeschlossen");
              }
            },
            (error) => {}
          );
      }
    },
  },
  mounted() {},
};
</script>

<style scoped>
#headerMGfin {
  background-image: url("../assets/img/logos/crossfit.jpg");
}

#kontaktButton {
  background-color: #fbce3b;
  border-color: transparent;
  width: 200px;
  font-size: 15px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bolder;
  font-style: italic;
}

#kontaktButton:hover {
  background-color: #212529;
  color: white;
  transition: 0.3s;
}
</style>
