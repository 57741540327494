export const pakete = [
  {
    id: 1,
    type: "basic",
    containerClass: "",
    imgSrc: require("@/assets/img/Mitgliedschaft/PaketBasic.svg"),
    title: "Paket Basic",
    btnClass: "btn btn-primary",
    description:
      "Das Paket Basic beinhaltet Gerätetraining und Kurse. Der Preis variiert je nach Laufzeitauswahl (12 Monate oder 24 Monate).",
    options: [
      {
        id: 1,
        label: "12 Monate",
        btnClass: "btn-primary",
        value: "basic12",
      },
      {
        id: 2,
        label: "24 Monate",
        btnClass: "btn-primary",
        value: "basic24",
      },
    ],
  },
  {
    id: 2,
    type: "fitness",
    containerClass: "row-background yellow-background",
    btnClass: "btn btn-light",
    imgSrc: require("@/assets/img/Mitgliedschaft/PaketFitness.svg"),
    title: "Paket Fitness",
    description:
      "Das Paket Fitness beinhaltet Gerätetraining und Kurse sowie bis zu drei Extras, die kostenfrei dazugebucht werden können. Der Preis variiert je nach Laufzeitauswahl (12 Monate oder 24 Monate).",
    options: [
      {
        id: 1,
        label: "12 Monate",
        btnClass: "btn-light",
        value: "fitness12",
      },
      {
        id: 2,
        label: "24 Monate",
        btnClass: "btn-light",
        value: "fitness24",
      },
    ],
  },
  {
    id: 3,
    type: "premium",
    containerClass: "",
    btnClass: "btn btn-primary",
    imgSrc: require("@/assets/img/Mitgliedschaft/PaketPremium.svg"),
    title: "Paket Premium",
    description:
      "Das Paket Premium beinhaltet alle buchbaren Leistungen exkl. Ballancer und Tiefenwärme. Der Preis variiert je nach Laufzeitauswahl (12 Monate oder 24 Monate).",
    options: [
      {
        id: 1,
        label: "12 Monate",
        btnClass: "btn-primary",
        value: "premium12",
      },
      {
        id: 2,
        label: "24 Monate",
        btnClass: "btn-primary",
        value: "premium24",
      },
    ],
  },
  {
    id: 6,
    type: "gold",
    btnClass: "btn btn-light",
    containerClass: "row-background yellow-background",
    imgSrc: require("@/assets/img/Mitgliedschaft/PaketGold.svg"),
    title: "Goldtarif",
    description:
      "Der Goldtarif beinhaltet Gerätetraining und Kurse sowie bis zu drei Extras, die kostenfrei dazugebucht werden können. Die Laufzeit beträgt 12 Monate und ist für alle Sportambitionierten über 60 Jahre.",
    options: [
      {
        id: 1,
        label: "12 Monate",
        btnClass: "btn-light",
        value: "gold",
      },
    ],
  },
  {
    id: 7,
    type: "zeit",
    btnClass: "btn btn-primary",
    containerClass: "row-background",
    imgSrc: require("@/assets/img/Mitgliedschaft/PaketZeit.svg"),
    title: "Geräte Zeiteingeschränkt",
    description:
      "Mit dem zeiteingeschränkten Tarif kannst du von Mo- Fr- von 12.00 - 17.00 Uhr an unseren Geräten trainieren. Die Laufzeit beträgt 12 Monate.",
    options: [
      {
        id: 1,
        label: "12 Monate",
        btnClass: "btn-primary",
        value: "zeit",
      },
    ],
  },
];

export const extras = [
  {
    img: require("@/assets/img/logos/extraGetraenke2.png"),
    text: "Getränke-Flatrate",
  },
  {
    img: require("@/assets/img/logos/extraDusche2.png"),
    text: "Duschen",
  },
  {
    img: require("@/assets/img/logos/extraSauna2.png"),
    text: "Sauna",
  },
  {
    img: require("@/assets/img/logos/extraPP2.png"),
    text: "Power Plate",
  },
  {
    img: require("@/assets/img/logos/extraSoli2.png"),
    text: "Solarium",
  },
  {
    img: require("@/assets/img/logos/extraMassage2.png"),
    text: "Massageliege",
  },
  {
    img: require("@/assets/img/logos/extraRolle2.png"),
    text: "Rolle- und Bandmassage",
  },
  {
    img: require("@/assets/img/logos/extraInfrarot2.png"),
    text: "Infrarotkabine",
  },
  {
    img: require("@/assets/img/logos/Ballancer.svg"),
    text: "Ballancer",
  },
  {
    img: require("@/assets/img/logos/Tiefenwaerme.svg"),
    text: "Tiefenwärme",
  },
];
