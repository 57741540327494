/*!

=========================================================
* Vue Argon Design System - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system/blob/master/LICENSE.md)

* Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import Argon from "./plugins/argon-kit";
import jQuery from "jquery";

import "bootstrap-icons/font/bootstrap-icons.css";
import "aos/dist/aos.css";
import axios from "axios";
import VueAxios from "vue-axios";
import vueScrollto from "vue-scrollto";
import Vuex from "vuex";
import VueSignaturePad from "vue-signature-pad";
import CoolLightBox from "vue-cool-lightbox";
import VueLazyLoad from "vue-lazyload";
import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";
import VueCryptojs from "vue-cryptojs";
import store from "./store";
import linkify from "vue-linkify";

Vue.directive("linkified", linkify);

Vue.use(VueCryptojs);

Vue.use(require("vue-cookies"));

import VueCookies from "vue-cookies";
Vue.use(VueCookies);

Vue.$cookies.config("7d");

Vue.use(CoolLightBox);

Vue.use(VueLazyLoad);

Vue.use(VueSignaturePad);

Vue.use(Vuex);

Vue.use(vueScrollto);

Vue.use(VueAxios, axios);

global.jQuery = jQuery;
let Bootstrap = require("bootstrap");
Vue.config.productionTip = false;
Vue.use(Argon);

new Vue({
  router,
  store: store,
  render: (h) => h(App),
  mounted() {
    import("aos").then((AOS) => AOS.init());
  },
}).$mount("#app");
