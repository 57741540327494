<template>
  <section class="aktuellesContainer">
    <Cookies id="cookies"></Cookies>
    <div class="row title">
      <h2>AKTUELLES</h2>
      <h4>BLEIB AUF DEM NEUSTEN STAND</h4>
    </div>
    <div class="row">
      <div class="col-sm-12 aktuelles-inhalt">
        <div class="btn-container">
          <div
            class="btn btn-primary"
            @click="loadContent('kursplan')"
            id="btn-kursplan"
          >
            <i class="bi bi-calendar-heart"></i>
            <span>&nbsp</span>Kursplan
          </div>
          <div class="btn btn-light" @click="loadContent('news')" id="btn-news">
            <i class="bi bi-info-circle-fill"></i>
            <span>&nbsp</span>News
          </div>
          <div
            class="btn btn-light"
            @click="loadContent('rueckblick')"
            id="btn-rueckblick"
          >
            <i class="bi bi-clock-history"></i>
            <span>&nbsp</span>Rückblick
          </div>
        </div>
        <img
          v-if="show_img_container"
          class="aktuelles-img"
          id="aktuelles-img"
          :src="img_URL + images[carousel_index]"
        />
        <img
          v-if="show_cookie_container"
          data-toggle="modal"
          style="cursor: pointer;"
          data-target="#CookieModal"
          class="aktuelles-img"
          id="aktuelles-img"
          src="@/assets/img/aktuellesCarousel/CookieHinweis.png"
        />
        <iframe
          type="text/html"
          v-if="show_yt_container"
          class="aktuelles-img aktuelles-yt"
          id="aktuelles-img"
          :src="images[carousel_index]"
          allowfullscreen
        />
        <img
          class="aktuelles-background"
          id="aktuelles-background"
          :src="backgrounds"
        />
      </div>
    </div>
    <div
      class="row"
      style="display: grid;padding-right: 100px;"
      id="news-index-container"
    >
      <div class="row progress">
        <div
          class="progress-bar"
          id="news-bar"
          role="progressbar"
          aria-valuenow="50"
          aria-valuemin="0"
          aria-valuemax="100"
        ></div>
      </div>
      <div class="col aktuelles-carousel-btn-container">
        <div class="row justify-content-center">
          <span id="carousel-index">
            {{ carousel_index + 1 }} / {{ carousel_max }}
          </span>
        </div>
        <div class="row">
          <i class="bi bi-arrow-left-circle" @click="changeView(-1)"></i>
          <i class="bi bi-arrow-right-circle" @click="changeView(+1)"></i>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Cookies from "../../components/Cookies";
import axios from "axios";
import CoolLightBox from "vue-cool-lightbox";
import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";
import $ from "jquery";

export default {
  components: {
    Cookies,
  },
  data: function() {
    return {
      images: [],
      backgrounds: require("@/assets/img/News/BG1.svg"),
      active_Content: "",
      content_list: ["news", "kursplan", "rueckblick"],
      carousel_index: 0,
      carousel_max: 0,
      show_img_container: true,
      show_cookie_container: false,
      show_yt_container: false,
      url: "",
      img_URL: "",
      show_img: false,
    };
  },
  mounted() {
    this.loadUrl();
    this.loadContent("kursplan");
    this.startProgressBar();
    this.startStopAutoplay();
  },
  methods: {
    startStopAutoplay() {
      var ref = this;
      var progress = document.getElementById("news-bar");

      document.getElementById("aktuelles-img").addEventListener(
        "mouseover",
        (event) => {
          progress.style.animationPlayState = "paused";
        },
        false
      );

      document.getElementById("aktuelles-img").addEventListener(
        "mouseleave",
        (event) => {
          progress.style.animationPlayState = "running";
        },
        false
      );
    },

    loadUrl() {
      if (window.location.href == "https://www.forum-neuenbuerg.de/#/") {
        this.url = "https://www.forum-neuenbuerg.de/";
        this.img_URL = "https://dashboard.forum-neuenbuerg.de/";
      } else {
        this.img_URL = "https://dashboard.forum-neuenbuerg.de/";
        this.url = "https://forum-neuenbuerg.de/";
      }
    },

    clearCollection() {
      this.images = [];
      this.carousel_index = 0;
      document
        .getElementById("news-bar")
        .classList.remove("progress-animation");
      document.getElementById("news-index-container").style.display = "none";
    },

    loadContent(content) {
      var aktuellesImg = $("#aktuelles-img");
      var background = $("#aktuelles-background");
      var newsIndexContainer = $("#news-index-container");
      let ref = this;
      newsIndexContainer.fadeOut(function() {
        background.fadeOut(function() {
          aktuellesImg.fadeOut(function() {
            ref.clearCollection();

            switch (content) {
              case "news":
                ref.active_Content = "news";
                background.css("bottom", "0px");
                ref.setBtn();
                axios
                  .get(
                    ref.url +
                      "action.php?username=web166&pw=BX79HmqzoxKNb4OY&request=aktuelles"
                  )
                  .then((response) => {
                    response.data.forEach((data) => {
                      if (data["name"] == "News") {
                        ref.images.push(data["link"]);
                      }
                    });
                    ref.carousel_max = ref.images.length;
                    ref.show_img = true;
                  });

                break;

              case "kursplan":
                ref.active_Content = "kursplan";
                background.css("bottom", "-30px");
                ref.setBtn();
                axios
                  .get(
                    ref.url +
                      "action.php?username=web166&pw=BX79HmqzoxKNb4OY&request=aktuelles"
                  )
                  .then((response) => {
                    response.data.forEach((data) => {
                      if (data["name"] == "Kursplan") {
                        ref.images.push(data["link"]);
                      }
                    });
                    ref.carousel_max = ref.images.length;
                    ref.show_img = true;
                  });
                break;

              case "rueckblick":
                ref.active_Content = "rueckblick";
                background.css("bottom", "0px");
                ref.setBtn();
                axios
                  .get(
                    ref.url +
                      "action.php?username=web166&pw=BX79HmqzoxKNb4OY&request=aktuelles"
                  )
                  .then((response) => {
                    response.data.forEach((data) => {
                      if (data["name"] == "Rückblick") {
                        ref.images.push(data["link"]);
                      }
                    });
                    ref.carousel_max = ref.images.length;
                    ref.show_img = true;
                  });
                break;

              default:
                break;
            }
          });
        });
      });

      setTimeout(function waitForElement() {
        if (ref.show_img) {
          ref.checkCookies();
          ref.show_img = false;
          aktuellesImg.fadeIn();
          setTimeout(function() {
            background.fadeIn();
          }, 500);
          setTimeout(function() {
            if (content == "news" || content == "rueckblick") {
              newsIndexContainer.fadeIn();
              document.getElementById("news-index-container").style.display =
                "grid";
              document
                .getElementById("news-bar")
                .classList.add("progress-animation");
            }
          }, 1000);
        } else {
          setTimeout(waitForElement, 250);
        }
      }, 1000);
    },

    checkCookies() {
      var ref = this;
      if (ref.images[ref.carousel_index].includes("embed")) {
        ref.show_img_container = false;
        if (ref.$cookies.isKey("user_session")) {
          ref.show_cookie_container = false;
        } else {
          ref.show_cookie_container = true;
        }
        if (ref.show_cookie_container == false) {
          ref.show_yt_container = true;
        }
      } else {
        ref.show_img_container = true;
        ref.show_yt_container = false;
        ref.show_cookie_container = false;
      }
    },

    setBtn() {
      document
        .getElementById("btn-" + this.active_Content)
        .classList.add("btn-primary");
      document
        .getElementById("btn-" + this.active_Content)
        .classList.remove("btn-light");

      this.content_list.forEach((content) => {
        if (content != this.active_Content) {
          document
            .getElementById("btn-" + content)
            .classList.remove("btn-primary");
          document.getElementById("btn-" + content).classList.add("btn-light");
        }
      });
    },

    changeView(i) {
      var animation = $("#aktuelles-img");
      var ref = this;
      animation.fadeOut(function() {
        document
          .getElementById("news-bar")
          .classList.remove("progress-animation");
        void document.getElementById("news-bar").offsetWidth;
        if (i < 0) {
          ref.carousel_index--;
          if (ref.carousel_index <= 0) {
            ref.carousel_index = ref.carousel_max;
          }
        } else {
          ref.carousel_index++;
        }

        if (ref.carousel_index >= ref.carousel_max) {
          ref.carousel_index = 0;
        }
        document.getElementById("news-bar").classList.add("progress-animation");

        ref.checkCookies();
      });

      animation.fadeIn();
    },

    startProgressBar() {
      var progress = document.getElementById("news-bar");
      var ref = this;
      progress.addEventListener("animationiteration", function() {
        ref.changeView(+1);
      });
    },

    data_to_array(data) {
      var array = [];
      for (var i = 0; i < data.length; i++) {
        var obj = data[i];
        array[i] = new Array();
        for (var key in obj) {
          array[i].push(obj[key]);
        }
      }
      return array;
    },
  },
};
</script>

<style>
.aktuellesContainer {
  background-image: url("../../assets/img/Startseite/Aktuelles BG.jpg");
  background-size: 100%;
  padding-bottom: 100px;
  padding-left: 10vw;
  padding-right: 10vw;
}

.aktuellesContainer #title {
  text-align: center;
}

.aktuellesContainer h2 {
  color: white;
}

.aktuellesContainer .btn-container {
  flex-direction: column;
  display: flex;
  grid-row: 1;
  justify-content: center;
  z-index: 3;
}

.aktuellesContainer .btn {
  width: 150px;
  margin: 20px;
  margin-left: auto;
  margin-right: 0.5rem;
}

.aktuelles-carousel-btn-container {
  color: #fcce3a;
  z-index: 5;
  grid-column: 8;
  grid-row: 2;
  font-size: 25px;
  margin-top: 20px;
  padding-right: 5vw;
}

.aktuelles-carousel-btn-container i {
  margin: 10px;
  margin-top: 0px;
  cursor: pointer;
  transition: 0.7s;
}

.aktuelles-carousel-btn-container i:hover {
  color: white;
}

.aktuelles-inhalt {
  text-align: center;
  justify-content: center;
  display: grid;
  position: relative;
}

.aktuelles-img {
  grid-column: 2 / span 8;
  grid-row: 1;
  z-index: 1;
  width: 80%;
  margin-left: 20px;
  background: transparent 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 30px #000000c1;
}

.aktuelles-background {
  grid-column: 2;
  grid-row: 1;
  position: absolute;
  left: 40px;
  width: 85%;
}

.aktuellesContainer .progress-animation {
  animation-name: progress-loading;
  animation-duration: 8s;
  animation-timing-function: ease-in;
  animation-iteration-count: infinite;
}

.aktuellesContainer .progress {
  grid-column: 2 / span 7;
  grid-row: 2;
  background-color: rgba(211, 211, 211, 0.458);
  width: 50%;
  margin: auto;
  height: 8px;
}

@keyframes progress-loading {
  from {
    width: 0;
  }

  to {
    width: 100%;
  }
}

@media screen and (max-width: 1000px) {
  .aktuelles-img,
  .aktuelles-background {
    grid-row: 2;
    grid-column: 1;
    width: 100%;
    margin: 0 -10px;
  }

  .aktuelles-background {
    bottom: -60px;
    left: 50px;
  }
  .aktuellesContainer .btn-container {
    grid-column: 1;
    flex-direction: row;
  }
}

@media screen and (max-width: 550px) {
  .aktuelles-img {
    grid-row: 2;
    grid-column: 1;
    width: 110%;
    margin: 0 -20px;
  }

  .aktuelles-background {
    bottom: -30px;
    left: 40px;
  }

  .aktuellesContainer .btn-container {
    grid-column: 1;
    flex-direction: column;
    margin: auto;
  }

  #news-index-container {
    display: block !important;
    padding-right: 0px !important;
    margin: auto;
    padding-bottom: 100px;
  }

  .aktuelles-carousel-btn-container i {
    font-size: 50px;
  }

  .aktuellesContainer .progress {
    grid-column: 5 / span 6;
    grid-row: 3;
    margin-top: 50px;
  }
}

.aktuelles-yt {
  width: 50vw;
  height: 600px;
}

@media screen and (max-width: 1300px) {
  .aktuelles-yt {
    width: 50vw;
    height: 400px;
  }
}

@media screen and (max-width: 1000px) {
  .aktuelles-yt {
    width: 80vw;
    height: 400px;
  }
}
</style>
