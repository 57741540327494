export const media = [{
    thumb: require('@/assets/img/Kurse/Stretch/Stretch1.png'),
    src: require('@/assets/img/Kurse/Stretch/Stretch1.png'),
  },
  {
    thumb: require('@/assets/img/Kurse/Stretch/Stretch2.png'),
    src: require('@/assets/img/Kurse/Stretch/Stretch2.png'),
  },
  {
    thumb: require('@/assets/img/Kurse/Stretch/Stretch3.png'),
    src: require('@/assets/img/Kurse/Stretch/Stretch3.png'),
  },
  {
    thumb: require('@/assets/img/Kurse/Stretch/Stretch4.png'),
    src: require('@/assets/img/Kurse/Stretch/Stretch4.png'),
  },
  {
    thumb: require('@/assets/img/Kurse/Stretch/Stretch5.png'),
    src: require('@/assets/img/Kurse/Stretch/Stretch5.png'),
  }
]