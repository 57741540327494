export const media = [{
		thumb: require('@/assets/img/Kurse/BBP/BBP1.jpg'),
		src: require('@/assets/img/Kurse/BBP/BBP1.jpg'),
	},
	{
		thumb: require('@/assets/img/Kurse/BBP/BBP2.jpg'),
		src: require('@/assets/img/Kurse/BBP/BBP2.jpg'),
	},
	{
		thumb: require('@/assets/img/Kurse/BBP/BBP3.jpg'),
		src: require('@/assets/img/Kurse/BBP/BBP3.jpg'),
	},
	{
		thumb: require('@/assets/img/Kurse/BBP/BBP4.jpg'),
		src: require('@/assets/img/Kurse/BBP/BBP4.jpg'),
	}
]