<template>
  <div class="grid">
    <div
      class="modal fade bd-example-modal-xl"
      tabindex="-1"
      id="PopUp"
      role="dialog"
      aria-labelledby="myExtraLargeModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-body" style="text-align: center">
            <img
              class="img-fluid"
              style="width: 100%; max-width: 800px;"
              :src="img_URL"
            />
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Okay!
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import axios from "axios";
export default {
  data() {
    return {
      url: "",
      img_URL: "",
    };
  },
  mounted() {
    this.loadUrl();
    this.getContent();
  },
  methods: {
    loadUrl() {
      if (window.location.href == "https://www.forum-neuenbuerg.de/#/") {
        this.url = "https://www.forum-neuenbuerg.de/";
        this.img_URL = "https://dashboard.forum-neuenbuerg.de/";
      } else {
        this.img_URL = "https://dashboard.forum-neuenbuerg.de/";
        this.url = "https://forum-neuenbuerg.de/";
      }
    },

    getContent() {
      axios
        .get(
          this.url +
            "action.php?username=web166&pw=BX79HmqzoxKNb4OY&request=aktuelles"
        )
        .then((response) => {
          response.data.forEach((data) => {
            if (data["name"] == "Popup") {
              this.img_URL += data["link"];
              $("#PopUp").modal("show");
            }
          });
        });
    },
  },
};
</script>

<style scoped>
.grid {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

ul {
  border: 1px solid gray;
  padding: 1rem 2rem;
  width: 200px;
}

li {
  text-align: left;
  margin: 0.5rem 0;
}

button.close {
  background-color: transparent !important;
}

.item {
  display: grid;
  grid-template-columns: 1fr 96px;
  width: 100%;
}
</style>
