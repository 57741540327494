export const media = [{
  thumb: require('@/assets/img/Kurse/Rücken/Rücken1.png'),
  src: require('@/assets/img/Kurse/Rücken/Rücken1.png'),
},
{
  thumb: require('@/assets/img/Kurse/Rücken/Rücken2.png'),
  src: require('@/assets/img/Kurse/Rücken/Rücken2.png'),
},
{
  thumb: require('@/assets/img/Kurse/Rücken/Rücken3.png'),
  src: require('@/assets/img/Kurse/Rücken/Rücken3.png'),
},
{
  thumb: require('@/assets/img/Kurse/Rücken/Rücken4.png'),
  src: require('@/assets/img/Kurse/Rücken/Rücken4.png'),
},
{
  thumb: require('@/assets/img/Kurse/Rücken/Rücken5.png'),
  src: require('@/assets/img/Kurse/Rücken/Rücken5.png'),
},
{
  thumb: require('@/assets/img/Kurse/Rücken/Rücken6.png'),
  src: require('@/assets/img/Kurse/Rücken/Rücken6.png'),
},
]