<template>
  <div class="profile-page" style="background-color: #F2F2F2;">
    <section
      class="section-profile-cover section-shaped my-0"
      id="header"
    ></section>
    <section class="section section-skew sectionAGB">
      <div class="container">
        <card
          shadow
          class="card-profile mt--300"
          no-body
          style="background-color: white;"
        >
          <div class="px-4">
            <div class="row ">
              <div class="col">
                <div class="mt-3">
                  <h2 id="agbtitle" style="color:#fbce3b">
                    ALLGEMEINE GESCHÄFTBEDINGUNGEN
                  </h2>
                </div>
              </div>
              <div class="col">
                <router-link :to="{ name: 'kontakt' }">
                  <base-button
                    id="kontaktButton"
                    type="default"
                    size="sm"
                    class="float-right mt-4"
                    >Kontakt</base-button
                  >
                </router-link>
              </div>
            </div>
            <div class="mt-3 py-3 border-top ">
              <div class="row " data-aos="fade-right">
                <div class="col-lg-12">
                  <p>
                    Im Folgenden werden die Allgemeinen Geschäftsbedingungen
                    (AGB) der Frank Reuck und Clement Deeg GbR erläutert.
                  </p>
                  <p>
                    Sollten unterschiedliche Regelungen im Vertrag, den AGB und
                    der Hausordnung getroffen worden sein, so gelten in erster
                    Linie die im Vertrag getroffenen Bedingungnen, sodann die
                    Regelungen der AGB, die wiederum die Hausordnung außer Kraft
                    setzen.
                  </p>
                  <h5 style="color:#fbce3b;">Adressen- oder Bankänderung</h5>
                  <p>
                    Änderungen der Anschrift, bei Bankeinzug auch Kontoänderung,
                    sind dem Fitnessstudio unverzüglich mitzuteilen.
                  </p>
                  <h5 style="color:#fbce3b;">Verzug des Beitrages</h5>
                  <p>
                    Befindet sich der Vertragspartner mit der Zahlung des
                    Beitrages <strong>schuldhaft</strong> im Verzug, werden für
                    <strong>jede notwendige Mahnung 3,00 € berechnet</strong>.
                    Dem Mitglied bleibt der Nachweis gestattet, dass kein oder
                    ein geringfügiger Schaden entstanden ist. Für
                    Bearbeitungsgebühren seitens der Bank des Vertragspartners
                    und dessen Höhe ist die Frank Reuck und Clement Deeg GbR
                    nicht verantwortlich.
                  </p>
                  <h5 style="color:#fbce3b;">Pausierung der Mitgliedschaft</h5>
                  <p>
                    Bei Klinikum Aufenthalt, Schwangerschaft oder
                    Geschäftsreisen kann die Mitgliedschaft pausiert werden.
                    Dieser Ausfall muss bis zum 25. des Vormonates beim Fitness-
                    und Gesundheitsforum eingegangen sein. Die Dauer der
                    ausgefallenen Zeit muss mindestens einen Monat andauern und
                    kann nicht länger als 6 Monate eingetragen werden. Die
                    Laufzeit der Mitgliedschaft verlängert sich entsprechend um
                    die angegebene Ruhezeit.
                  </p>
                  <h5 style="color:#fbce3b;">Haftungsauschluss</h5>
                  <p>
                    Eine Haftung des Studios für den Verlust oder die
                    Beschädigung mitgebrachter Kleidung, Sachen, Wertgegenstände
                    und Geld wird ausgeschlossen, es sei denn, der Verlust oder
                    die Beschädigung ist auf grob fahrlässiges oder
                    vorsätzliches Verhalten des Studios zurückzuführen. Das
                    Studio haftet nicht für selbstverschuldete Unfälle.
                  </p>
                  <h5 style="color:#fbce3b;">Kündigung</h5>
                  <p>
                    Der Mitgliedschaftsvertrag ist von beiden Seiten mit einer
                    Frist von 3 Monaten (bzw. 1 Monat bei monatlicher Laufzeit)
                    zum Ende der Erstlaufzeit kündbar. Jede Kündigung hat in
                    Textform zu erfolgen. Erfolgt keine fristgemäße Kündigung,
                    verlängert sich der Mitgliedschaftsvertrag um jeweils
                    weitere 6 Monate (bzw. 1 Monat bei monatlicher Laufzeit).
                    Die Kündigungsfrist beträgt dann für beide Seiten 2 Monate
                    (bzw. 1 Monat bei monatlicher Laufzeit). Das Recht zur
                    außerordentlichen Kündigung bleibt hiervon unberührt.
                  </p>
                  <h5 style="color:#fbce3b;">Hausordnung</h5>
                  <ol>
                    <li>
                      <p>
                        <strong
                          >Geltungsbereich und Zweck der Hausordnung</strong
                        >
                      </p>
                      <p>
                        Diese Ordnung gilt für die Einrichtung des Fitness- und
                        Gesundheitsforums, Frank Reuck und Clement Deeg GbR. Sie
                        hat den Zweck, den Mitgliedern einnen angenehmen und
                        erholsamen Aufenthalt zu ermöglichen.
                      </p>
                    </li>
                    <li>
                      <p><strong>Aushang</strong></p>
                      <p>
                        Die Öffnungszeiten des Studios werden durch den Aushang
                        bekannt gegeben.
                      </p>
                    </li>
                    <li>
                      <p><strong>Verhalten des Besuchers</strong></p>
                      <ol style="margin-left: -1.5em;">
                        <li id="listVerhalten">
                          Die Besucher haben sich so zu verhalten, dass
                          Sicherheit, Sauberkeit und Ordnung gewährleistet sind
                          und andere Besucher nicht gestört oder belästigt
                          werden.
                        </li>
                        <li id="listVerhalten">
                          Alle Anlagen, Einrichtungen und Geräte sind pfleglich
                          zu behandeln. Festgestellte Schäden sind unverzüglich
                          zu melden.
                        </li>
                        <li id="listVerhalten">
                          Der Verzehr von Lebensmitteln ist untersagt bzw. nur
                          im Bistro-Bereich gestattet.
                        </li>
                        <li id="listVerhalten">
                          Das Rauchen in der Fitness-Anlage ist untersagt.
                        </li>
                        <li id="listVerhalten">
                          Bitte schalten Sie Ihr Handy während des Aufenthaltes
                          lautlos oder schalten Sie es aus.
                        </li>
                      </ol>
                    </li>
                    <br />
                    <li>
                      <p><strong>Verhalten des Besuchers</strong></p>
                      <p>
                        Jeder Besucher hat sich vor Benutzung der Sauna zu
                        duschen.
                      </p>
                    </li>
                    <li>
                      <p><strong>Garderoben</strong></p>
                      <p>
                        Zur Verfügung gestellte Garderobenschlüssel/-transponder
                        bitte dem Personal beim Verlassen des Hauses wieder
                        aushändigen. Bei Verlust wird anderenfalls eine
                        Kostenerstattung in Höhe von 19,99 € erhoben.
                      </p>
                    </li>
                    <li>
                      <p><strong>Bekleidung in der Anlage</strong></p>
                      <p>
                        Die Trainingsräume und Trainingsflächen sind nur in
                        entsprechender Kleidung zu betreten. Dazu zählen sowohl
                        Trainingsbekleidung, als auch saubere Sportschuhe. Aus
                        hygienischen Gründen ist das Training nur mit
                        bekleidetem Oberkörper gestattet.<br />Die Nutzung der
                        Sauna ist nur unbekleidet erlaubt.<br />
                        Um den hohen hygienischen Standard in unserer Anlage zu
                        gewährleisten, sind die Geräte, sowie Liegeflächen vor
                        der Benutzung mit einem sauberen Handtuch zu bedecken,
                        sodass keine Körperflüssigkeiten auf die Auflageflächen
                        geraten können.
                      </p>
                    </li>
                    <li>
                      <p><strong>Ordnung im Freihantelbereich</strong></p>
                      <p>
                        Hanteln und Gewichte sind nach Verlassen der jeweiligen
                        Übung/Geräte wieder zu den dafür vorgesehenen
                        Ablagemöglichkeiten zurückzubringen.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong
                          >Folgen bei Verstößen gegen die Hausordnung</strong
                        >
                      </p>
                      <p>
                        Besucher der Fitness-Anlage, die diesen Bestimmungen
                        zuwiderhandeln oder sonst die Ordnung stören, müssen das
                        Haus nach Aufforderung durch das diensthabende Personal
                        sofort verlassen.
                      </p>
                    </li>
                  </ol>
                  <h4 class="mt-2" style="color:#fbce3b">Öffnungszeiten</h4>
                  <div class="row">
                    <div class="col-lg-2">Mo-Fr</div>
                    <div class="col-lg-2">06-22 Uhr</div>
                  </div>
                  <div class="row">
                    <div class="col-lg-2">Sa,So</div>
                    <div class="col-lg-2">09-17 Uhr</div>
                  </div>
                  <div class="row">
                    <div class="col-lg-2">Feiertag</div>
                    <div class="col-lg-3">9-17 Uhr</div>
                  </div>
                  <div class="row">
                    <div class="col-lg-3">365 Tage geöffnet</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </card>
      </div>
    </section>
  </div>
</template>

<script style="background-color: #F2F2F2;">
export default {
  name: "Agb.vue",
};
</script>

<style scoped>
@media only screen and (max-width: 767px) {
  #kontaktButton {
    width: 100% !important;
    margin-top: 20px !important;
  }
}

@media only screen and (max-width: 457px) {
  #agbtitle {
    font-size: 25px;
  }
}

#listVerhalten {
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.7;
}
.sectionAGB p {
  font-weight: 400;
}

#header {
  background-image: url("../assets/img/logos/crossfit.jpg");
}
#kontaktButton {
  background-color: #fbce3b;
  border-color: transparent;
  width: 200px;
  font-size: 15px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bolder;
  font-style: italic;
}
#kontaktButton:hover {
  background-color: #212529;
  color: white;
  transition: 0.3s;
}
</style>
