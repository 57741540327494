<template>
  <section class="section ZeitgraphContainer">
    <div class="row">
      <div class="col-sm d-flex justify-content-center text-center">
        <div id="title" style="margin-top:0px; padding-bottom: 20px;">
          <h2>BELIEBTE TRAININGSZEITEN</h2>
          <h4>TRAINING OHNE STRESS</h4>
        </div>
      </div>
    </div>
    <div class="chart-container" id="graphContainer">
      <canvas id="graphDay"></canvas>
    </div>
    <div
      class="row d-flex justify-content-center text-center"
      style="margin-top:-5%"
      id="graphBtnRow_1"
    >
      <div class="col col-sm-1 colBtnGraph">
        <button id="day0" v-on:click="loadGraph(0)" class="btn btnGraph">
          MONTAG
        </button>
      </div>
      <div class="col col-sm-1 colBtnGraph">
        <button id="day1" v-on:click="loadGraph(1)" class="btn btnGraph">
          DIENSTAG
        </button>
      </div>
      <div class="col col-sm-1 colBtnGraph">
        <button id="day2" v-on:click="loadGraph(2)" class="btn btnGraph">
          MITTWOCH
        </button>
      </div>
      <div class="col col-sm-1 colBtnGraph">
        <button id="day3" v-on:click="loadGraph(3)" class="btn btnGraph">
          DONNERSTAG
        </button>
      </div>
      <div class="col col-sm-1 colBtnGraph">
        <button id="day4" v-on:click="loadGraph(4)" class="btn btnGraph">
          FREITAG
        </button>
      </div>
      <div class="col col-sm-1 colBtnGraph">
        <button id="day5" v-on:click="loadGraph(5)" class="btn btnGraph">
          SAMSTAG
        </button>
      </div>
      <div class="col col-sm-1 colBtnGraph">
        <button id="day6" v-on:click="loadGraph(6)" class="btn btnGraph">
          SONNTAG
        </button>
      </div>
    </div>

    <div
      class="row d-flex justify-content-center text-center"
      style="margin-top:-7.5%; margin-left: 10%; margin-right: 10%;"
      id="graphBtnRow_2_1"
    >
      <div class="col col-sm-2 colBtnGraph">
        <button id="day0_2" v-on:click="loadGraph(0)" class="btn btnGraph">
          MONTAG
        </button>
      </div>
      <div class="col col-sm-2 colBtnGraph">
        <button id="day1_2" v-on:click="loadGraph(1)" class="btn btnGraph">
          DIENSTAG
        </button>
      </div>
      <div class="col col-sm-2 colBtnGraph">
        <button id="day2_2" v-on:click="loadGraph(2)" class="btn btnGraph">
          MITTWOCH
        </button>
      </div>
      <div class="col col-sm-2 colBtnGraph">
        <button id="day3_2" v-on:click="loadGraph(3)" class="btn btnGraph">
          DONNERSTAG
        </button>
      </div>
      <div class="col col-sm-2 colBtnGraph">
        <button id="day4_2" v-on:click="loadGraph(4)" class="btn btnGraph">
          FREITAG
        </button>
      </div>
      <div class="col col-sm-2 colBtnGraph">
        <button id="day5_2" v-on:click="loadGraph(5)" class="btn btnGraph">
          SAMSTAG
        </button>
      </div>
      <div class="col col-sm-2 colBtnGraph">
        <button id="day6_2" v-on:click="loadGraph(6)" class="btn btnGraph">
          SONNTAG
        </button>
      </div>
    </div>

    <div
      class="row d-flex justify-content-center text-center"
      style="margin-top:-5%; width: 10%"
      id="graphBtnRow_2"
    >
      <div class="col col-sm-2 colBtnGraph">
        <button id="day1_2" v-on:click="loadGraph(1)" class="btn btnGraph">
          MONTAG
        </button>
      </div>
      <div class="col col-sm-2 colBtnGraph">
        <button id="day2_2" v-on:click="loadGraph(2)" class="btn btnGraph">
          DIENSTAG
        </button>
      </div>
      <div class="col col-sm-2 colBtnGraph">
        <button id="day3_2" v-on:click="loadGraph(3)" class="btn btnGraph">
          MITTWOCH
        </button>
      </div>
      <div class="col col-sm-2 colBtnGraph">
        <button id="day4_2" v-on:click="loadGraph(4)" class="btn btnGraph">
          DONNERSTAG
        </button>
      </div>
      <div class="col col-sm-2 colBtnGraph">
        <button id="day5_2" v-on:click="loadGraph(5)" class="btn btnGraph">
          FREITAG
        </button>
      </div>
      <div class="col col-sm-2 colBtnGraph">
        <button id="day6_2" v-on:click="loadGraph(6)" class="btn btnGraph">
          SAMSTAG
        </button>
      </div>
      <div class="col col-sm-2 colBtnGraph">
        <button id="day7_2" v-on:click="loadGraph(7)" class="btn btnGraph">
          SONNTAG
        </button>
      </div>
    </div>
  </section>
</template>

<script>
import Chart from "chart.js";
import TimeData from "./TimeData.js";

export default {
  data() {
    return {
      chart: null,
    };
  },
  mounted() {
    this.chart = new Chart(
      document.getElementById("graphDay"),
      TimeData(0, this.getGradient())[0]
    );
  },
  methods: {
    getGradient() {
      const ctx = document.getElementById("graphDay").getContext("2d");
      const gradient = ctx.createLinearGradient(0, 0, 0, 400);
      gradient.addColorStop(0, "rgba(250,174,50,1)");
      gradient.addColorStop(1, "rgba(250,174,50,0.1)");
      return gradient;
    },

    loadGraph(day) {
      this.changeButtonStyle(day);
      this.chart.destroy();
      this.chart = new Chart(
        document.getElementById("graphDay"),
        TimeData(day, this.getGradient())[0]
      );
    },

    changeButtonStyle(day) {
      for (var i = 0; i < 7; i++) {
        var button = document.getElementById("day" + i);
        var button2 = document.getElementById("day" + i + "_2");
        if (i == day) {
          button.style.cssText =
            "background-color: white; border: 3px solid #FBCE3B; color: #FBCE3B;";
          button2.style.cssText =
            "background-color: white; border: 3px solid #FBCE3B; color: #FBCE3B;";
        } else {
          button.style.cssText =
            "background-color: #FBCE3B; border: 3px solid transparent; color: #212529;";
          button2.style.cssText =
            "background-color: #FBCE3B; border: 3px solid transparent; color: #212529;";
        }
      }
    },
  },
};
</script>

<style>
.chart-container {
  position: relative;
  margin: auto;
  margin-bottom: 7.5%;
  width: 60vw;
}

.btnGraph {
  color: #212529;
  background-color: #fbce3b;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bolder;
  font-style: italic;
  height: 50px;
  width: 150px;
  font-size: 15px;
}

@media only screen and (max-width: 1400px) {
  #graphBtnRow_1 {
    display: none !important;
  }

  #graphBtnRow_2 {
    width: 100% !important;
  }
}

@media only screen and (min-width: 1401px) {
  #graphBtnRow_2_1 {
    display: none !important;
  }
}

@media only screen and (max-width: 1000px) {
  #graphBtnRow_2_1 {
    display: none !important;
  }
}

@media only screen and (min-width: 1001px) {
  #graphBtnRow_2 {
    display: none !important;
  }
}

@media only screen and (max-width: 800px) {
  .btnGraph {
    width: 130px;
    font-size: 12px !important;
  }

  .chart-container {
    width: 80vw;
  }
}

@media only screen and (max-width: 575px) {
  #graphBtnRow_2 {
    width: auto !important;
  }

  .chart-container {
    width: 90vw;
  }
}

.colBtnGraph {
  margin: 20px;
}
</style>
