<template>
  <section class="section faqContainter" id="faqContainer">
    <div class="container">
      <div class="row">
        <div class="col-sm">
          <div id="title">
            <h2>FAQ</h2>
            <h4>DU HAST FRAGEN?</h4>
          </div>
          <div
            class="accordion"
            id="accordionExample"
            data-aos="fade-down"
            style="padding-bottom: 20px;"
          >
            <div class="card">
              <div class="card-header" id="headingOne">
                <h2 class="mb-0">
                  <button
                    class="btn btn-link btn-block text-left"
                    type="button"
                    data-toggle="collapse"
                    data-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    <p id="faqButtonText">Wie sind eure Öffnungszeiten?</p>
                  </button>
                </h2>
              </div>

              <div
                id="collapseOne"
                class="collapse show"
                aria-labelledby="headingOne"
                data-parent="#accordionExample"
              >
                <div class="card-body">
                  <ul>
                    <li>Montag bis Freitag von 06:00 – 22.00 Uhr</li>
                    <li>Samstag und Sonntag von 09:00 – 17:00 Uhr</li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="card-header" id="headingTwo">
                <h2 class="mb-0">
                  <button
                    class="btn btn-link btn-block text-left collapsed"
                    id="faqButtonText"
                    type="button"
                    data-toggle="collapse"
                    data-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    <span id="desktop"
                      >Ab wie vielen Jahren kann man im Fitness- und
                      Gesundheitsforum Neuenbürg trainieren?</span
                    >
                  </button>
                </h2>
              </div>
              <div
                id="collapseTwo"
                class="collapse"
                aria-labelledby="headingTwo"
                data-parent="#accordionExample"
              >
                <div class="card-body">
                  Generell darfst du ab 18 Jahren bei uns trainieren.<br />
                </div>
              </div>
            </div>
            <div class="card">
              <div class="card-header" id="headingThree">
                <h2 class="mb-0">
                  <button
                    class="btn btn-link btn-block text-left collapsed"
                    type="button"
                    id="faqButtonText"
                    data-toggle="collapse"
                    data-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                  >
                    Was ist wenn ich unter 18 Jahren bin?
                  </button>
                </h2>
              </div>
              <div
                id="collapseThree"
                class="collapse"
                aria-labelledby="headingThree"
                data-parent="#accordionExample"
              >
                <div class="card-body">
                  Mit Einverständnis der Erziehungsberechtigten und mit einer
                  Einweisung eines Trainers darfst du bei uns trainieren.
                </div>
              </div>
            </div>
            <div class="card">
              <div class="card-header" id="headingThree">
                <h2 class="mb-0">
                  <button
                    class="btn btn-link btn-block text-left collapsed"
                    type="button"
                    id="faqButtonText"
                    data-toggle="collapse"
                    data-target="#collapse4"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                  >
                    Kann ich den Vertrag meines Freundes / Familienmitglieds für
                    einen bestimmten Zeitraum<br />leihen?
                  </button>
                </h2>
              </div>
              <div
                id="collapse4"
                class="collapse"
                aria-labelledby="headingThree"
                data-parent="#accordionExample"
              >
                <div class="card-body">
                  Nein, aber du kannst eine 10er- oder Tageskarte bei uns im
                  Studio erwerben.
                </div>
              </div>
            </div>
            <div class="card">
              <div class="card-header" id="headingThree">
                <h2 class="mb-0">
                  <button
                    class="btn btn-link btn-block text-left collapsed"
                    type="button"
                    id="faqButtonText"
                    data-toggle="collapse"
                    data-target="#collapse5"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                  >
                    Kann ich meinen Vertrag an einen Freund / Familienmitglied
                    übergeben?
                  </button>
                </h2>
              </div>
              <div
                id="collapse5"
                class="collapse"
                aria-labelledby="headingThree"
                data-parent="#accordionExample"
              >
                <div class="card-body">
                  Eine bestehende Mitgliedschaft kann übergeben werden.<br />
                  Melde dich dafür mit deinem Freund / Familienmitglied bei uns
                  an der Theke. Wir informieren euch dann gerne über alles
                  weitere.
                </div>
              </div>
            </div>
            <div class="card">
              <div class="card-header" id="headingThree">
                <h2 class="mb-0">
                  <button
                    class="btn btn-link btn-block text-left collapsed"
                    type="button"
                    id="faqButtonText"
                    data-toggle="collapse"
                    data-target="#collapse6"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                  >
                    Ist das Probetraining kostenlos?
                  </button>
                </h2>
              </div>
              <div
                id="collapse6"
                class="collapse"
                aria-labelledby="headingThree"
                data-parent="#accordionExample"
              >
                <div class="card-body">
                  Das Probetraining mit Trainer kostet 19.99€ und wird bei
                  Abschluss einer Mitgliedschaft mit der Anmeldegebühr
                  verrechnet. Ein Probetraining ohne Trainer kostet 14.99 €.
                </div>
              </div>
            </div>
            <div class="card">
              <div class="card-header" id="headingThree">
                <h2 class="mb-0">
                  <button
                    class="btn btn-link btn-block text-left collapsed"
                    type="button"
                    id="faqButtonText"
                    data-toggle="collapse"
                    data-target="#collapse7"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                  >
                    Welche Zahlungsmöglichkeiten gibt es?
                  </button>
                </h2>
              </div>
              <div
                id="collapse7"
                class="collapse"
                aria-labelledby="headingThree"
                data-parent="#accordionExample"
              >
                <div class="card-body">
                  Bei uns ist nur Sepa Lastschrift möglich.
                </div>
              </div>
            </div>
            <div class="card">
              <div class="card-header" id="headingThree">
                <h2 class="mb-0">
                  <button
                    class="btn btn-link btn-block text-left collapsed"
                    type="button"
                    id="faqButtonText"
                    data-toggle="collapse"
                    data-target="#collapse8"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                  >
                    Wie kann ich meinen Vertrag kündigen?
                  </button>
                </h2>
              </div>
              <div
                id="collapse8"
                class="collapse"
                aria-labelledby="headingThree"
                data-parent="#accordionExample"
              >
                <div class="card-body">
                  Die Kündigung muss in schriftlicher Form bei uns per Post, vor
                  Ort oder per Mail eingehen.
                </div>
              </div>
            </div>
            <div class="card">
              <div class="card-header" id="headingThree">
                <h2 class="mb-0">
                  <button
                    class="btn btn-link btn-block text-left collapsed"
                    type="button"
                    id="faqButtonText"
                    data-toggle="collapse"
                    data-target="#collapse9"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                  >
                    Kann ich meinen Vertrag stilllegen?
                  </button>
                </h2>
              </div>
              <div
                id="collapse9"
                class="collapse"
                aria-labelledby="headingThree"
                data-parent="#accordionExample"
              >
                <div class="card-body">
                  Grundsätzlich kann der Vertrag nicht stillgelegt werden. Kann
                  man jedoch aus Gesundheitlichen Gründen nicht trainieren
                  kommen, kann der Vertrag maximal für sechs Monate, jedoch
                  mindestens für einen Monat stillgelegt werden.
                </div>
              </div>
            </div>
            <div class="card">
              <div class="card-header" id="headingThree">
                <h2 class="mb-0">
                  <button
                    class="btn btn-link btn-block text-left collapsed"
                    type="button"
                    id="faqButtonText"
                    data-toggle="collapse"
                    data-target="#collapse10"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                  >
                    Muss ich für ein Probetraining ein Termin vereinbaren?
                  </button>
                </h2>
              </div>
              <div
                id="collapse10"
                class="collapse"
                aria-labelledby="headingThree"
                data-parent="#accordionExample"
              >
                <div class="card-body">
                  Wenn das Training mit einer Einweisung in die Geräte sein
                  soll, dann ja, ansonsten kannst du jederzeit vorbei kommen.
                </div>
              </div>
            </div>
            <div class="card">
              <div class="card-header" id="headingThree">
                <h2 class="mb-0">
                  <button
                    class="btn btn-link btn-block text-left collapsed"
                    type="button"
                    id="faqButtonText"
                    data-toggle="collapse"
                    data-target="#collapse11"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                  >
                    Wie kann ich meine Mitgliedsdaten wie Adresse, Email oder
                    Bankverbindung ändern?
                  </button>
                </h2>
              </div>
              <div
                id="collapse11"
                class="collapse"
                aria-labelledby="headingThree"
                data-parent="#accordionExample"
              >
                <div class="card-body">
                  Um deine Daten zu ändern, benötigen wir eine schriftliche
                  Änderung per Mail oder vor Ort.
                </div>
              </div>
            </div>
            <div class="card">
              <div class="card-header" id="headingThree">
                <h2 class="mb-0">
                  <button
                    class="btn btn-link btn-block text-left collapsed"
                    type="button"
                    id="faqButtonText"
                    data-toggle="collapse"
                    data-target="#collapse12"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                  >
                    Was mache ich, wenn ich meinen Transponder verloren habe?
                  </button>
                </h2>
              </div>
              <div
                id="collapse12"
                class="collapse"
                aria-labelledby="headingThree"
                data-parent="#accordionExample"
              >
                <div class="card-body">
                  Bei uns im Studio kannst du an der Theke einen neuen für 9.99€
                  erwerben und beschreiben lassen.
                </div>
              </div>
            </div>
            <div class="card">
              <div class="card-header" id="headingThree">
                <h2 class="mb-0">
                  <button
                    class="btn btn-link btn-block text-left collapsed"
                    type="button"
                    id="faqButtonText"
                    data-toggle="collapse"
                    data-target="#collapse13"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                  >
                    Was mache ich, wenn ich meinen Transponder vergessen habe?
                  </button>
                </h2>
              </div>
              <div
                id="collapse13"
                class="collapse"
                aria-labelledby="headingThree"
                data-parent="#accordionExample"
              >
                <div class="card-body">
                  Du kannst bei uns an der Theke ein Leihtransponder gegen Pfand
                  eintauschen.
                </div>
              </div>
            </div>
            <div class="card">
              <div class="card-header" id="headingThree">
                <h2 class="mb-0">
                  <button
                    class="btn btn-link btn-block text-left collapsed"
                    type="button"
                    id="faqButtonText"
                    data-toggle="collapse"
                    data-target="#collapse14"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                  >
                    Kann ich Freunde mitbringen?
                  </button>
                </h2>
              </div>
              <div
                id="collapse14"
                class="collapse"
                aria-labelledby="headingThree"
                data-parent="#accordionExample"
              >
                <div class="card-body">
                  Jeder darf Freunde zum Trainieren mitbringen, entweder mit
                  einer vorhandenen Gästekarte oder einem Probetraining für
                  14,99€.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Faq",
};
</script>

<style scoped>
.card {
  border: 1px solid #f2f2f2;
}

.card-body {
  font-weight: 400;
}

#faqButtonText {
  font-weight: 700;
  font-size: 17px;
}

#faqContainer {
  background-color: #f2f2f2;
  padding-top: 40px;
  padding-bottom: 40px;
}

#headingOne,
#headingTwo,
#headingThree {
  background-color: #fbce3b;
  color: #f2f2f2;
}
#headingOne h2 {
  color: #f2f2f2;
}
#faqButton {
  background-color: #fbce3b;
}
#title {
  text-align: center;
  margin-bottom: 40px;
}
#title h2 {
  color: #212529;
  font-family: noto-sans-display-extraconde, sans-serif;
  font-size: 70px;
  font-style: italic;
}
#title h4 {
  color: #fbce3b;
  font-family: noto-sans-display-extraconde, sans-serif;
  font-size: 35px;
  font-style: italic;
  margin-top: -10px;
}
#faqButtonText {
  color: white;
  white-space: normal;
}
</style>
