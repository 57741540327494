<template>
  <div class="uberUnscontainer">
    <EggPopup></EggPopup>
    <div class="ueberUnsHeader"></div>
    <div class="row" id="rowUeberuns">
      <div class="col-sm d-flex justify-content-center text-center">
        <div
          id="title"
          style="margin-top: 30px; padding-bottom: 30px"
          data-aos="fade-down"
        >
          <h2>DAS FORUM</h2>
          <h4>WIR BRINGEN DICH WEITER</h4>
        </div>
      </div>
    </div>
    <div class="row" style="margin-bottom: 40px">
      <div class="col-sm d-flex justify-content-center text-center">
        <div
          style="
            padding-bottom: 30px;
            padding-left: 20px;
            padding-right: 20px;
            max-width: 900px;
          "
          data-aos="fade-down"
        >
          <p style="text-align: left">
            <strong
              >Das Forum wurde im Jahre 1999 im Gewerbegebiet Neuenbürg von den
              beiden langjährig befreundeten Inhabern Frank Reuck und Clement
              Deeg gegründet. Durch das breit gefächerte Angebot hat sich das
              Forum weit über das Einzugsgebiet hinaus herumgesprochen und wird
              durch entsprechende Werbemaßnahmen vermarktet.</strong
            >
          </p>
          <p style="text-align: left">
            <strong>
              Bei uns trainieren Leistungs-, Freizeit- und Gesundheitssportler
              auf rund 1.500 m² und zwei Etagen.
            </strong>
          </p>
          <p style="text-align: left">
            <strong>
              Die persönliche und individuelle Beratung unserer Mitglieder liegt
              uns sehr am Herzen.</strong
            >
          </p>
          <p style="text-align: left">
            <strong>
              Es herrscht eine sehr schöne familiäre Atmosphäre mit
              unterschiedlich individuellen Altersgruppen und Persönlichkeiten,
              die unser Studio so besonders machen.
            </strong>
          </p>
          <p style="text-align: left">
            <strong>
              Das Forum bietet eine Vielseitigkeit der Mitarbeiter und
              Kurstrainern.
            </strong>
          </p>
          <p style="text-align: left">
            <strong>
              Komm vorbei und werde auch Du ein Teil vom Forum und trage auch
              zur tollen Atmosphäre bei!
            </strong>
          </p>
        </div>
      </div>
    </div>
    <div class="trainerContainer">
      <Trainer :filter="'Trainer'"></Trainer>
      <div style="margin-top: 200px"></div>
      <Trainer :filter="'Kurstrainer'"></Trainer>
    </div>

    <div class="partnerContainer">
      <div class="row" id="partnerRow1">
        <div class="col-sm d-flex justify-content-center text-center">
          <div id="title2" style="padding-bottom: 100px">
            <h2 style="color: white" data-aos="fade-right">
              Unsere Kooperationspartner
            </h2>
          </div>
        </div>
        <div
          class="container text-center my-3"
          style="min-width: 100%; padding-right: 30px; padding-left: 30px"
        >
          <div class="row mx-auto my-auto">
            <div class="col-sm-1"></div>
            <div class="col-sm-2">
              <a href="https://urbansportsclub.com/de"
                ><img
                  src="@/assets/img/logos/urban-sports-club-partner-02_928ab192ec.png"
                  style="width: 100%; height: auto; margin-top: 0px"
                  class="d-inline-block align-top"
                  alt=""
                  data-aos="fade-right"
                  id="partner"
              /></a>
            </div>
            <div class="col-sm-2">
              <img
                src="@/assets/img/logos/großmnn.png"
                style="width: 100%; height: auto; margin-top: 20px"
                class="d-inline-block align-top"
                alt=""
                data-aos="fade-down"
              />
            </div>
            <div class="col-sm-2">
              <a href="https://www.hansefit.de/"
                ><img
                  src="@/assets/img/logos/Hansefit_Logo (1).png"
                  style="width: 100%; height: auto; margin-top: 0px"
                  class="d-inline-block align-top"
                  alt=""
                  data-aos="fade-down"
                  id="partner"
              /></a>
            </div>
            <div class="col-sm-2">
              <a href="https://www.interfit.de/de/"
                ><img
                  src="@/assets/img/logos/interfit-logo (1).png"
                  style="width: 100%; height: auto; margin-top: 0px"
                  class="d-inline-block align-top"
                  alt=""
                  data-aos="fade-left"
                  id="partner"
              /></a>
            </div>
            <div class="col-sm-2">
              <a href="https://www.bartondesign.de/"
                ><img
                  src="@/assets/img/logos/BartonDesign_Logo.png"
                  style="width: 100%; height: auto; margin-top: 0px"
                  class="d-inline-block align-top"
                  alt=""
                  data-aos="fade-left"
                  id="partner"
              /></a>
            </div>

            <div class="col-sm-1"></div>
          </div>
        </div>
      </div>
      <div class="row" id="partnerRow1">
        <div
          class="container text-center my-3"
          style="min-width: 100%; padding-right: 30px; padding-left: 30px"
        >
          <div class="row mx-auto my-auto">
            <div class="col-sm-1"></div>
            <div class="col-sm-2">
              <a href="https://www.schrank-shop.com/"
                ><img
                  src="@/assets/img/logos/dekoNbg.jpg"
                  style="width: 100%; height: auto; margin-top: 0px"
                  class="d-inline-block align-top"
                  alt=""
                  data-aos="fade-right"
                  id="partner"
              /></a>
            </div>
            <div class="col-sm-2">
              <a href="https://salon-divahair.de/">
                <img
                  src="@/assets/img/logos/DivaHair.png"
                  style="width: 115%; height: auto; margin-top: 0px"
                  class="d-inline-block align-top"
                  alt=""
                  data-aos="fade-up"
                  id="partner"
              /></a>
            </div>
            <div class="col-sm-2">
              <a
                href="https://www.aok.de/pk/bw/inhalt/gepruefte-fitness-studios/"
                ><img
                  src="@/assets/img/logos/AOK_partnerstudio_01 (1).png"
                  style="width: 90%; height: auto; margin-top: 0px"
                  class="d-inline-block align-top"
                  alt=""
                  data-aos="fade-up"
                  id="partner"
              /></a>
            </div>
            <div class="col-sm-2">
              <a href="https://www.dssv.de/"
                ><img
                  src="@/assets/img/logos/DSSV (1).png"
                  style="width: 70%; height: auto; margin-top: 0px"
                  class="d-inline-block align-top"
                  alt=""
                  data-aos="fade-left"
                  id="partner"
              /></a>
            </div>
            <div class="col-sm-2">
              <a href="https://www.by-onlineshop.de/"
                ><img
                  src="@/assets/img/logos/BY_Logo.png"
                  style="width: 70%; height: auto; margin-top: 0px"
                  class="d-inline-block align-top"
                  alt=""
                  data-aos="fade-left"
                  id="partner"
              /></a>
            </div>
            <div class="col-sm-1">
              <img
                id="EggGoldDesktop"
                style="display: none;"
                src="@/assets/img/Ostern/EiGold.png"
                alt="Ei1"
                v-on:click="eggFound('gold')"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="row" id="partnerRow2" style="">
        <div class="col-sm d-flex justify-content-center text-center">
          <div id="title2" style="margin-top: 30px; padding-bottom: 100px">
            <h2 style="color: white" data-aos="fade-right">
              Unsere Kooperationspartner
            </h2>
          </div>
        </div>
        <div
          class="container text-center my-3"
          style="min-width: 100%; padding-right: 30px; padding-left: 30px"
        >
          <div class="row mx-auto my-auto">
            <div class="col-sm-2">
              <a href="https://urbansportsclub.com/de"
                ><img
                  src="@/assets/img/logos/urban-sports-club-partner-02_928ab192ec.png"
                  style="width: 100%; height: auto; margin-top: 0px"
                  class="d-inline-block align-top"
                  alt=""
                  data-aos="fade-right"
                  id="partner"
              /></a>
            </div>
            <div class="col-sm-2">
              <img
                src="@/assets/img/logos/großmnn.png"
                style="width: 100%; height: auto; margin-top: 20px"
                class="d-inline-block align-top"
                alt=""
                data-aos="fade-down"
              />
            </div>
            <div class="col-sm-2">
              <a href="https://www.hansefit.de/"
                ><img
                  src="@/assets/img/logos/Hansefit_Logo (1).png"
                  style="width: 100%; height: auto; margin-top: 0px"
                  class="d-inline-block align-top"
                  alt=""
                  data-aos="fade-down"
                  id="partner"
              /></a>
            </div>
            <div class="col-sm-2">
              <a href="https://www.interfit.de/de/"
                ><img
                  src="@/assets/img/logos/interfit-logo (1).png"
                  style="width: 100%; height: auto; margin-top: 0px"
                  class="d-inline-block align-top"
                  alt=""
                  data-aos="fade-left"
                  id="partner"
              /></a>
            </div>
            <div class="col-sm-2">
              <a href="https://www.bartondesign.de/"
                ><img
                  src="@/assets/img/logos/BartonDesign_Logo.png"
                  style="width: 100%; height: auto; margin-top: 0px"
                  class="d-inline-block align-top"
                  alt=""
                  data-aos="fade-left"
                  id="partner"
              /></a>
            </div>
          </div>
        </div>
      </div>
      <div class="row" id="partnerRow2">
        <div
          class="container text-center my-3"
          style="min-width: 100%; padding-right: 30px; padding-left: 30px"
        >
          <div class="row mx-auto my-auto">
            <div class="col-sm-2">
              <a href="https://www.schrank-shop.com/"
                ><img
                  src="@/assets/img/logos/dekoNbg.jpg"
                  style="
                    width: 100%;
                    height: auto;
                    margin-top: 0px;
                    margin-left: 40px;
                  "
                  class="d-inline-block align-top"
                  alt=""
                  data-aos="fade-right"
                  id="partner"
              /></a>
            </div>
            <img
              id="EggGoldMobile"
              style="display: none;"
              src="@/assets/img/Ostern/EiGold.png"
              alt="Ei1"
              v-on:click="eggFound('gold')"
            />
            <div class="col-sm-2">
              <a href="https://salon-divahair.de/">
                <img
                  src="@/assets/img/logos/DivaHair.png"
                  style="width: 115%; height: auto; margin-top: 0px"
                  class="d-inline-block align-top"
                  alt=""
                  data-aos="fade-up"
                  id="partner"
              /></a>
            </div>
            <div class="col-sm-2">
              <a
                href="https://www.aok.de/pk/bw/inhalt/gepruefte-fitness-studios/"
                ><img
                  src="@/assets/img/logos/AOK_partnerstudio_01 (1).png"
                  style="width: 90%; height: auto; margin-top: 0px"
                  class="d-inline-block align-top"
                  alt=""
                  data-aos="fade-up"
                  id="partner"
              /></a>
            </div>
            <div class="col-sm-2">
              <a href="https://www.dssv.de/"
                ><img
                  src="@/assets/img/logos/DSSV (1).png"
                  style="
                    width: 70%;
                    height: auto;
                    margin-top: 0px;
                    margin-right: 50px;
                  "
                  class="d-inline-block align-top"
                  alt=""
                  data-aos="fade-left"
                  id="partner"
              /></a>
            </div>
            <div class="col-sm-2">
              <a href="https://www.by-onlineshop.de/"
                ><img
                  src="@/assets/img/logos/BY_Logo.png"
                  style="width: 70%; height: auto; margin-top: 0px"
                  class="d-inline-block align-top"
                  alt=""
                  data-aos="fade-left"
                  id="partner"
              /></a>
            </div>
          </div>
        </div>
      </div>

      <div class="row" id="partnerRow3">
        <div class="col-sm d-flex justify-content-center text-center">
          <div id="title2" style="margin-top: 30px; padding-bottom: 0px">
            <h2 style="color: white" data-aos="fade-right">
              Unsere Kooperationspartner
            </h2>
          </div>
        </div>
        <div
          class="container text-center my-3"
          style="min-width: 100%; padding-right: 30px; padding-left: 30px"
        >
          <div class="row mx-auto my-auto">
            <div class="col-md-6">
              <a href="https://urbansportsclub.com/de"
                ><img
                  src="@/assets/img/logos/urban-sports-club-partner-02_928ab192ec.png"
                  style="width: 70%; height: auto; margin-top: 0px"
                  class="d-inline-block align-top"
                  alt=""
                  data-aos="fade-right"
                  id="partner"
              /></a>
            </div>
            <div class="col-md-6">
              <img
                src="@/assets/img/logos/großmnn.png"
                style="width: 70%; height: auto; margin-top: 20px"
                class="d-inline-block align-top"
                alt=""
                data-aos="fade-down"
              />
            </div>
          </div>
          <img
            id="EggGoldMobilesmall"
            style="display: none;"
            src="@/assets/img/Ostern/EiGold.png"
            alt="Ei1"
            v-on:click="eggFound('gold')"
          />
          <div class="row mx-auto my-auto">
            <div class="col-md-6">
              <a href="https://www.hansefit.de/"
                ><img
                  src="@/assets/img/logos/Hansefit_Logo (1).png"
                  style="width: 70%; height: auto; margin-top: 0px"
                  class="d-inline-block align-top"
                  alt=""
                  data-aos="fade-down"
                  id="partner"
              /></a>
            </div>
            <div class="col-md-6">
              <a href="https://www.interfit.de/de/"
                ><img
                  src="@/assets/img/logos/interfit-logo (1).png"
                  style="width: 70%; height: auto; margin-top: 0px"
                  class="d-inline-block align-top"
                  alt=""
                  data-aos="fade-left"
                  id="partner"
              /></a>
            </div>
          </div>
        </div>
      </div>
      <div class="row" id="partnerRow3">
        <div
          class="container text-center my-3"
          style="min-width: 100%; padding-right: 30px; padding-left: 30px"
        >
          <div class="row mx-auto my-auto">
            <div class="col-sm-6">
              <a href="https://www.schrank-shop.com/"
                ><img
                  src="@/assets/img/logos/dekoNbg.jpg"
                  style="width: 70%; height: auto; margin-top: 20px"
                  class="d-inline-block align-top"
                  alt=""
                  data-aos="fade-right"
                  id="partner"
              /></a>
            </div>
            <div class="col-sm-6">
              <a href="https://salon-divahair.de/">
                <img
                  src="@/assets/img/logos/DivaHair.png"
                  style="width: 80%; height: auto; margin-top: 20px"
                  class="d-inline-block align-top"
                  alt=""
                  data-aos="fade-up"
                  id="partner"
              /></a>
            </div>
          </div>
          <div class="row mx-auto my-auto">
            <div class="col-sm-6">
              <a
                href="https://www.aok.de/pk/bw/inhalt/gepruefte-fitness-studios/"
                ><img
                  src="@/assets/img/logos/AOK_partnerstudio_01 (1).png"
                  style="width: 70%; height: auto; margin-top: 20px"
                  class="d-inline-block align-top"
                  alt=""
                  data-aos="fade-up"
                  id="partner"
              /></a>
            </div>
            <div class="col-sm-6">
              <a href="https://www.dssv.de/"
                ><img
                  src="@/assets/img/logos/DSSV (1).png"
                  style="
                    width: 55%;
                    height: auto;
                    margin-top: 20px;
                    margin-bottom: 100px;
                  "
                  class="d-inline-block align-top"
                  alt=""
                  data-aos="fade-left"
                  id="partner"
              /></a>
            </div>
          </div>
          <div class="row mx-auto my-auto">
            <div class="col-sm-6">
              <a href="https://www.by-onlineshop.de/"
                ><img
                  src="@/assets/img/logos/BY_Logo.png"
                  style="width: 30%; height: auto; margin-top: 0px; margin-bottom: 100px;"
                  class="d-inline-block align-top"
                  alt=""
                  data-aos="fade-left"
                  id="partner"
              /></a>
            </div>
            <div class="col-sm-6">
              <a href="https://www.bartondesign.de/"
                ><img
                  src="@/assets/img/logos/BartonDesign_Logo.png"
                  style="width: 70%; height: auto; margin-top: 0px; margin-bottom: 100px;"
                  class="d-inline-block align-top"
                  alt=""
                  data-aos="fade-left"
                  id="partner"
              /></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
import Trainer from "./UeberUns/TrainerCarousel.vue";
import LeistungDesktop from "./startseite/LeistungDesktop.vue";
import EggPopup from "../components/EggPopup";
export default {
  components: {
    Trainer,
    LeistungDesktop,
    EggPopup,
  },
  methods: {},
  mounted: function() {
    $("#recipeCarousel2").carousel({
      interval: 4000,
    });

    $("#recipeCarousel2.carousel .carousel-item").each(function() {
      var minPerSlide = 3;
      var next = $(this).next();
      if (!next.length) {
        next = $(this).siblings(":first");
      }
      next
        .children(":first-child")
        .clone()
        .appendTo($(this));

      for (var i = 0; i < minPerSlide; i++) {
        next = next.next();
        if (!next.length) {
          next = $(this).siblings(":first");
        }

        next
          .children(":first-child")
          .clone()
          .appendTo($(this));
      }
    });

    $("#recipeCarousel1").carousel({
      interval: 4000,
    });

    $("#recipeCarousel1.carousel .carousel-item").each(function() {
      var minPerSlide = 3;
      var next = $(this).next();
      if (!next.length) {
        next = $(this).siblings(":first");
      }
      next
        .children(":first-child")
        .clone()
        .appendTo($(this));

      for (var i = 0; i < minPerSlide; i++) {
        next = next.next();
        if (!next.length) {
          next = $(this).siblings(":first");
        }

        next
          .children(":first-child")
          .clone()
          .appendTo($(this));
      }
    });

    $("#recipeCarousel3").carousel({
      interval: 4000,
    });

    $("#recipeCarousel3.carousel .carousel-item").each(function() {
      var minPerSlide = 3;
      var next = $(this).next();
      if (!next.length) {
        next = $(this).siblings(":first");
      }
      next
        .children(":first-child")
        .clone()
        .appendTo($(this));

      for (var i = 0; i < minPerSlide; i++) {
        next = next.next();
        if (!next.length) {
          next = $(this).siblings(":first");
        }

        next
          .children(":first-child")
          .clone()
          .appendTo($(this));
      }
    });

    $("#recipeCarousel4").carousel({
      interval: 4000,
    });

    $("#recipeCarousel4.carousel .carousel-item").each(function() {
      var minPerSlide = 3;
      var next = $(this).next();
      if (!next.length) {
        next = $(this).siblings(":first");
      }
      next
        .children(":first-child")
        .clone()
        .appendTo($(this));

      for (var i = 0; i < minPerSlide; i++) {
        next = next.next();
        if (!next.length) {
          next = $(this).siblings(":first");
        }

        next
          .children(":first-child")
          .clone()
          .appendTo($(this));
      }
    });
  },
};
</script>

<style scoped>
@media only screen and (max-width: 1285px) {
}

.Alex img {
  width: 100%;
}

h5 {
  font-size: 1.5vw;
}

h4 {
  font-size: 1.25vw;
}

.ueberUnsHeader #btn360:hover {
  background-color: #212529;
  color: white;
  transition: 0.3s;
}

@media only screen and (max-width: 1085px) {
  #rowUeberuns {
    margin-top: -00px !important;
  }

  .ueberUnsHeader {
    min-height: 400px !important;
  }
}

@media only screen and (max-width: 715px) {
  .ueberUnsHeader {
    min-height: 300px !important;
  }
}

@media only screen and (max-width: 580px) {
  #desktopGF {
    display: none;
  }
}

@media only screen and (min-width: 580px) {
  #mobileGF {
    display: none;
  }
}

@media only screen and (max-width: 500px) {
  .ueberUnsHeader {
    min-height: 220px !important;
  }
}

@media only screen and (min-width: 1285px) {
  .ueberUnsHeader {
    min-height: 700px;
  }
}

.ueberUnsHeader {
  width: 100%;
  min-height: 700px;
  background-image: url("../assets/img/logos/Studio2.jpg");
  background-size: 100%;
  background-position-y: -50px;
  background-repeat: no-repeat;
  position: relative;
}

.partnerContainer {
  /* height: 100%; */
  /* min-height: 500px; */
  background-image: url("../assets/img/logos/3Element 8.png");
  /* background-size: cover; */
  background-position: top;
  background-size: 100%;
  background-repeat: no-repeat;
  position: relative;
  margin-top: 200px;
}

@media only screen and (max-width: 1610px) {
  .partnerContainer {
    background-size: 150% !important;
  }

  .trainerContainer {
    background-size: 150% !important;
  }

  .kursTrainerContainer {
    background-size: 150% !important;
  }
}

@media only screen and (max-width: 1100px) {
  #title2 {
    margin-top: 0px !important;
  }
}

@media only screen and (max-width: 870px) {
  .partnerContainer {
    background-size: 200% !important;
  }

  .trainerContainer {
    background-size: 200% !important;
  }

  .kursTrainerContainer {
    background-size: 200% !important;
  }
}

@media only screen and (max-width: 680px) {
  #title2 h2 {
    font-size: 50px !important;
  }
}

@media only screen and (max-width: 510px) {
  .partnerContainer {
    background-size: 230% !important;
  }

  .trainerContainer {
    background-size: 230% !important;
  }

  .kursTrainerContainer {
    background-size: 230% !important;
  }
}

@media only screen and (max-width: 450px) {
  .partnerContainer {
    background-size: 460% !important;
  }
}

@media only screen and (max-width: 1500px) {
  #partnerRow1 {
    display: none;
  }
}

@media only screen and (min-width: 1501px) {
  #partnerRow2 {
    display: none;
  }
}

@media only screen and (max-width: 1200px) {
  #partnerRow2 {
    display: none;
  }
}

@media only screen and (min-width: 1201px) {
  #partnerRow3 {
    display: none;
  }
}

.kursTrainerContainer {
  /* height: 100%; */
  /* min-height: 500px; */
  background-image: url("../assets/img/logos/2Element 7.png");
  /* background-size: cover; */
  background-position: top;
  background-size: 100%;
  background-repeat: no-repeat;
  position: relative;
  margin-top: 200px;
}

.trainerContainer {
  /* height: 100%; */
  /* min-height: 500px; */
  background-image: url("../assets/img/logos/3Element 8.png");
  /* background-size: cover; */
  background-position: top;
  background-size: 100%;
  background-repeat: no-repeat;
  position: relative;
}

#title2 h2 {
  /* margin-top: -20px; */
  font-family: noto-sans-display-extraconde, sans-serif;
  font-size: 70px;
  font-style: italic;
}

.ueberUnsHeader #btn360 {
  position: absolute;
  bottom: 10px;
  left: 10px;
  outline-color: transparent;
  color: white;
  background-color: #fbce3b;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bolder;
  font-style: italic;
  height: 50px;
  width: 300px;
  font-size: 20px;
  -webkit-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
  -webkit-animation: slide-in-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: slide-in-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-1000px);
    transform: translateX(-1000px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-1000px);
    transform: translateX(-1000px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
</style>
