<template>
  <div class="mitgliedschaft">
    <!-- MODALS -->
    <div
      class="modal fade"
      id="extrasModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="extrasModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="extrasModalLabel">
              {{ paket_titel }}
            </h5>
          </div>
          <div class="modal-body">
            <div class="row">
              <p>
                {{ inlcuded }}
              </p>
            </div>
            <div
              class="row included"
              v-for="(included, id) in list_included"
              :key="included.id"
            >
              <div class="col-2">
                <img :src="included.img" class="extras-icon" />
              </div>
              <div class="col-10">
                <span>{{ included.text }}</span>
              </div>
              <hr v-if="id != list_included.length - 1" />
            </div>

            <hr />
            <div class="row">
              <p>
                Zubuchbar:
              </p>
            </div>
            <div
              class="row excluded"
              v-for="(excluded, id) in list_excluded"
              :key="excluded.id"
            >
              <div class="col-2">
                <img :src="excluded.img" class="extras-icon" />
              </div>
              <div class="col-10">
                <span>{{ excluded.text }}</span>
              </div>
              <hr v-if="id != list_excluded.length - 1" />
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary" data-dismiss="modal">
              Okay!
            </button>
          </div>
        </div>
      </div>
    </div>

    <MeetingModal title="Jetzt Termin vereinbaren! 🚀" />

    <div class="justify-content-center text-center">
      <div data-aos="fade-up" id="title">
        <h2>WERDE TEIL DES FORUMS</h2>
        <h4>& LEBE JETZT</h4>
      </div>
    </div>

    <div
      class="row text-center justify-content-center sticky"
      data-aos="fade-up"
      style="gap: 20px;"
    >
      <button class="btn btn-secondary">€ / Monat: {{ price }}</button>
      <button
        class="btn btn-primary"
        data-toggle="modal"
        data-target="#terminModal"
      >
        Jetzt Termin vereinbaren!
      </button>
    </div>

    <div
      v-for="paket in pakete"
      :key="paket.id"
      class="row paket-container"
      :class="paket.containerClass"
      data-aos="fade-down"
    >
      <div class="col-md-6">
        <img :src="paket.imgSrc" data-aos="fade-right" />
      </div>
      <div class="col-md-5 text-container">
        <div class="row">
          <h3>{{ paket.title }}</h3>
        </div>
        <hr class="paket-hr" />
        <div class="row">
          <p>{{ paket.description }}</p>
        </div>
        <div class="row btn-row">
          <div
            v-for="option in paket.options"
            :key="option.id"
            class="btn"
            :class="option.btnClass"
            @click="changePrice(option.value)"
          >
            {{ option.label }}
          </div>
          <div
            v-if="paket.btnClass"
            :class="paket.btnClass"
            @click="showExtras(paket.type)"
            data-toggle="modal"
            data-target="#extrasModal"
          >
            <span>Extras</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-stars"
              viewBox="0 0 16 16"
            >
              <path
                d="M7.657 6.247c.11-.33.576-.33.686 0l.645 1.937a2.89 2.89 0 0 0 1.829 1.828l1.936.645c.33.11.33.576 0 .686l-1.937.645a2.89 2.89 0 0 0-1.828 1.829l-.645 1.936a.361.361 0 0 1-.686 0l-.645-1.937a2.89 2.89 0 0 0-1.828-1.828l-1.937-.645a.361.361 0 0 1 0-.686l1.937-.645a2.89 2.89 0 0 0 1.828-1.828l.645-1.937zM3.794 1.148a.217.217 0 0 1 .412 0l.387 1.162c.173.518.579.924 1.097 1.097l1.162.387a.217.217 0 0 1 0 .412l-1.162.387A1.734 1.734 0 0 0 4.593 5.69l-.387 1.162a.217.217 0 0 1-.412 0L3.407 5.69A1.734 1.734 0 0 0 2.31 4.593l-1.162-.387a.217.217 0 0 1 0-.412l1.162-.387A1.734 1.734 0 0 0 3.407 2.31l.387-1.162zM10.863.099a.145.145 0 0 1 .274 0l.258.774c.115.346.386.617.732.732l.774.258a.145.145 0 0 1 0 .274l-.774.258a1.156 1.156 0 0 0-.732.732l-.258.774a.145.145 0 0 1-.274 0l-.258-.774a1.156 1.156 0 0 0-.732-.732L9.1 2.137a.145.145 0 0 1 0-.274l.774-.258c.346-.115.617-.386.732-.732L10.863.1z"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { pakete, extras } from "./data";
import MeetingModal from "./MeetingModal.vue";
export default {
  components: {
    MeetingModal,
  },
  data() {
    return {
      price: 0,
      pakete: pakete,
      infoMailStatus: "",
      included: "",
      paket_titel: "",
      list_included: [{}],
      list_excluded: [
        {
          img: "",
          text: "",
        },
      ],
      extras: extras,
    };
  },
  mounted() {},
  methods: {
    changePrice(paket) {
      switch (paket) {
        case "basic12":
          this.price = 44.99;
          break;

        case "basic24":
          this.price = 39.99;
          break;

        case "fitness12":
          this.price = 64.99;
          break;

        case "fitness24":
          this.price = 54.99;
          break;

        case "premium12":
          this.price = 84.99;
          break;

        case "premium24":
          this.price = 74.99;
          break;

        case "gold":
          this.price = 49.99;
          break;

        case "zeit":
          this.price = 29.99;
          break;

        default:
          break;
      }
    },
    showExtras(paket) {
      this.emptyArray();
      this.inlcuded = "Enthalten:";
      switch (paket) {
        case "basic":
          this.paket_titel = "Paket Basic";
          this.list_included.push({
            img: require("@/assets/img/Mitgliedschaft/dash-circle.svg"),
            text: "Keine",
          });

          break;

        case "fitness":
          this.paket_titel = "Paket Fitness";
          this.inlcuded = "Bis zu 3 Extras kostenfrei:";
          this.list_included.push({
            img: require("@/assets/img/logos/extraDusche2.png"),
            text: "Duschen",
          });
          this.list_included.push({
            img: require("@/assets/img/logos/extraGetraenke2.png"),
            text: "Getränke-Flatrate",
          });
          this.list_included.push({
            img: require("@/assets/img/logos/extraPP2.png"),
            text: "Power Plate",
          });
          this.list_included.push({
            img: require("@/assets/img/logos/extraSoli2.png"),
            text: "Solarium",
          });
          this.list_included.push({
            img: require("@/assets/img/logos/extraSauna2.png"),
            text: "Sauna",
          });
          this.list_included.push({
            img: require("@/assets/img/logos/extraRolle2.png"),
            text: "Rolle- und Bandmassage",
          });
          this.list_included.push({
            img: require("@/assets/img/logos/extraMassage2.png"),
            text: "Massageliege",
          });
          this.list_included.push({
            img: require("@/assets/img/logos/extraInfrarot2.png"),
            text: "Infrarotkabine",
          });
          break;

        case "premium":
          this.paket_titel = "Paket Premium";
          this.list_included.push({
            img: require("@/assets/img/logos/extraDusche2.png"),
            text: "Duschen",
          });
          this.list_included.push({
            img: require("@/assets/img/logos/extraGetraenke2.png"),
            text: "Getränke-Flatrate",
          });
          this.list_included.push({
            img: require("@/assets/img/logos/extraPP2.png"),
            text: "Power Plate",
          });
          this.list_included.push({
            img: require("@/assets/img/logos/extraSoli2.png"),
            text: "Solarium",
          });
          this.list_included.push({
            img: require("@/assets/img/logos/extraSauna2.png"),
            text: "Sauna",
          });
          this.list_included.push({
            img: require("@/assets/img/logos/extraRolle2.png"),
            text: "Rolle- und Bandmassage",
          });
          this.list_included.push({
            img: require("@/assets/img/logos/extraMassage2.png"),
            text: "Massageliege",
          });
          this.list_included.push({
            img: require("@/assets/img/logos/extraInfrarot2.png"),
            text: "Infrarotkabine",
          });

          break;

        case "gold":
          this.paket_titel = "Goldtarif";
          this.list_included.push({
            img: require("@/assets/img/logos/extraInfrarot2.png"),
            text: "3 Wahlleistungen",
          });
          break;

        case "zeit":
          this.paket_titel = "Geräte Zeiteingeschränkt";
          this.list_included.push({
            img: require("@/assets/img/Mitgliedschaft/dash-circle.svg"),
            text: "Keine",
          });

          break;

        default:
          break;
      }
      this.extras.forEach((extra) => {
        var exclude = true;
        this.list_included.forEach((extraIn) => {
          if (extra.text == extraIn.text) {
            exclude = false;
          }
        });
        if (exclude) {
          this.list_excluded.push({
            img: extra.img,
            text: extra.text,
          });
        }
      });
    },
    emptyArray() {
      while (this.list_included.length != 0) {
        this.list_included.pop();
      }
      while (this.list_excluded.length != 0) {
        this.list_excluded.pop();
      }
    },
  },
};
</script>

<style>
@import "./membershipStyles.css";
</style>
