<template>
  <form class="needs-validation" onsubmit="return false" id="terminForm">
    <div class="form-group">
      <label for="inputName">Dein Name</label>
      <input
        type="text"
        class="form-control"
        id="inputName"
        placeholder="Namen eingeben..."
        required
      />
      <small class="form-text text-muted"></small>
    </div>
    <div class="form-group">
      <label for="inputMail">Deine Email</label>
      <input
        type="email"
        class="form-control"
        id="inputMail"
        placeholder="Email eingeben..."
        required
      />
      <small class="form-text text-muted"></small>
    </div>
    <div class="form-group">
      <label for="inputPhone">Deine Telefonnummer</label>
      <input
        type="phone"
        class="form-control"
        id="inputPhone"
        placeholder="Telefonnummer eingeben..."
      />
      <small class="form-text text-muted"></small>
    </div>
    <div class="form-group">
      <label for="inputDate">Datum</label>
      <input type="date" id="inputDate" required />
    </div>
    <div class="form-group">
      <label for="inputTime">Uhrzeit</label>
      <input type="time" id="inputTime" required />
    </div>
    <button class="btn btn-primary" style="width: 100%" @click="sendMail()">
      Absenden
    </button>
  </form>
</template>

<script>
import { validateForm, sendMail } from "./utils";
import $ from "jquery";
import store from "../../../store";

export default {
  methods: {
    async sendMail() {
      try {
        const validatedForm = await validateForm();
        this.startSending(validatedForm);
      } catch (error) {
        console.error("Error:", error);
      }
    },
    startSending(form) {
      const formData = {
        name: $("#inputName").val(),
        email: $("#inputMail").val(),
        phone: $("#inputPhone").val(),
        date: new Date($("#inputDate").val()).toLocaleDateString("de-DE"),
        time: $("#inputTime").val(),
      };
      store.state.mail.status = "loading";
      sendMail(formData, "https://forum-neuenbuerg.de/terminMG.php");
    },
  },
};
</script>

<style scoped></style>
