export const media = [
	{
	  thumb:  require('@/assets/img/Kurse/Yoga/Yoga1.jpg'),
	  src: require('@/assets/img/Kurse/Yoga/Yoga1.jpg'),
	},
	{
		thumb:  require('@/assets/img/Kurse/Yoga/Yoga2.jpg'),
		src: require('@/assets/img/Kurse/Yoga/Yoga2.jpg'),
	  },
	  {
		thumb:  require('@/assets/img/Kurse/Yoga/Yoga3.jpg'),
		src: require('@/assets/img/Kurse/Yoga/Yoga3.jpg'),
	  },
	  {
		thumb:  require('@/assets/img/Kurse/Yoga/Yoga4.jpg'),
		src: require('@/assets/img/Kurse/Yoga/Yoga4.jpg'),
	  },
	  {
		thumb:  require('@/assets/img/Kurse/Yoga/Yoga5.jpg'),
		src: require('@/assets/img/Kurse/Yoga/Yoga5.jpg'),
	  },
	  {
		thumb:  require('@/assets/img/Kurse/Yoga/Yoga6.jpg'),
		src: require('@/assets/img/Kurse/Yoga/Yoga6.jpg'),
	  },
  ]