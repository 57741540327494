
<template>
  <div
    class="Ruecken" 
  >
    <div
      v-for="i in [0, 1, 2, 3, 4]"
      :key="i"
    >
    </div>
   
    <div>
      <ul>
        <li
          v-for="(image, index) in media"
          :key="index"
          style="display: inline-block; padding: 10px;"
        >
          <img
            v-lazy="image.src || image.thumb"
            style="height: 100px"
            @click="openGallery(index)"
          >
        </li>
      </ul>

      <LightBox
        ref="lightbox"
        :media="media"
        :show-caption="true"
        :show-light-box="false"
      />
    </div>
  </div>
</template>

<script>
import LightBox from '../LightBox'
import {
  media,
  paragraphs,
} from './RueckenMedia'
export default {
  components: {
    LightBox,
  },
  data () {
    return {
      media,
    }
  },
  methods: {
    openGallery(index) {
      this.$refs.lightbox.showImage(index)
    }
  }
}
</script>

<style scoped>

@media only screen and (max-width: 770px) {

ul{
  margin-left: -40px!important;
}

li{
  width: 20px!important;
}

}

</style>