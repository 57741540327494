<template>
    <div id="socialMediaContainer">
        <div class="container" style=" margin-top:60px; margin-bottom: 100px;" >
            <div class="row">
                <div class="col-sm">
                    <div id="title">
                     <h2>Folge uns auf Social Media</h2>
                     <h4>Instagram & Facebook</h4>
                    </div>
                </div>
            </div>
            <div class="row " style="padding-top: 15px;">
                <div class="col" data-aos="fade-right">
                    <a target="_blank" href="https://www.instagram.com/fitnessforumneuenbuerg/">                    
                        <img src="@/assets/img/logos/87390.png"
							style="width: 30%; float: right;">
                    </a>
                </div>
                <img id="EggLila" style="display: none;" src="@/assets/img/Ostern/EiLila.png" alt="Ei1" v-on:click="eggFound('lila')">
                <div class="col" data-aos="fade-left">
                <a target="_blank" id="socialMediaButton" href="https://www.facebook.com/FitnessForumNeuenbuerg"
                  >
                    <img src="@/assets/img/logos/F_icon.png"
							style="width: 30%">
                </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
	import $ from "jquery";
    export default {
        name: "SocialMedia",

		mounted() {
			AOS => AOS.init();
			axios.get(
					"https://forum-neuenbuerg.de/action.php?username=web166&pw=BX79HmqzoxKNb4OY&request=eggsActive"
				)
				.then(response => {
					response.data.forEach(egg => {

						if (egg.color == "lila") {
							document.getElementById('EggLila').style.cssText =
								"display: block; cursor: pointer"
						}
					});
				});
		},
		methods: {
			eggFound(clr) {

				this.$store.state.klickedEgg = clr
				$('#EggModal').modal('show')
			},
		}
    }
</script>

<style scoped>

#title{
    text-align: center;
}
#title h4{
    color: #fbce3b;
}
#socialMediaIconsContainer{
    margin-top:40px;
}

</style>