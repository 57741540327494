<template>
    <div class="profile-page"  style="background-color: #F2F2F2;">
        <section class="section-profile-cover section-shaped my-0" id="header">
           
        </section>
        <section class="section section-skew">
            <div class="container">
                <card shadow class="card-profile mt--300" no-body style="background-color: white;">
                    <div class="px-4">
                        <div class="row ">
                            <div class="col">
                                <div class="mt-3">
                                    <h2 style="color:#fbce3b">TRAINERVORSTELLUNG - VANESSA</h2>
                                </div>
                            </div>
                        </div>
                        <div class="mt-3 py-3 border-top ">
                            <div class="row "  data-aos="fade-right">
                                <div class="col-lg-12">
									<div class="row">
									
									<div class="col-sm-4">
										<p style="text-align: justify">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut 
											labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd
											 gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed 
											 diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo
											  dolores et ea rebum. 
           
          </p>
		  
									</div>
									<div class="col-sm-8">
                                    <img src="@/assets/img/logos/Vanessa_new.png" style="width: 100%">
									</div>
									</div>
                                    
                                    <div class="row">
										<div class="col-sm-12">
                                        <p style="text-align: justify">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut 
											labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd
											 gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed 
											 diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo
											  dolores et ea rebum. 
           
          </p>
		  <p style="text-align: justify">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut 
											labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd
											 gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed 
											 diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo
											  dolores et ea rebum. 
           
          </p>
										</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </card>
            </div>
        </section>
    </div>
</template>

<script>
    export default {
        name: "Agb.vue"
    }
</script>

<style scoped>
    #header{
        background-image: url("../assets/img/logos/crossfit.jpg");
    }
   #kontaktButton{
        background-color:#fbce3b;
        border-color:transparent;
        width: 200px;
        font-size: 15px;
    font-family: Arial, Helvetica, sans-serif;
	font-weight: bolder;
	font-style: italic;   
    }
    #kontaktButton:hover {
		background-color: #212529;
		color: white;
		transition: 0.3s;
	}
</style>