<template>
  <footer class="footer bg-dark" id="footer1" style="margin-top: -0px">
    <div class="container" id="footerContainer">
      <div class="row row-grid  my-md " id="footerRow1">
        <div class="col-sm-12 justify-content-center" id="footerLogo_2">
          <router-link :to="{ name: 'startseite' }">
            <img
              src="@/assets/img/logos/Unbenannt-2.png"
              style="width: 100%; height: auto; margin-bottom: -100px; float: right"
              class=""
              alt=""
            />
          </router-link>
        </div>
        <div class="col-lg-3 ">
          <h5 class="mb-2" id="footer_text">Links</h5>
          <ul style="list-style:none; margin-left:-40px;">
            <li>
              <router-link :to="{ name: 'startseite' }">
                <a href="#" style="color:white">Das Forum</a>
              </router-link>
            </li>
            <li><a href="#" style="color:white">Über uns</a></li>
            <li><a href="#" style="color:white">Karriere</a></li>
            <li>
              <a href="https://live.forum-neuenbuerg.de" style="color:white"
                >Live</a
              >
            </li>
          </ul>
        </div>
        <div class="col-lg-4">
          <h5 class="mb-2" id="footer_text">Du hast Fragen?</h5>
          <ul style="list-style:none; margin-left:-40px;">
            <li>
              <i class="fa fa-phone" style="color:white; margin-left: 0px"></i
              ><a href="#" style="color:white"> Tel. 0 70 82 / 94 11 44</a>
            </li>
            <li>
              <i class="fa fa-envelope" style="color:white; margin-left:0px"></i
              ><a href="mailto:info@forum-neuenbuerg.de" style="color:white">
                E-Mail: info@forum-neuenbuerg.de</a
              >
            </li>
          </ul>
        </div>
        <div class="col-sm-3 " id="footerLogo_1">
          <router-link :to="{ name: 'startseite' }">
            <img
              src="@/assets/img/logos/Unbenannt-2.png"
              style="width: 100%; height: auto; margin-top: 0px;"
              class="d-inline-block align-top"
              alt=""
            />
          </router-link>
        </div>
      </div>
      <hr id="footerDivider" />
      <div
        class="row align-items-center justify-content-md-between"
        id="subFooter"
      >
        <div class="col-md-6">
          <div class="copyright" style="color:lightgray">
            <a>1.0.0</a>&copy; {{ year }}
            <a href="#" style="color:lightgray"
              >Fitness- und Gesundheitsforum Neuenbürg</a
            >
          </div>
        </div>
        <div class="col-md-6" id="subFooterRight">
          <ul
            class="nav nav-footer justify-content-end"
            style="padding-right:0px"
          >
            <li class="nav-item">
              <router-link :to="{ name: 'AGB' }">
                <a style="color:lightgray;padding-right:10px; font-size: 15px"
                  >AGB</a
                >
              </router-link>
            </li>
            <li class="nav-item">
              <router-link :to="{ name: 'kontakt' }">
                <a style="color:lightgray;padding-right:10px;font-size: 15px"
                  >Kontakt
                </a>
              </router-link>
            </li>
            <li class="nav-item">
              <router-link :to="{ name: 'datenschutz' }">
                <a style="color:lightgray;padding-right:10px;font-size: 15px"
                  >Datenschutz</a
                >
              </router-link>
            </li>
            <li class="nav-item">
              <router-link :to="{ name: 'impressum' }">
                <a style="color:lightgray;padding-right:10px;font-size: 15px"
                  >Impressum</a
                >
              </router-link>
            </li>
            <li class="nav-item">
              <router-link :to="{ name: 'Kuendigung' }">
                <a style="color:lightgray;font-size: 15px">Kündigung</a>
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  name: "app-footer",
  data() {
    return {
      year: new Date().getFullYear(),
    };
  },
};
</script>
<style>
@media only screen and (max-width: 1029px) {
  #footer1 {
    height: 280px !important;
  }
  #subFooterRight {
    margin-top: -22.5px;
  }
}

@media only screen and (max-width: 991px) {
  #footerRow1 {
    margin-bottom: 100px !important;
  }
  #footerLogo_1 {
    display: none;
  }
  #footerLogo_2 img {
    margin-bottom: 20px;
    width: 300px !important;
  }
}

@media only screen and (max-width: 991px) {
  #footerLogo_2 img {
    margin-bottom: -1000px !important;
    float: right !important;
  }
  #footer1 {
    height: 330px !important;
  }
  #subFooter {
  }
  #footerDivider {
    margin-top: 120px;
  }
}

@media only screen and (max-width: 768px) {
  #footerLogo_2 img {
    margin-bottom: -1000px !important;
    float: right !important;
  }
  #footer1 {
    height: 340px !important;
  }
  #subFooter {
    margin-top: 30px !important;
  }

  #subFooterRight {
    margin-top: -90px;
  }
  #footerDivider {
    margin-top: 130px !important;
  }
  .copyright {
    margin-top: -30px;
  }
}

@media only screen and (max-width: 430px) {
  #footerLogo_2 img {
    margin-bottom: 40px !important;
    margin-left: 10%;
    float: left !important;
  }
  #footer1 {
    height: 480px !important;
  }
  #subFooter {
    margin-top: 30px !important;
  }
  #footerDivider {
    margin-top: 280px !important;
  }
}

@media only screen and (min-width: 992px) {
  #footerLogo_2 {
    display: none;
  }
}

#footerDivider {
  margin-bottom: 5px;
  border-top: 1px solid white;
}

#footerRow1 {
  height: 120px;
}

#footerContainer {
  margin-top: -80px;
}

#subfooter {
  margin-top: -70px;
  color: grey;
}

#footer1 {
  height: 260px;
  margin-top: 70px;
}

#footer_text {
  color: white;
}
</style>
