export const media = [
	{
	  thumb: require('@/assets/img/PP/PP1.jpg'),
		src: require('@/assets/img/PP/PP1.jpg'),
	},
	{
		thumb: require('@/assets/img/PP/PP2.jpg'),
		  src: require('@/assets/img/PP/PP2.jpg'),
	  },
	  {
		thumb: require('@/assets/img/PP/PP3.jpg'),
		  src: require('@/assets/img/PP/PP3.jpg'),
	  },
	  {
		thumb: require('@/assets/img/PP/PP4.jpg'),
		  src: require('@/assets/img/PP/PP4.jpg'),
	  },
	  {
		thumb: require('@/assets/img/PP/PP5.jpg'),
		  src: require('@/assets/img/PP/PP5.jpg'),
	  },
	  {
		thumb: require('@/assets/img/PP/PP7.jpg'),
		  src: require('@/assets/img/PP/PP7.jpg'),
	  },
	  {
		thumb: require('@/assets/img/PP/PP8.jpg'),
		  src: require('@/assets/img/PP/PP8.jpg'),
	  },
	  {
		thumb: require('@/assets/img/PP/PP9.jpg'),
		  src: require('@/assets/img/PP/PP9.jpg'),
	  },
	
  ]