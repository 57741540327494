<template>
  <div>
    <div class="row">
      <div class="col-sm">
        <img :src="require(`@/assets/img/MA/${name}1.jpg`)" />
      </div>

      <div class="col-sm text-left" id="colText">
        <h1>{{ name }}</h1>
        <h2 style="color: #FBCE3B">{{ areas }}</h2>
        <h5>Alter: {{ age }} Jahre</h5>
        <h5 v-if="hobbies">Hobbys: {{ hobbies }}</h5>
        <h5 v-if="education">
          Ausbildung:
          <ul>
            <li v-for="education in education" :key="education">
              {{ education }}
            </li>
          </ul>
        </h5>
        <h5 v-if="courses">
          Kurse:
          <ul>
            <li v-for="course in courses" :key="course">
              {{ course }}
            </li>
          </ul>
        </h5>
      </div>
    </div>
    <div class="row">
      <div
        class="mottoContainer text-center"
        style="background-color: #FBCE3B; border-radius: 5px; margin-top: 50px; width: 100%"
      >
        <h5 style="color: white; padding: 20px; text-transform: uppercase;">
          <strong>{{ quote }}</strong>
        </h5>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
  props: {
    name: String,
    areas: String,
    age: Array,
    hobbies: String,
    education: Array,
    courses: Array,
    quote: String,
  },
  mounted() {
    var today = new Date();
    let year = this.age.year;
    let month = this.age.month;
    let day = this.age.day;
    var dd = parseInt(String(today.getDate()).padStart(2, "0"));
    var mm = parseInt(String(today.getMonth() + 1).padStart(2, "0")); //January is 0!
    var yy = today.getFullYear();
    if (month > mm || (month == mm && day > dd)) {
      year += 1;
    }
    this.age = yy - year;
  },
  methods: {},
};
</script>

<style scoped>
img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>
