import Vue from "vue";
import Router from "vue-router";
import AppHeader from "./layout/AppHeader";
import AppFooter from "./layout/AppFooter";
import Startseite from "./views/Startseite.vue";
import Membership from "./views/sections/membership/Membership.vue";
import UeberUns from "./views/UeberUns.vue";
import Kontakt from "./views/Kontakt.vue";
import Karriere from "./views/Karriere.vue";
import AGB from "./views/Agb.vue";
import Impressum from "./views/Impressum.vue";
import Datenschutz from "./views/Datenschutz.vue";
import StellenangebotTemplate from "./views/StellenangebotTemplate.vue";
import Kuendigung from "./views/Kuendigung.vue";
import BeitragsTemplate from "./views/BeitragsTemplate.vue";
import Datenschutzerklaerung from "./views/Datenschutzerklaerung.vue";
import DiashowWerbung from "./views/DiashowWerbung.vue";
import DiashowEingang from "./views/DiashowEingang.vue";
import Kurse from "./views/Kurse.vue";
import test from "./components/test.vue";

import GetLive from "./views/sections/membership/GetLive.vue";

import msgsend from "./views/msgsend.vue";
import Kuendigungabgeschlossen from "./views/Kuendigungabgeschlossen.vue";

Vue.use(Router);

export default new Router({
  linkExactActiveClass: "active",
  routes: [
    {
      path: "/",
      name: "startseite",
      components: {
        header: AppHeader,
        default: Startseite,
        footer: AppFooter,
      },
    },
    {
      path: "/membership",
      name: "Membership",
      components: {
        header: AppHeader,
        default: Membership,
        footer: AppFooter,
      },
      // children: [{ path: "getlive", name: "GetLive", component: GetLive }],
    },
    {
      path: "/membership/getlive",
      name: "GetLive",
      components: {
        header: AppHeader,
        default: GetLive,
        footer: AppFooter,
      },
    },
    {
      path: "/Datenschutzerklaerung",
      name: "datenschutzerklaerung",
      components: {
        header: AppHeader,
        default: Datenschutzerklaerung,
        footer: AppFooter,
      },
    },
    {
      path: "/Kuendigung",
      name: "Kuendigung",
      components: {
        header: AppHeader,
        default: Kuendigung,
        footer: AppFooter,
      },
    },
    {
      path: "/Kuendigungabgeschlossen",
      name: "Kuendigungabgeschlossen",
      components: {
        header: AppHeader,
        default: Kuendigungabgeschlossen,
        footer: AppFooter,
      },
    },
    {
      path: "/DiashowWerbung",
      name: "DiashowWerbung",
      components: {
        default: DiashowWerbung,
      },
    },
    {
      path: "/DiashowEingang",
      name: "DiashowEingang",
      components: {
        default: DiashowEingang,
      },
    },
    {
      path: "/msgsend/:first_name/:last_name/:phone/:email/:msg",
      name: "msgsend",
      components: {
        header: AppHeader,
        default: msgsend,
        footer: AppFooter,
      },
      props: true,
    },

    {
      path: "/test",
      name: "test",
      components: {
        header: AppHeader,
        default: test,
        footer: AppFooter,
      },
    },
    {
      path: "/Kurse",
      name: "Kurse",
      components: {
        header: AppHeader,
        default: Kurse,
        footer: AppFooter,
      },
    },
    {
      path: "/UeberUns",
      name: "ueberUns",
      components: {
        header: AppHeader,
        default: UeberUns,
        footer: AppFooter,
      },
    },
    {
      path: "/Kontakt",
      name: "kontakt",
      components: {
        header: AppHeader,
        default: Kontakt,
        footer: AppFooter,
      },
    },
    {
      path: "/Karriere",
      name: "karriere",
      components: {
        header: AppHeader,
        default: Karriere,
        footer: AppFooter,
      },
    },
    {
      path: "/AGB",
      name: "AGB",
      components: {
        header: AppHeader,
        default: AGB,
        footer: AppFooter,
      },
    },
    {
      path: "/Impressum",
      name: "impressum",
      components: {
        header: AppHeader,
        default: Impressum,
        footer: AppFooter,
      },
    },
    {
      path: "/Datenschutz",
      name: "datenschutz",
      components: {
        header: AppHeader,
        default: Datenschutz,
        footer: AppFooter,
      },
    },

    {
      path: "/StellenangebotTemplate",
      name: "stellenangebotTemplate",
      components: {
        header: AppHeader,
        default: StellenangebotTemplate,
        footer: AppFooter,
      },
    },
    {
      path: "/BeitragsTemplate",
      name: "beitragsTemplate",
      components: {
        header: AppHeader,
        default: BeitragsTemplate,
        footer: AppFooter,
      },
    },
  ],
  scrollBehavior: (to) => {
    if (to.hash) {
      return {
        selector: to.hash,
      };
    } else {
      return {
        x: 0,
        y: 0,
      };
    }
  },
});
