<template>
  <div class="profile-page" style="background-color: #F2F2F2;">
    <section class="section-profile-cover section-shaped my-0" id="header">
      <div class="">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </section>
    <section class="section section-skew sectionImpressum">
      <div class="container">
        <card
          shadow
          class="card-profile mt--300"
          no-body
          style="background-color: white;"
        >
          <div class="px-4">
            <div class="row ">
              <div class="col">
                <div class="mt-3">
                  <h2 style="color:#fbce3b">IMPRESSUM</h2>
                </div>
              </div>
              <div class="col">
                <router-link :to="{ name: 'kontakt' }">
                  <base-button
                    id="kontaktButton"
                    type="default"
                    size="sm"
                    class="float-right mt-4"
                    >Kontakt</base-button
                  >
                </router-link>
              </div>
            </div>
            <div class="mt-3 py-3 border-top ">
              <div class="row " data-aos="fade-right">
                <div class="col-lg-12">
                  <p>
                    Fitness- und Gesundheitsforum<br />
                    C. Deeg GbR
                  </p>
                  <p>
                    Daimlerstr. 2<br />
                    75305 Neuenbürg
                  </p>
                  <p>
                    Telefon: 0 70 82 / 94 11 44<br />
                    E-Mail: info@forum-neuenbuerg.de<br />
                    Internet: www.forum-neuenbuerg.de
                  </p>
                  <p>Inhaber:Clement Deeg</p>
                  <p>
                    <strong>Bankverbindung</strong><br />Sparkasse Pforzheim
                    Calw <br />IBAN: DE66 6665 0085 0000 9505 80 <br />BIC:
                    PZHSDE66XXX <br />Ust-IdNr.: DE196980183
                  </p>

                  <h4 class="mt-2" style="color:#fbce3b">Öffnungszeiten</h4>
                  <div class="row">
                    <div class="col-lg-2">Mo-Fr</div>
                    <div class="col-lg-2" style="text-align: right">
                      06.00-22.00 Uhr
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-2">Sa,So</div>
                    <div class="col-lg-2" style="text-align: right">
                      09.00-17.00 Uhr
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </card>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "Impressum.vue",
};
</script>

<style scoped>
@media only screen and (max-width: 462px) {
  #kontaktButton {
    width: 100% !important;
    /* margin-top: 20px!important; */
  }
}

.sectionImpressum p {
  font-weight: 400;
}
#header {
  background-image: url("../assets/img/logos/crossfit.jpg");
}
#kontaktButton {
  background-color: #fbce3b;
  border-color: transparent;
  width: 200px;
  font-size: 15px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bolder;
  font-style: italic;
}
#kontaktButton:hover {
  background-color: #212529;
  color: white;
  transition: 0.3s;
}
</style>
