<template>
  <div>
    <PopUp></PopUp>
    <HeaderTitle></HeaderTitle>
    <Brands></Brands>
    <Leistungen></Leistungen>
    <Aktuelles></Aktuelles>
    <Zeitgraph></Zeitgraph>
    <Standort></Standort>
    <Testimonials></Testimonials>
    <faq></faq>
    <SocialMedia></SocialMedia>
  </div>
</template>

<script>
import Testimonials from "./startseite/Testimonials";
import Standort from "./startseite/Standort";
import Zeitgraph from "./startseite/Zeitgraph/Zeitgraph.vue";
import Aktuelles from "./startseite/Aktuelles";
import Leistungen from "./startseite/Leistungen";
import HeaderTitle from "./startseite/HeaderTitle";
import Brands from "./startseite/Brands";
import Faq from "./startseite/Faq.vue";
import SocialMedia from "./startseite/SocialMedia";
import PopUp from "../components/PopUp";

export default {
  name: "startseite",
  components: {
    Testimonials,
    Standort,
    Zeitgraph,
    Leistungen,
    Aktuelles,
    SocialMedia,
    HeaderTitle,
    Brands,
    Faq,
    PopUp,
  },
  methods: {},
  mounted() {},
};
</script>

<style>
@media only screen and (max-width: 500px) {
  h2 {
    font-size: 35px !important;
  }
  h4 {
    font-size: 30px !important;
  }
  .standortText {
    font-size: 17px;
  }
  .miniTitle {
    font-size: 20px;
    margin-top: 7.5px;
  }
}
</style>
