<template>
  <div class="profile-page" style="background-color: #F2F2F2;">
    <section class="section-profile-cover" id="header"></section>
    <section class="section section-skew sectionDatenschutz" style="">
      <div class="container">
        <card
          shadow
          class="card-profile mt--300"
          no-body
          style="background-color: white;"
        >
          <div class="px-4">
            <div class="row ">
              <div class="col">
                <div class="mt-3">
                  <h2 style="color:#fbce3b">DATENSCHUTZ</h2>
                </div>
              </div>
              <div class="col">
                <!--<base-button type="info" size="sm" class="mr-4">Connect</base-button>-->
                <base-button
                  id="kontaktButton"
                  type="default"
                  size="sm"
                  class="float-right mt-4"
                  >Kontakt</base-button
                >
              </div>
            </div>
            <div class="mt-3 py-3 border-top ">
              <div class="row " data-aos="fade-right">
                <div class="col-lg-12" id="datenschutzContent">
                  <h4>Haftungsausschluss, Rechtliche Hinweise, Datenschutz</h4>
                  <h5 class="mt-2">
                    Inhaltliche Verantwortung und Haftungsbeschränkung
                  </h5>
                  <p>
                    Das Angebot von Fitness Forum in Neuenbürg besteht zum Teil
                    aus redaktionellen Inhalten. Diese Inhalte wurden mit
                    größtmöglicher Sorgfalt erstellt. Der Anbieter übernimmt
                    jedoch keine Gewähr für die Richtigkeit, Vollständigkeit und
                    Aktualität der bereitgestellten Inhalte. Die Nutzung der
                    Inhalte der Website erfolgt auf eigene Gefahr des Nutzers.
                    Namentlich gekennzeichnete Beiträge geben die Meinung des
                    jeweiligen Autors und nicht immer die Meinung des Anbieters
                    wieder. Mit der reinen Nutzung der Website des Anbieters
                    kommt keinerlei Vertragsverhältnis zwischen dem Nutzer und
                    dem Anbieter zustande.
                  </p>
                  <p>
                    Bezüglich der redaktionellen Inhalte, die uns Partner zur
                    Verfügung stellen und bezüglich von Interessierten
                    präsentierten Informationen, Produkten und Dienstleistungen
                    wird keine Gewähr für die Richtigkeit übernommen und jede
                    Haftung ausgeschlossen.
                  </p>
                  <h5 class="mt-2">Externe Links</h5>
                  <p>
                    Diese Website enthält Verknüpfungen zu Websites Dritter
                    ("externe Links"). Diese Websites unterliegen der Haftung
                    der jeweiligen Betreiber. Fitness Forum in Neuenbürg hat bei
                    der erstmaligen Verknüpfung der externen Links die fremden
                    Inhalte daraufhin überprüft, ob etwaige Rechtsverstöße
                    bestehen. Zu dem Zeitpunkt waren keine Rechtsverstöße
                    ersichtlich. Der Anbieter hat keinerlei Einfluss auf die
                    aktuelle und zukünftige Gestaltung und auf die Inhalte der
                    verknüpften Seiten. Das Setzen von externen Links bedeutet
                    nicht, dass sich der Anbieter die hinter dem Verweis oder
                    Link liegenden Inhalte zu Eigen macht. Eine ständige
                    Kontrolle der externen Links ist für den Anbieter ohne
                    konkrete Hinweise auf Rechtsverstöße nicht zumutbar. Bei
                    Kenntnis von Rechtsverstößen werden jedoch derartige externe
                    Links unverzüglich gelöscht.
                  </p>
                  <h5>Urheber- und Leistungsschutzrechte</h5>
                  <p>
                    Die auf dieser Website veröffentlichten Inhalte unterliegen
                    dem deutschen Urheber- und Leistungsschutzrecht. Jede vom
                    deutschen Urheber- und Leistungsschutzrecht nicht
                    zugelassene Verwertung bedarf der vorherigen schriftlichen
                    Zustimmung des Anbieters oder jeweiligen Rechteinhabers.
                    Dies gilt insbesondere für Vervielfältigung, Bearbeitung,
                    Übersetzung, Einspeicherung, Verarbeitung bzw. Wiedergabe
                    von Inhalten in Datenbanken oder anderen elektronischen
                    Medien und Systemen. Inhalte und Rechte Dritter sind dabei
                    als solche gekennzeichnet. Die unerlaubte Vervielfältigung
                    oder Weitergabe einzelner Inhalte oder kompletter Seiten ist
                    nicht gestattet und strafbar. Lediglich die Herstellung von
                    Kopien und Downloads für den persönlichen, privaten und
                    nicht kommerziellen Gebrauch ist erlaubt. Die Darstellung
                    dieser Website in fremden Frames ist nur mit schriftlicher
                    Erlaubnis zulässig.
                  </p>

                  <h5>Bildlizenzen</h5>
                  <p>
                    Die Bildrechte liegen beim Betreiber dieser Website.
                    Jegliche Verwendung von Bildern der Internetseiten ist ohne
                    Erlaubnis nicht gestattet. Ebenso nicht gestattet ist die
                    Veränderung oder Weiterverarbeitung der Bilder dieser
                    Website.
                  </p>

                  <h5>Bildnachweis</h5>
                  <p>
                    <a
                      href="https://www.freepik.com/free-photo/solid-concrete-wall-textured-backdrop_17839221.htm"
                      style="color: #525f7f"
                      >Image by rawpixel.com</a
                    >
                    on Freepik
                  </p>
                  <p>
                    <a
                      href="https://www.freepik.com/photos/music"
                      style="color: #525f7f"
                      >Music photo created by senivpetro - www.freepik.com</a
                    >
                  </p>
                  <p>
                    <a
                      href="https://www.freepik.com/photos/woman"
                      style="color: #525f7f"
                      >Woman photo created by pressfoto - www.freepik.com</a
                    >
                  </p>
                  <p>
                    <a
                      href="https://www.freepik.com/photos/water"
                      style="color: #525f7f"
                      >Water photo created by diana.grytsku - www.freepik.com</a
                    >
                  </p>
                  <p>
                    <a
                      href="https://www.freepik.com/photos/people"
                      style="color: #525f7f"
                      >People photo created by javi_indy - www.freepik.com</a
                    >
                  </p>
                  <p>
                    <a
                      href="https://de.freepik.com/fotos/musik"
                      style="color: #525f7f"
                      >Musik Foto erstellt von senivpetro - de.freepik.com</a
                    >
                  </p>
                  <p>
                    <a
                      href="https://de.freepik.com/fotos/frau"
                      style="color: #525f7f"
                      >Frau Foto erstellt von pressfoto - de.freepik.com</a
                    >
                  </p>
                  <p>
                    <a
                      href="https://de.freepik.com/fotos/wasser"
                      style="color: #525f7f"
                      >Wasser Foto erstellt von diana.grytsku -
                      de.freepik.com</a
                    >
                  </p>
                  <p>
                    <a
                      href="https://www.freepik.com/photos/woman"
                      style="color: #525f7f"
                      >Woman photo created by drobotdean - www.freepik.com</a
                    >
                  </p>
                  <h5>Produkt- und Markennamen</h5>
                  <p>
                    Diese Website enthält unter Umständen Hinweise auf Produkt-
                    und Markennamen anderer Firmen. Die verwendeten Begriffe
                    sind Warenzeichen und/oder eingetragene Warenzeichen der
                    entsprechenden Firmen.
                  </p>
                  <h5>Datenschutz Informationen</h5>
                  <p>
                    Sofern innerhalb des Internetangebotes die Möglichkeit zur
                    Eingabe persönlicher oder geschäftlicher Daten (E-Mail
                    Adressen, Namen, Anschriften) besteht, so erfolgt die
                    Preisgabe dieser Daten seitens des Nutzers auf ausdrücklich
                    freiwilliger Basis. Die Inanspruchnahme und Bezahlung aller
                    angebotenen Dienste ist - soweit technisch möglich und
                    zumutbar - auch ohne Angabe solcher Daten bzw. unter Angabe
                    anonymisierter Daten oder eines Pseudonyms gestattet.
                  </p>
                  <p>
                    Bei jedem Zugriff eines Nutzers auf eine Seite aus dem
                    Angebot der Fitness Forum in Neuenbürg und bei jedem Abruf
                    einer Datei werden Daten über diesen Vorgang in einer
                    Protokolldatei gespeichert.
                  </p>
                  <p>
                    Im einzelnen wird über jeden Abruf folgender Datensatz
                    gespeichert:
                  </p>
                  <ul>
                    <li>Name der abgerufenen Datei,</li>
                    <li>Datum und Uhrzeit des Abrufs,</li>
                    <li>übertragene Datenmenge,</li>
                    <li>Referrer und Herkunft des Benutzers</li>
                    <li>Meldung, ob der Abruf erfolgreich war.</li>
                    <li>
                      Es wird ein Cookie gesetzt um wiederkehrende Besucher zu
                      erkennen.
                    </li>
                  </ul>
                  <p>
                    Zusätzlich wird die IP-Adresse des Rechners, von dem die
                    Anfrage abgeschickt wurde, gespeichert.
                  </p>
                  <p>
                    Die gespeicherten Daten werden zu statistischen Zwecken der
                    Webanalyse und des Webcontrollings ausgewertet und zur
                    kontinuierlichen Verbesserung der Webseiten und des
                    Marketing und des Vertriebs der Fitness Forum in Neuenbürg
                    verwendet. Eine Weitergabe an Dritte findet nicht statt
                    außer im Falle von Google Analytics siehe folgender Zusatz
                    Absatz.
                  </p>
                  <p>
                    Diese Website benutzt zusätzlich Google Analytics, einen
                    Webanalysedienst der Google Inc. ("Google") Google Analytics
                    verwendet sog. "Cookies", Textdateien, die auf Ihrem
                    Computer gespeichert werden und die eine Analyse der
                    Benutzung der Website durch Sie ermöglicht. Die durch den
                    Cookie erzeugten Informationen über Ihre Benutzung dieser
                    Website (einschließlich Ihrer IP-Adresse) wird an einen
                    Server von Google in den USA übertragen und dort
                    gespeichert. Google wird diese Informationen benutzen, um
                    Ihre Nutzung der Website auszuwerten, um Reports über die
                    Websiteaktivitäten für die Fitness Forum in Neuenbürg
                    zusammenzustellen und um weitere mit der Websitenutzung und
                    der Internetnutzung verbundene Dienstleistungen zu
                    erbringen. Auch wird Google diese Informationen
                    gegebenenfalls an Dritte übertragen, sofern dies gesetzlich
                    vorgeschrieben oder soweit Dritte diese Daten im Auftrag von
                    Google verarbeiten. Google wird nach Aussagen von Google in
                    keinem Fall Ihre IP-Adresse mit anderen Daten der Google in
                    Verbindung bringen. Sie können die Installation der Cookies
                    durch eine entsprechende Einstellung Ihrer Browser Software
                    verhindern; wir weisen Sie jedoch darauf hin, dass Sie in
                    diesem Fall gegebenenfalls nicht sämtliche Funktionen dieser
                    Website voll umfänglich nutzen können.
                  </p>
                  <p>
                    Sie können darüber hinaus die Erfassung der durch das Cookie
                    erzeugten und auf Ihre Nutzung der Website bezogenen Daten
                    (inkl. Ihrer IP-Adresse) an Google sowie die Verarbeitung
                    dieser Daten durch Google verhindern, indem sie das unter
                    dem folgenden Link (<a
                      href="http://tools.google.com/dlpage/gaoptout?hl=de"
                      >http://tools.google.com/dlpage/gaoptout?hl=de</a
                    >) verfügbare Browser-Plugin herunterladen und installieren.
                  </p>
                  <p>
                    Nähere Informationen hierzu finden Sie unter
                    http://tools.google.com/dlpage/gaoptout?hl=de bzw. unter
                    <a href="http://www.google.de/analytics/terms/de.htm"
                      >http://www.google.de/analytics/terms/de.html</a
                    >
                    (allgemeine Informationen zu Google Analytics und
                    Datenschutz). Wir weisen Sie darauf hin, dass auf dieser
                    Webseite Google Analytics um den Code "gat._anonymizeIp();"
                    erweitert wurde, um eine anonymisierte Erfassung von
                    IP-Adressen (sog. IP-Masking) zu gewährleisten.
                  </p>
                  <p>
                    Sie können die Erfassung durch Google Analytics verhindern,
                    indem Sie auf folgenden Link klicken. Es wird ein
                    Opt-Out-Cookie gesetzt, der die zukünftige Erfassung Ihrer
                    Daten beim Besuch dieser Website verhindert: Google
                    Analytics deaktivieren
                  </p>
                  <p>
                    Durch die Nutzung dieser Website erklären Sie sich mit der
                    Bearbeitung der über Sie erhobenen Daten durch uns und durch
                    Google sowie in der zuvor beschriebenen Art und Weise und zu
                    dem zuvor benannten Zweck einverstanden.
                  </p>
                  <h5>Besondere Nutzungsbedingungen</h5>
                  <p>
                    Soweit besondere Bedingungen für einzelne Nutzungen dieser
                    Website von den vorgenannten Bedingungen abweichen, wird an
                    entsprechender Stelle ausdrücklich darauf hingewiesen. In
                    diesem Falle gelten im jeweiligen Einzelfall die besonderen
                    Nutzungsbedingungen.
                  </p>
                  <h5>
                    Keine Teilnahme an außergerichtlicher Streitbeilegung gemäß
                    Verbraucherstreitbeilegungsgesetz
                  </h5>
                  <p>
                    Fitness Forum in Neuenbürg nimmt nicht an einer
                    außergerichtlichen Streitbeilegung gemäß
                    Verbraucherstreitbeilegungsgesetz (VSBG) teil. Rein
                    vorsorglich, nur für den Fall einer nicht disponiblen
                    gesetzlichen Pflicht zur Teilnahme an einer
                    außergerichtlichen Streitbeilegung im Sinne des
                    Verbraucherstreitbeilegungsgesetz, wird nachfolgend auf die
                    zuständige Schlichtungsstelle verwiesen: Allgemeine
                    Verbraucherschlichtungsstelle des Zentrums für Schlichtung
                    e. V., Straßburger Str. 8, 77694 Kehl, Telefon: +49 7851 79
                    579 40, Telefax: +49 7851 79579 41, E-Mail:
                    mail@verbraucher-schlichter.de, Website:
                    www.verbraucher-schlichter.de .
                  </p>
                  <h5>Gewerbliche Werbung</h5>
                  <p>
                    Die Verwendung der Kontaktdaten des Impressums zur
                    gewerblichen Werbung ist ausdrücklich nicht erwünscht, es
                    sei denn der Anbieter hatte zuvor seine schriftliche
                    Einwilligung erteilt oder es besteht bereits eine
                    Geschäftsbeziehung. Der Anbieter und alle auf dieser Website
                    genannten Personen widersprechen hiermit jeder kommerziellen
                    Verwendung und Weitergabe ihrer Daten.
                  </p>
                  <h4 class="mt-2" style="color:#fbce3b">Öffnungszeiten</h4>
                  <div class="row">
                    <div class="col-lg-2">Mo-Fr</div>
                    <div class="col-lg-2">06-22 Uhr</div>
                  </div>
                  <div class="row">
                    <div class="col-lg-2">Sa,So</div>
                    <div class="col-lg-2">09-17 Uhr</div>
                  </div>
                  <div class="row">
                    <div class="col-lg-2">Feiertag</div>
                    <div class="col-lg-2">9-17 Uhr</div>
                  </div>
                  <div class="row">
                    <div class="col-lg-3">365 Tage geöffnet</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </card>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "Datenschutz.vue",
};
</script>

<style scoped>
@media only screen and (max-width: 654px) {
  #kontaktButton {
    width: 100% !important;
    margin-top: 20px !important;
  }
}

@media only screen and (max-width: 396px) {
  #kontaktButton {
    margin-top: 20px !important;
  }
}

#header {
  background-image: url("../assets/img/logos/crossfit.jpg");
}
#kontaktButton {
  background-color: #fbce3b;
  border-color: transparent;
  width: 200px;
  font-size: 15px;

  font-family: Arial, Helvetica, sans-serif;
  font-weight: bolder;
  font-style: italic;
}

.sectionDatenschutz p {
  font-weight: 400;
}
#kontaktButton:hover {
  background-color: #212529;
  color: white;
  transition: 0.3s;
}

#datenschutzContent h5,
a {
  color: #fbce3b;
}
</style>
